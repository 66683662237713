import { useCallback, useEffect, useMemo, useState } from 'react'
import { produce } from 'immer'
import { Fade } from '@mui/material'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'
import CoachingStep from 'domain/Support/PerspectiveSolver/core/CoachingStep'

import ConfirmationButton from 'presentation/views/components/ConfirmationButton/ConfirmationButton'

import ActionsList from './ActionsList/ActionsList'
import ThoughtsList from './ThoughtsList/ThoughtsList'

enum RecapAnimationStep {
	Situation,
	Actions,
	Thoughts,
	Feeling,
	Confirm,
}

type PositiveRecapProps = {
	situation: CoachingSituation
	updateSituation: (updatedSituation: CoachingSituation) => void
}

function PositiveRecap({ situation, updateSituation }: PositiveRecapProps) {
	/**
	 * States
	 */

	const [animationStep, setAnimationStep] = useState<
		RecapAnimationStep | undefined
	>(RecapAnimationStep.Actions)

	/**
	 * Memo
	 */

	const areActionsVisible = useMemo(() => {
		if (animationStep === undefined) return false
		if (animationStep >= RecapAnimationStep.Actions) return true
		return false
	}, [animationStep])

	const areThoughtsVisible = useMemo(() => {
		if (animationStep === undefined) return false
		if (animationStep >= RecapAnimationStep.Thoughts) return true
		return false
	}, [animationStep])

	const isFeelingVisible = useMemo(() => {
		if (animationStep === undefined) return false
		if (animationStep >= RecapAnimationStep.Feeling) return true
		return false
	}, [animationStep])

	/*
	const isConfirmButtonVisible = useMemo(() => {
		if (animationStep === undefined) return false
		if (animationStep >= RecapAnimationStep.Confirm) return true
		return false
	}, [animationStep])
	*/

	const feeling = useMemo(() => {
		return situation.transformation.feeling.toLowerCase()
	}, [situation])

	/**
	 * Callbacks
	 */

	const confirm = useCallback(() => {
		const updatedSituation = produce(situation, (situation) => {
			situation.currentStep = CoachingStep.ResultFeeling
			return situation
		})

		updateSituation(updatedSituation)
	}, [situation, updateSituation])

	/**
	 * Effects
	 */

	useEffect(function onMount() {
		const animationIntervalId = setInterval(() => {
			setAnimationStep(
				(animationStep: RecapAnimationStep | undefined) => {
					if (animationStep === undefined) return animationStep
					if (animationStep === RecapAnimationStep.Confirm)
						return animationStep

					return animationStep + 1
				}
			)
		}, 2000)

		return function onUnmount() {
			clearInterval(animationIntervalId)
		}
	})

	/**
	 * Render
	 */

	if (animationStep === undefined) return null

	return (
		<>
			<div className='coaching-situation-content'>
				<div
					style={{
						height: '100%',
						maxHeight: '100%',
						overflow: 'auto',
					}}
				>
					<Fade in={areActionsVisible} appear timeout={1000}>
						<div>
							<h3 style={{ marginBottom: 0 }}>J'agis :</h3>
							<ActionsList
								actions={situation.transformation.actions}
							/>
						</div>
					</Fade>
					<Fade in={areThoughtsVisible} timeout={1000}>
						<div>
							<h3 style={{ marginTop: 8, marginBottom: 0 }}>
								Car je sais que :
							</h3>
							<ThoughtsList
								thoughts={situation.transformation.thoughts}
							/>
						</div>
					</Fade>

					<Fade in={isFeelingVisible} timeout={1000}>
						<h3>Alors je me sens {feeling}</h3>
					</Fade>
				</div>
			</div>
			<div className='coaching-situation-confirm'>
				<ConfirmationButton
					label={`Je fini le coaching`}
					isShown={isFeelingVisible}
					confirm={confirm}
				/>
			</div>
		</>
	)
}

export default PositiveRecap

import { useCallback, useMemo } from 'react'
import { Fade, IconButton, Tab, Tabs } from '@mui/material'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'

import './styles.css'

type HeaderProps = {
	tabsVisibility: boolean
	inspirationSelected: boolean
	setInspirationSelected: (shown: boolean) => void
	canGoBack: boolean
	goBack: () => void
	closable: boolean
	quit: () => void
}

function Header({
	tabsVisibility,
	inspirationSelected,
	setInspirationSelected,
	canGoBack,
	goBack,
	closable,
	quit,
}: HeaderProps) {
	/**
	 * Memo
	 */

	const tabValue = useMemo(() => {
		if (inspirationSelected === false) return 1
		else return 2
	}, [inspirationSelected])

	/**
	 * Callbacks
	 */

	const navigate = useCallback(
		(event: React.SyntheticEvent, clickedTab: number) => {
			if (clickedTab === 1) setInspirationSelected(false)
			else setInspirationSelected(true)
		},
		[setInspirationSelected]
	)

	/**
	 * Render
	 */

	const backButtonRender = useMemo(() => {
		const visible = inspirationSelected === false && canGoBack === true

		return (
			<Fade in={visible}>
				<IconButton size='large' onClick={goBack}>
					<ArrowBackIcon style={{ color: '#ACACAC' }} />
				</IconButton>
			</Fade>
		)
	}, [inspirationSelected, canGoBack, goBack])

	const closeButtonRender = useMemo(() => {
		const visible = inspirationSelected === false && closable === true

		return (
			<Fade in={visible}>
				<IconButton size='large' onClick={quit}>
					<CloseIcon style={{ color: '#ACACAC' }} />
				</IconButton>
			</Fade>
		)
	}, [inspirationSelected, closable, quit])

	/*const coachRender = useMemo(() => {
		if (tabsVisibility === true) return null

		return (
			<div className='tips-layout-coach'>
				<img
					className='tips-layout-coach-image'
					src={
						process.env.PUBLIC_URL + '/assets/images/Katia-Head.png'
					}
					alt='Coach'
				/>
			</div>
		)
	}, [tabsVisibility])*/

	const tabsRender = useMemo(() => {
		if (tabsVisibility === false) return null

		return (
			<Tabs
				value={tabValue}
				onChange={navigate}
				sx={{
					padding: '4px',
					width: '100%',
					height: '100%',
				}}
			>
				<Tab label='Conseils' value={1} />
				<Tab label='Exemples' value={2} />
			</Tabs>
		)
	}, [tabsVisibility, tabValue, navigate])

	return (
		<div className='tips-layout-header'>
			{backButtonRender}
			{tabsRender}
			{closeButtonRender}
		</div>
	)
}

export default Header

import { useCallback, useEffect, useMemo, useState } from 'react'

import SnapshotDTO from 'domain/Diagnostic/WellBeingSnapshot/Snapshot/interfaces/SnapshotDTO'

import useInteractors from 'presentation/hooks/useInteractors'

import NoRecap from './NoRecap/View'
import LastRecap from './LastRecap/View'

interface State {
	initializedMostRecentSnapshot: boolean
	mostRecentSnapshot: SnapshotDTO | null
}

export default function View() {
	/**
	 * Base
	 */

	const {
		diagnostic: { snapshotInteractor },
	} = useInteractors()

	/**
	 * States
	 */

	const [state, setState] = useState<State>({
		initializedMostRecentSnapshot: false,
		mostRecentSnapshot: null,
	})

	/**
	 * Callbacks
	 */

	const initializeSnapshot = useCallback(async () => {
		const snapshot = await snapshotInteractor.getMostRecent()

		setState({
			initializedMostRecentSnapshot: true,
			mostRecentSnapshot: snapshot,
		})
	}, [snapshotInteractor])

	/**
	 * Effects
	 */

	useEffect(() => {
		initializeSnapshot()
	}, [initializeSnapshot])

	/**
	 * Render
	 */

	const recapRender = useMemo(() => {
		if (state.mostRecentSnapshot)
			return <LastRecap snapshot={state.mostRecentSnapshot} />
		else return <NoRecap />
	}, [state.mostRecentSnapshot])

	return recapRender
}

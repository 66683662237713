import { useCallback, useEffect, useMemo, useState } from 'react'
import { Chip, useTheme, useMediaQuery, Box, Button } from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import Coaching from 'domain/Support/PerspectiveSolver/core/Coaching'
import ProblematicDTO from 'domain/Diagnostic/Problematic/interfaces/ProblematicDTO'

import useInteractors from 'presentation/hooks/useInteractors'

import CategoriesMock from 'domain/Psycho/core/data-access/StrategiesCategoriesMock'

import Modal from '../Modal/View'
import SwipeableDrawer from '../SwipeableDrawer/View'

const getCategoryStyles = (categoryLabel: string) => {
	switch (categoryLabel) {
		case 'Amitié': {
			return {
				backgroundColor: '#FCF3ED',
				color: '#E17F48',
			}
		}
		case 'Amour': {
			return {
				backgroundColor: '#FAEEEE',
				color: '#C54F4F',
			}
		}
		case 'Santé': {
			return {
				backgroundColor: '#EFF7F5',
				color: '#5FA69B',
			}
		}
		case 'Sport': {
			return {
				backgroundColor: '#EDF6F9',
				color: '#48A0BC',
			}
		}
		case 'Famille': {
			return {
				backgroundColor: '#FAEEF2',
				color: '#C95478',
			}
		}
		case 'Argent': {
			return {
				backgroundColor: '#EEF4FA',
				color: '#4E8BC5',
			}
		}
		case 'Travail': {
			return {
				backgroundColor: '#F2EFFC',
				color: '#785FDB',
			}
		}
		case 'Émotions': {
			return {
				backgroundColor: '#F6EFFA',
				color: '#A05DCA',
			}
		}
		default: {
			return {
				backgroundColor: '#E9E9E9',
				color: '#292827',
			}
		}
	}
}

type Props = {
	problematic: ProblematicDTO | null
	type: 'modal' | 'swipeable-drawer'
	opened: boolean
	edit: (problematic: ProblematicDTO) => void
	cancel: () => void
	remove: (id: string) => void
}

export default function View({
	problematic,
	type,
	opened,
	edit,
	cancel,
	remove,
}: Props) {
	/**
	 * Dependencies
	 */

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const { coachingInteractor } = useInteractors()

	/**
	 * State
	 */

	const [coaching, setCoaching] = useState<Coaching | null>(null)
	const [description, setDescription] = useState<string>('')

	/**
	 * Callbacks
	 */

	const loadCoaching = useCallback(
		async (id: string) => {
			try {
				const coaching = await coachingInteractor.get(id)
				setCoaching(coaching)
			} catch (error) {
				alert('Coaching introuvable.')
			}
		},
		[coachingInteractor]
	)

	const onEdit = useCallback(() => {
		if (problematic === null) return

		edit(problematic)
	}, [problematic, edit])

	const onRemove = useCallback(() => {
		if (problematic === null) return

		remove(problematic.id)
	}, [problematic, remove])

	/**
	 * Effects
	 */

	useEffect(() => {
		if (problematic === null) {
			setDescription('')
			// setCategories([])
		} else {
			setDescription(
				problematic.description.slice() ||
					problematic.freeDescription.slice()
			)
			loadCoaching(problematic.supports[0].id)
			// setCategories(problematic.categories.slice())
		}
	}, [problematic, loadCoaching])

	/**
	 * Render
	 */

	const actionsList = useMemo(() => {
		if (coaching === null) return null

		const actionsRender = []
		const situation = coaching.situations[coaching.situations.length - 1]
		for (let i = 0; i < situation.transformation.actions.length; i++) {
			const id = situation.transformation.actions[i].id
			const text = situation.transformation.actions[i].text
			actionsRender.push(<li key={id}>{text}</li>)
		}

		return <ol>{actionsRender}</ol>
	}, [coaching])

	const categoriesRender = useMemo(() => {
		if (problematic === null) return null

		return problematic.categories.map((categoryId) => {
			const category = CategoriesMock.find(
				(strategyCategory) => strategyCategory.id === categoryId
			)

			if (!category) return null

			return (
				<Chip
					key={category.id}
					label={category.label}
					style={{
						backgroundColor: getCategoryStyles(category.label)
							.backgroundColor,
						color: getCategoryStyles(category.label).color,
						fontSize: '0.75rem',
						height: '24px',
					}}
				/>
			)
		})
	}, [problematic])

	const thoughtsList = useMemo(() => {
		if (coaching === null) return null

		const thoughtsRender = []
		const situation = coaching.situations[coaching.situations.length - 1]
		for (let i = 0; i < situation.transformation.thoughts.length; i++) {
			const id = situation.transformation.thoughts[i].id
			const text = situation.transformation.thoughts[i].text
			thoughtsRender.push(<li key={id}>{text}</li>)
		}

		return <ol>{thoughtsRender}</ol>
	}, [coaching])

	const feeling = useMemo(() => {
		if (coaching === null) return null

		const situation = coaching.situations[coaching.situations.length - 1]

		return `Alors je me sens ${situation.transformation.feeling}`
	}, [coaching])

	const contentRender = useMemo(
		() => (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					height: '100%',
					width: '100%',
				}}
			>
				{isMobile ? (
					<>
						<Box
							sx={{
								position: 'absolute',
								top: '20px',
								left: '50%',
								transform: 'translateX(-40px)',
								width: '80px',
								height: '4px',
								borderRadius: '4px',
								background: '#e8e8e8',
							}}
						></Box>
					</>
				) : (
					<></>
				)}
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						flex: '0 0 fit-content',
						width: '100%',
						gap: '8px',
						marginTop: '8px',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							gap: '4px',
						}}
					>
						{categoriesRender}
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							flex: '0 0 fit-content',
							width: '100%',
						}}
					>
						<h3>{description}</h3>
					</Box>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						fontSize: '1rem',
						height: '100%',
					}}
				>
					J'agis
					{actionsList}
					Car je pense que
					{thoughtsList}
					{feeling}
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: isMobile ? 'column' : 'row',
						flex: '0 0 fit-content',
						width: '100%',
						gap: '8px',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: isMobile ? 'column' : 'row',
							flex: '0 0 fit-content',
							width: '100%',
							gap: '8px',
						}}
					>
						{isMobile ? (
							<>
								<Button
									onClick={onRemove}
									variant='contained'
									sx={{ backgroundColor: '#F16161' }}
								>
									Supprimer le sujet
								</Button>
								<Button
									onClick={cancel}
									variant='contained'
									color='secondary'
								>
									Fermer
								</Button>
							</>
						) : (
							<>
								<Button
									onClick={cancel}
									variant='contained'
									color='secondary'
								>
									Fermer
								</Button>
								<Button
									onClick={onRemove}
									variant='contained'
									sx={{ backgroundColor: '#F16161' }}
								>
									Supprimer le sujet
								</Button>
							</>
						)}
					</Box>
				</Box>
			</Box>
		),
		[
			isMobile,
			description,
			actionsList,
			thoughtsList,
			feeling,
			onEdit,
			onRemove,
			cancel,
		]
	)

	if (type === 'modal') {
		return (
			<Modal isOpen={opened} close={cancel} maximized>
				{contentRender}
			</Modal>
		)
	} else {
		return (
			<SwipeableDrawer
				maximized
				isOpen={opened}
				close={cancel}
				anchor={'bottom'}
			>
				{contentRender}
			</SwipeableDrawer>
		)
	}
}

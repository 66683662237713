import { useCallback, useEffect, useMemo, useState } from 'react'

import Tips from 'domain/Support/PerspectiveSolver/core/tips/Tips'

import Header from './Header/Header'
import Instruction from './Instruction/Instruction'
import Inspiration from './Inspiration/Inspiration'

import './styles.css'

type LayoutProps = {
	tips: Tips
	closable: boolean
	confirmable?: boolean
	close: () => void
}

function Layout({ tips, closable, confirmable = true, close }: LayoutProps) {
	/**
	 * States
	 */

	const [instructionPageIndex, setInstructionPageIndex] = useState<number>(0)
	const [inspirationSelected, setInspirationSelected] = useState(false)

	/**
	 * Memos
	 */

	const tabsVisibility = useMemo(() => {
		if (tips.inspiration) return true
		else return false
	}, [tips])

	const headerShown = useMemo(
		() => tabsVisibility || !!instructionPageIndex,
		[tabsVisibility, instructionPageIndex]
	)

	/**
	 * Callbacks
	 */

	const onSetInstructionPageIndex = useCallback((index: number) => {
		setInstructionPageIndex(index)
	}, [])

	const onPrevious = useCallback(() => {
		if (instructionPageIndex >= 1)
			setInstructionPageIndex(instructionPageIndex - 1)
	}, [instructionPageIndex])

	const goBackToInstruction = useCallback(() => {
		setInspirationSelected(false)
	}, [])

	/**
	 * Effects
	 */

	useEffect(function onMount() {
		return function onUnmount() {
			setInstructionPageIndex(0)
		}
	}, [])

	useEffect(() => {
		setInstructionPageIndex(0)
		setInspirationSelected(false)
	}, [tips, setInstructionPageIndex, setInspirationSelected])

	/**
	 * Render
	 */

	const canGoBack = useMemo(() => {
		if (instructionPageIndex > 0) return true
		return false
	}, [instructionPageIndex])

	const headerRender = useMemo(() => {
		if (headerShown === false) return null

		return (
			<Header
				tabsVisibility={tabsVisibility}
				inspirationSelected={inspirationSelected}
				setInspirationSelected={setInspirationSelected}
				canGoBack={canGoBack}
				goBack={onPrevious}
				closable={closable}
				quit={close}
			/>
		)
	}, [
		headerShown,
		tabsVisibility,
		inspirationSelected,
		canGoBack,
		closable,
		close,
		setInspirationSelected,
		onPrevious,
	])

	const contentRender = useMemo(() => {
		if (tips.inspiration === undefined || inspirationSelected === false) {
			return (
				<Instruction
					instruction={tips.instruction}
					pageIndex={instructionPageIndex}
					updatePageIndex={onSetInstructionPageIndex}
					confirmable={confirmable}
					close={close}
				/>
			)
		}

		return (
			<Inspiration
				inspiration={tips.inspiration}
				goBackToInstruction={goBackToInstruction}
			/>
		)
	}, [
		tips,
		confirmable,
		inspirationSelected,
		instructionPageIndex,
		onSetInstructionPageIndex,
		close,
		goBackToInstruction,
	])

	return (
		<div className='tips-layout'>
			<div className='tips-layout-navigation'>{headerRender}</div>
			<div className='tips-layout-content'>{contentRender}</div>
		</div>
	)
}

export default Layout

import Tips from 'domain/Support/PerspectiveSolver/core/tips/Tips'

const tips: Tips = {
	instruction: {
		title: '', //`Comment trouver des actions ?`,
		pages: [
			{
				text: [
					`Qu'est-ce qu’une action ou une attitude ?`,
					`Une action ou une attitude doit être formulée au <b>présent</b>, de manière <b>positive</b>, et doit être <b>réaliste</b>.`,
					`Attention :`,
					`❌ “Je sais que je peux y arriver” : est une pensée
❌ “J’arrête de stresser” : est une formulation négative
❌ “J’aurai le droit au chômage” : c’est une condition qui n’est pas sûre`,
					`Quelques bons exemples :`,
					`✅ “Je prends le temps dont j’ai besoin en y allant étape par étape”
✅ “Je prends du recul et je me sens confiante”
✅ “J’ai confiance dans ma capacité à gérer ou à créer de l’argent”`,
				],
				confirmLabel: `Comment trouver une action`,
			},
			{
				text: [
					`Comment trouver une action ou une attitude ?`,
					`Imagine une personne qui ressent l’émotion positive que tu souhaiterais vivre dans cette situation :`,
					`- Que fait-elle concrètement ?`,
					`- A quoi ressemble son environnement ?`,
					`- Comment se comporte-t-elle ?`,
					`Le but est de te mettre dans sa peau et de te demander : en étant dans cette émotion positive, comment je suis ? Comment j'agis ?`,
				],
				confirmLabel: `J'écris mes actions positives réalistes`,
			},
		],
	},
	inspiration: {
		text: [
			`Je profite de ma journée`,
			`Je suis concentré·e`,
			`Je lâche prise`,
			`Je suis détaché·e du regard/jugement des autres`,
			`Je suis détaché·e émotionnellement`,
			`Je suis détaché·e de l’avenir mais engagé·e aujourd’hui`,
			`J’ai confiance en l’avenir/en moi`,
			`Je suis engagé·e dans mon bien-être`,
			`Je m’autorise à être moi-même`,
			`J’ai un plan et je m’y tiens`,
			`J’ai du plaisir à m’organiser`,
			`Je me sens soutenu·e et guidé·e`,
			`Je me concentre sur l’instant présent`,
			`Je sais faire des pauses et stopper si besoin`,
			`Je suis ma joie et mon enthousiasme`,
			`Je continue mes actions`,
			`J’accepte les choses que je ne peux pas contrôler`,
			`J’accueille mes émotions et les laisse circuler`,
		],
	},
}

export default tips

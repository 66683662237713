import CategoriesQuestionnaireEntity from './CategoriesQuestionnaireEntity'
import CategoriesQuestionnaireDTO from '../interfaces/data-transfer-objects/CategoriesQuestionnaireDTO'

class QuestionnaireMapper {
	static toDTO(
		entity: CategoriesQuestionnaireEntity
	): CategoriesQuestionnaireDTO {
		return {
			selectedCategories: entity.selectedCategories.map((category) =>
				Object.assign({}, category)
			),
			questions: entity.questions.map((question) =>
				Object.assign({}, question)
			),
			selectedAnswers: entity.selectedAnswers.slice(),
		}
	}
}

export default QuestionnaireMapper

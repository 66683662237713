import { useMemo } from 'react'
import { Box, Button, useMediaQuery, useTheme } from '@mui/material'

import SnapshotDTO from 'domain/Diagnostic/WellBeingSnapshot/Snapshot/interfaces/SnapshotDTO'

import Modal from 'presentation/views/components/Modal/View'

import GoodResult from './GoodResult/View'
import AverageResult from './AverageResult/View'
import LowResult from './LowResult/View'
import CriticalResult from './CriticalResult/View'

interface Props {
	opened: boolean
	snapshot: SnapshotDTO | null
	close: () => void
}

export default function View({ opened, snapshot, close }: Props) {
	/**
	 * Base
	 */

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	/**
	 * Render
	 */

	const recapRender = useMemo(() => {
		if (snapshot === null) return null

		if (snapshot.totalScore > 75) return <GoodResult />
		else if (snapshot.totalScore > 50) return <AverageResult />
		else if (snapshot.totalScore > 25) return <LowResult />
		else return <CriticalResult />
	}, [snapshot])

	return (
		<Modal isOpen={opened} maximized={isMobile}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
				}}
			>
				<Box sx={{ flex: '0 0 fit-content' }}>
					<h3>Bilan de ton étoile de vie</h3>
				</Box>
				<Box
					sx={{
						flex: '1 0 0',
						overflow: { xs: 'hidden', sm: 'visible' },
					}}
				>
					{recapRender}
				</Box>
				<Box
					sx={{
						display: 'flex',
						flex: '0 0 fit-content',
						width: '100%',
						height: 'auto',
						marginTop: '16px',
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={close}
						sx={{
							flex: '1 0 0',
							height: 'fit-content',
						}}
					>
						Compris
					</Button>
				</Box>
			</Box>
		</Modal>
	)
}

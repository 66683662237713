import { useCallback, useMemo, useState } from 'react'

import Tile from './Tile/Tile'
import DetailsPanel from './DetailsPanel/DetailsPanel'

import './styles.css'

type Content = {
	id: string
	text: string
}

type SliderProps = {
	title: string
	content: Array<Content>
	reversed?: boolean
	editable?: boolean
	editContent?: (content: string, index: number) => void
	removeContent?: (index: number) => void
}

function Slider({
	title,
	content,
	reversed = false,
	editable = false,
	editContent = undefined,
	removeContent = undefined,
}: SliderProps) {
	/**
	 * States
	 */

	const [editorIsOpen, setDetailsPanelOpen] = useState(false)

	/**
	 * Memos
	 */

	const upperCaseTitle = useMemo(() => title.toUpperCase(), [title])

	/**
	 * Callbacks
	 */

	const openDetailsPanel = useCallback(() => {
		if (content.length === 0) return

		setDetailsPanelOpen(true)
	}, [content])

	const closeDetailsPanel = useCallback(() => {
		setDetailsPanelOpen(false)
	}, [])

	const onRemoveContent = useCallback(
		(index: number) => {
			if (removeContent !== undefined) {
				if (content.length === 1) closeDetailsPanel()
				removeContent(index)
			}
		},
		[content, removeContent, closeDetailsPanel]
	)

	/**
	 * Render
	 */

	const titleClass = useMemo(() => {
		if (content.length > 0) return 'slider-title'
		else return 'slider-title hidden'
	}, [content])

	const contentRender = useMemo(() => {
		const contentRender = []
		for (let i = 0; i < content.length; i++) {
			let counter = i
			if (reversed) counter = content.length - 1 - i

			contentRender.push(
				<Tile
					text={content[counter].text}
					editable={editable}
					key={content[counter].id}
				/>
			)
		}

		return contentRender
	}, [editable, content, reversed])

	return (
		<>
			<div className='slider-container' onClick={openDetailsPanel}>
				<div className={titleClass}>{upperCaseTitle}</div>
				<div className='slider-content'>{contentRender}</div>
			</div>
			<DetailsPanel
				isOpen={editorIsOpen}
				close={closeDetailsPanel}
				title={upperCaseTitle}
				content={content}
				editable={editable}
				editContent={editContent}
				removeContent={onRemoveContent}
			/>
		</>
	)
}

export default Slider

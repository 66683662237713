import { Link } from 'react-router-dom'
import { Paper } from '@mui/material'

export default function View() {
	return (
		<Link to='autocoaching'>
			<Paper
				sx={{
					display: 'flex',
					flexDirection: 'column',
					borderRadius: '8px',
					padding: '20px 20px',
					height: 'fit-content',
					width: '100%',
					gap: '4px',
					justifyContent: 'center',
				}}
			>
				<h3 style={{ marginBottom: '0' }}>Session d'autocoaching</h3>
				<p
					style={{
						lineHeight: 'normal',
					}}
				>
					Travaille un sujet qui te préoccupe !
				</p>
			</Paper>
		</Link>
	)
}

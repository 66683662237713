import { Button } from '@mui/material'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'

type ResultNoNeutralSituationFormProps = {
	situation: CoachingSituation
	updateSituation: (updatedSituation: CoachingSituation) => void
	startNewSituation: () => void
	quitCoaching: () => void
}

function ResultNoNeutralSituationForm({
	situation,
	updateSituation,
	startNewSituation,
	quitCoaching,
}: ResultNoNeutralSituationFormProps) {
	/**
	 * Render
	 */

	return (
		<div className='coaching-situation-content'>
			<h3 style={{ flex: '0 0 fit-content' }}>
				Ta problématique était un peu floue ou trop large.
			</h3>
			<div style={{ flex: '1 0 0', overflowY: 'auto' }}>
				<p>
					L’objectif est de trouver une seule thématique. Plus tu
					choisiras un sujet précis, plus le coaching sera efficace.
				</p>
				<p>
					<b>Suggestion</b> : tu peux prendre une feuille et écrire ce
					qui te passe par la tête pour mieux identifier ta
					problématique.
				</p>
				<p>
					Voici des questions qui peuvent t’aider à préciser ta
					problématique :
				</p>
				<ul>
					<li>“Qu’est ce qui me dérange vraiment ?”</li>
					<li>
						“Pourquoi ?” à écrire régulièrement après une réponse
					</li>
					<li>“Qu’est ce qui serait le pire du pire ?”</li>
					<li>“Qu’est ce que je ne veux surtout pas vivre ?”</li>
				</ul>
				<p>Est ce que tu souhaites faire une nouvelle session ?</p>
			</div>

			<div style={{ flex: '0 0 fit-content' }}>
				<Button
					onClick={startNewSituation}
					variant='contained'
					style={{ color: 'white', margin: '8px 0px' }}
					fullWidth
				>
					Oui, c’est parti !
				</Button>
				<Button
					onClick={quitCoaching}
					variant='contained'
					style={{ color: 'white', margin: '8px 0px' }}
					fullWidth
				>
					Non, quitter
				</Button>
			</div>
		</div>
	)
}

export default ResultNoNeutralSituationForm

import { useMemo } from 'react'
import { Box, useTheme, useMediaQuery, Button } from '@mui/material'

import CachedIcon from '@mui/icons-material/Cached'

import SnapshotDTO from 'domain/Diagnostic/WellBeingSnapshot/Snapshot/interfaces/SnapshotDTO'
import QuestionnaireDTO from 'domain/Diagnostic/WellBeingSnapshot/Questionnaire/interfaces/data-transfer-objects/QuestionnaireDTO'

interface Props {
	snapshot: SnapshotDTO | null
	questionnaire: QuestionnaireDTO | null
	showQuestionnaireStartInstructions: () => void
}

export default function View({
	snapshot,
	questionnaire,
	showQuestionnaireStartInstructions,
}: Props) {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const buttonLabelRender = useMemo(() => {
		if (questionnaire === null) return 'Nouveau test'
		else return 'Reprendre le test'
	}, [questionnaire])

	if (snapshot === null) return null

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: isMobile ? 'column-reverse' : 'row',
				justifyContent: isMobile ? 'center' : 'flex-end',
				alignItems: 'center',
				gap: isMobile ? '8px' : '16px',
				width: isMobile ? '100%' : 'auto',
			}}
		>
			<p
				style={{
					padding: '0',
					borderRadius: '32px',
					color: '#292827',
					fontSize: isMobile ? '0.875rem' : '1rem',
					width: 'auto',
				}}
			>
				Mis à jour le {snapshot.creationDate.toLocaleDateString()}
			</p>
			<Button
				size='small'
				variant='contained'
				color='secondary'
				startIcon={<CachedIcon />}
				onClick={showQuestionnaireStartInstructions}
				sx={{
					marginTop: '0px',
					padding: '12px 24px',
					fontSize: isMobile ? 'inherited' : '1rem',
					width: isMobile ? '100%' : 'auto',
				}}
			>
				{buttonLabelRender}
			</Button>
		</Box>
	)
}

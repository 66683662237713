import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import Root from 'presentation/views/root/Root'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
	<StrictMode>
		<Root />
	</StrictMode>
)

serviceWorkerRegistration.register()

import { useMemo } from 'react'

import QuestionnaireDTO from 'domain/Diagnostic/WellBeingSnapshot/Questionnaire/interfaces/data-transfer-objects/QuestionnaireDTO'

import Question from './Question/View'

interface Props {
	questionnaire: QuestionnaireDTO
	currentQuestionIndex: number | undefined
	selectAnswer: (answerIndex: number) => Promise<void>
	confirmAnswer: () => Promise<void>
}

export default function View({
	questionnaire,
	currentQuestionIndex,
	selectAnswer,
	confirmAnswer,
}: Props) {
	const content = useMemo(() => {
		if (questionnaire === null || currentQuestionIndex === undefined)
			return null

		const question = questionnaire.questions[currentQuestionIndex]
		return (
			<Question
				question={question}
				selectAnswer={selectAnswer}
				confirmAnswer={confirmAnswer}
			/>
		)
	}, [questionnaire, currentQuestionIndex, selectAnswer, confirmAnswer])

	return content
}

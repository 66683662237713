import CategoryWeight from './CategoryWeightMapper'

import Question from '../entities/Question'
import QuestionDTO from '../../interfaces/data-transfer-objects/QuestionDTO'

class QuestionnaireMapper {
	static toDTO(question: Question): QuestionDTO {
		return {
			id: question.id,
			wording: question.wording,
			revertScoreRatio: question.revertScoreRatio,
			answers: question.answers.slice(),
			categoriesWeights: question.categoriesWeights.map(
				(categoryWeight) => CategoryWeight.toDTO(categoryWeight)
			),
			selectedAnswer: question.selectedAnswer,
		}
	}
}

export default QuestionnaireMapper

import { useCallback, useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, useTheme, useMediaQuery } from '@mui/material'

import SupportType from 'domain/Support/interfaces/SupportType'
import ProblematicDTO from 'domain/Diagnostic/Problematic/interfaces/ProblematicDTO'

import useInteractors from 'presentation/hooks/useInteractors'

import ProblematicsList from './ProblematicList/View'

type Props = {
	quitCoaching: () => void
}

export default function View({ quitCoaching }: Props) {
	/**
	 * Base
	 */

	const navigate = useNavigate()

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const {
		coachingInteractor,
		diagnostic: { problematicInteractor },
	} = useInteractors()

	/**
	 * Local state
	 */

	const [problematics, setProblematics] = useState<Array<ProblematicDTO>>([])
	const [problematicsLoaded, setProblematicsLoaded] = useState<boolean>(false)

	/**
	 * Memos
	 */

	const onGoingProblematics = useMemo(
		() =>
			problematics.filter(
				(problematic) =>
					problematic.supports.length > 0 &&
					problematic.supports[0].done === false
			),
		[problematics]
	)

	const allProblematics = useMemo(
		() =>
			problematics.filter(
				(problematic) => problematic.supports.length === 0
			),
		[problematics]
	)

	/**
	 * Callbacks
	 */

	const loadProblematics = useCallback(async () => {
		const problematics = await problematicInteractor.getProblematics()
		setProblematics(problematics)
		setProblematicsLoaded(true)
	}, [problematicInteractor])

	const onStartCoachingWithoutProblematic = useCallback(async () => {
		const coaching = await coachingInteractor.add()
		navigate(`/autocoaching/${coaching.id}`)
	}, [coachingInteractor, navigate])

	const onResumeCoachingFromProblematic = useCallback(
		(problematic: ProblematicDTO) => {
			for (let i = 0; i < problematic.supports.length; i++) {
				const support = problematic.supports[i]
				if (support.type === SupportType.PerspectiveSolver) {
					navigate(`/autocoaching/${support.id}`)
					return
				}
			}
		},
		[navigate]
	)

	const onCreateCoachingFromProblematic = useCallback(
		async (problematic: ProblematicDTO) => {
			const coaching = await coachingInteractor.addFromProblematic(
				problematic.id
			)
			navigate(`/autocoaching/${coaching.id}`)
		},
		[coachingInteractor, navigate]
	)

	/**
	 * Effects
	 */

	useEffect(() => {
		loadProblematics()
	}, [loadProblematics])

	useEffect(() => {
		if (
			problematicsLoaded === true &&
			allProblematics.length === 0 &&
			onGoingProblematics.length === 0
		) {
			onStartCoachingWithoutProblematic()
		}
	}, [
		problematicsLoaded,
		onGoingProblematics,
		allProblematics,
		onStartCoachingWithoutProblematic,
	])

	/**
	 * Render
	 */

	const onGoingProblematicsRender = useMemo(() => {
		if (onGoingProblematics.length === 0) return null

		return (
			<>
				{isMobile ? (
					<>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								height: 'fit-content',
								width: '100%',
							}}
						>
							<ProblematicsList
								title={'Reprendre un coaching'}
								problematics={onGoingProblematics}
								selectProblematic={
									onResumeCoachingFromProblematic
								}
							/>
						</Box>
					</>
				) : (
					<>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								height: 'fit-content',
								width: '100%',
							}}
						>
							<ProblematicsList
								title={'Reprendre un coaching'}
								problematics={onGoingProblematics}
								selectProblematic={
									onResumeCoachingFromProblematic
								}
							/>
						</Box>
					</>
				)}
			</>
		)
	}, [onGoingProblematics, isMobile, onResumeCoachingFromProblematic])

	const allProblematicsRender = useMemo(() => {
		if (allProblematics.length === 0) return null

		return (
			<>
				{isMobile ? (
					<>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								height: 'fit-content',
								width: '100%',
							}}
						>
							<ProblematicsList
								title={'Commencer un coaching'}
								problematics={allProblematics}
								selectProblematic={
									onCreateCoachingFromProblematic
								}
							/>
						</Box>
					</>
				) : (
					<>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								height: 'fit-content',
								width: '100%',
							}}
						>
							<ProblematicsList
								title={'Commencer un coaching'}
								problematics={allProblematics}
								selectProblematic={
									onCreateCoachingFromProblematic
								}
							/>
						</Box>
					</>
				)}
			</>
		)
	}, [allProblematics, isMobile, onCreateCoachingFromProblematic])

	return (
		<>
			{isMobile ? (
				<>
					<Box
						sx={{
							position: 'relative',
							display: 'flex',
							flexDirection: 'column',
							height: '100%',
							width: '100%',
						}}
					>
						<Box
							sx={{
								flex: '0 0 auto',
								width: '100%',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									position: 'relative',
								}}
							>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										width: '100%',
										height: 'auto',
										position: 'relative',
										padding: '32px 16px 8px',
										alignContent: 'center',
										justifyContent: 'center',
									}}
								>
									<h2
										style={{
											margin: '0px',
										}}
									>
										Coaching
									</h2>
								</Box>
							</Box>
						</Box>
						<Box
							sx={{
								flex: '1 0 0',
								padding: {
									xs: '16px 0px 128px 16px',
									sm: '16px 24px',
								},
								overflow: 'auto',
								width: '100%',
								height: '100%',
							}}
						>
							{onGoingProblematicsRender}
							{allProblematicsRender}
						</Box>
						<Box
							sx={{
								flex: '0 0 auto',
								padding: '32px 16px 16px',
								width: '100%',
								position: 'absolute',
								bottom: '0',
								background:
									'linear-gradient(180deg, rgba(252, 243, 239, 0.00) 0%, #FCF3EF 52%, #FCF3EF 100%)',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: '8px',
									paddingTop: '16px',
								}}
							>
								<Button
									color='primary'
									variant='contained'
									size='small'
									fullWidth
									onClick={onStartCoachingWithoutProblematic}
									sx={{ height: 'fit-content' }}
								>
									Commencer avec un nouveau sujet
								</Button>
							</Box>
						</Box>
					</Box>
				</>
			) : (
				<>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '32px',
							padding: {
								sm: '136px 48px 48px',
								md: '136px 32px 64px 32px',
								lg: '160px 64px 96px 64px',
							},
							height: '100%',
							width: '100%',
							maxWidth: '1248px',
							margin: '0 auto',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: {
									sm: 'column',
									md: 'row',
								},
								gap: '24px',
								justifyContent: {
									sm: 'center',
									md: 'space-between',
								},
								alignItems: 'center',
								padding: '24px',
								borderRadius: '8px',
								background: theme.palette.background.paper,
							}}
						>
							<h2
								style={{
									margin: '0px',
									width: 'fit-content',
								}}
							>
								Coaching
							</h2>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									gap: '16px',
								}}
							>
								<Button
									color='primary'
									variant='contained'
									size='small'
									fullWidth={isMobile}
									onClick={onStartCoachingWithoutProblematic}
									sx={{ height: 'fit-content' }}
								>
									Commencer avec un nouveau sujet
								</Button>
							</Box>
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexDirection: { sm: 'column', md: 'row' },
								gap: '32px',
							}}
						>
							{onGoingProblematicsRender}
							{allProblematicsRender}
						</Box>
					</Box>
				</>
			)}
		</>
	)
}

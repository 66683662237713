import { useMemo } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'

import CompactLayout from './CompactLayout'
import StandardLayout from './StandardLayout'

export default function View() {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const layoutRender = useMemo(
		() => (isMobile ? <CompactLayout /> : <StandardLayout />),
		[isMobile]
	)

	return layoutRender
}

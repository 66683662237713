import QuestionnaireStatus from '../entities/QuestionnaireStatus'
import QuestionnaireStatusDTO from '../../interfaces/data-transfer-objects/QuestionnaireStatusDTO'

class QuestionnaireStatusMapper {
	static toDTO(status: QuestionnaireStatus): QuestionnaireStatusDTO {
		if (status === QuestionnaireStatus.HasNoAnswers)
			return QuestionnaireStatusDTO.HasNoAnswers
		else if (status === QuestionnaireStatus.IsPartiallyCompleted)
			return QuestionnaireStatusDTO.IsPartiallyCompleted
		else return QuestionnaireStatusDTO.IsCompleted
	}
}

export default QuestionnaireStatusMapper

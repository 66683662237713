import { SvgIcon, SvgIconProps } from '@mui/material'

export default function HomeIconInactive(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<svg
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M15.5 8C15.5 9.22982 14.8661 10.3119 13.9049 10.9368C13.6672 11.0913 13.5 11.3609 13.5 11.673C13.5 12.1342 13.8559 12.49 14.281 12.5388C17.2185 12.8761 19.5 15.372 19.5 18.4C19.5 19.0075 19.0075 19.5 18.4 19.5H5.6C4.99249 19.5 4.5 19.0075 4.5 18.4C4.5 15.372 6.7815 12.8761 9.71896 12.5388C10.1441 12.49 10.5 12.1342 10.5 11.673C10.5 11.3609 10.3328 11.0913 10.0951 10.9368C9.1339 10.3119 8.5 9.22982 8.5 8C8.5 6.067 10.067 4.5 12 4.5C13.933 4.5 15.5 6.067 15.5 8Z'
					className='iconstrokefill'
				/>
			</svg>
		</SvgIcon>
	)
}

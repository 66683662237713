import Category from '../entities/Category'
import CategoryDTO from '../../interfaces/data-transfer-objects/CategoryDTO'

class CategoryMapper {
	static toDTO(category: Category): CategoryDTO {
		return {
			id: category.id,
			label: category.label,
		}
	}
}

export default CategoryMapper

import { Box } from '@mui/material'

import SnapshotDTO from 'domain/Diagnostic/WellBeingSnapshot/Snapshot/interfaces/SnapshotDTO'
import QuestionnaireDTO from 'domain/Diagnostic/WellBeingSnapshot/Questionnaire/interfaces/data-transfer-objects/QuestionnaireDTO'

import LastRefresh from './../LastRefresh/View'

interface Props {
	snapshot: SnapshotDTO | null
	questionnaire: QuestionnaireDTO | null
	showQuestionnaireStartInstructions: () => void
}

export default function View({
	snapshot,
	questionnaire,
	showQuestionnaireStartInstructions,
}: Props) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				position: 'relative',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					height: 'auto',
					position: 'relative',
					padding: '32px 16px 8px',
					alignContent: 'center',
					justifyContent: 'center',
				}}
			>
				<h2
					style={{
						margin: '0px',
					}}
				>
					Étoile de vie
				</h2>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					position: 'relative',
					alignContent: 'center',
					justifyContent: 'center',
					padding: '16px',
					gap: '8px',
				}}
			>
				<LastRefresh
					snapshot={snapshot}
					questionnaire={questionnaire}
					showQuestionnaireStartInstructions={
						showQuestionnaireStartInstructions
					}
				/>
			</Box>
		</Box>
	)
}

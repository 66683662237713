import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { produce } from 'immer'
import { nanoid } from 'nanoid'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'
import CoachingStep from 'domain/Support/PerspectiveSolver/core/CoachingStep'

import PerceptionSolverDemoVideoModal from 'presentation/views/components/PerceptionSolverDemoVideoModal/View'

import useAuth from 'presentation/hooks/useAuth'

import Situation from '../Coaching/Situation/Situation'

import { getFlagValue } from 'common/storage-interactor'

function createSituation(): CoachingSituation {
	const situation: CoachingSituation = {
		id: nanoid(),

		currentIteration: 1,
		currentStep: CoachingStep.NeutralAnalysis,

		hasFreeDescription: false,
		freeDescription: '',
		description: '',

		analysis: {
			feeling: '',
			thoughts: [],
			mostPowerfulThoughtIndex: 0,
		},

		transformation: {
			feeling: '',
			actions: [],
			thoughts: [],
			mostPowerfulThoughtIndex: 0,
		},

		result: {
			feeling: '',
		},
	}

	return situation
}

const initialSituations: Array<CoachingSituation> = [createSituation()]

export default function View() {
	const navigate = useNavigate()
	const { authUser } = useAuth()

	/**
	 * States
	 */

	const [situations, setSituations] =
		useState<Array<CoachingSituation>>(initialSituations)

	/**
	 * Memo
	 */

	const situation = useMemo(() => {
		const lastSituationIndex = situations.length - 1
		return situations[lastSituationIndex]
	}, [situations])

	const demoDone = useMemo(() => {
		if (authUser === null)
			return getFlagValue('demo-done', authUser, 'local')
		else return false
	}, [authUser])

	/**
	 * Callbacks
	 */

	const quitCoaching = useCallback(() => {
		navigate('/')
	}, [navigate])

	const updateSituation = useCallback(
		(updatedSituation: CoachingSituation) => {
			const updatedSituations = produce(situations, (situations) => {
				for (let i = 0; i < situations.length; i++) {
					if (situations[i].id === updatedSituation.id) {
						situations[i] = updatedSituation
						break
					}
				}
				return situations
			})
			setSituations(updatedSituations)
		},
		[situations]
	)

	const startNewSituation = useCallback(() => {
		const updatedSituations = produce(situations, (situations) => {
			situations.push(createSituation())
			return situations
		})
		setSituations(updatedSituations)
	}, [situations])

	/**
	 * Render
	 */

	if (authUser === null && demoDone) return <div>Essai déjà utilisé</div>

	return (
		<>
			<Situation
				situation={situation}
				situations={situations}
				updateSituation={updateSituation}
				startNewSituation={startNewSituation}
				quitCoaching={quitCoaching}
			/>
			<PerceptionSolverDemoVideoModal />
		</>
	)
}

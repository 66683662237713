import {
	createContext,
	PropsWithChildren,
	useCallback,
	useState,
	useMemo,
} from 'react'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { produce } from 'immer'

import LightTheme from 'presentation/themes/LightTheme'
import DarkTheme from 'presentation/themes/DarkTheme'

/**
 * Interfaces
 */

enum ThemeMode {
	Light,
	Dark,
}

interface ThemeState {
	mode: ThemeMode
}

interface ThemeContextState extends ThemeState {
	setMode: (mode: ThemeMode) => Promise<void>
}

/**
 * Context
 */

export const ThemeContext = createContext<ThemeContextState>({
	mode: ThemeMode.Light,
	setMode: async (mode: ThemeMode) => {
		throw Error('Function not initialized')
	},
})

/**
 * Provider
 */

export default function Theme({ children }: PropsWithChildren) {
	/**
	 * States
	 */

	const [themeState, setThemeState] = useState<ThemeState>({
		mode: ThemeMode.Light,
	})

	/**
	 * Callbacks
	 */

	const setMode = useCallback(
		async (mode: ThemeMode) => {
			const newThemeState = produce(themeState, (themeState) => {
				themeState.mode = mode
			})

			setThemeState(newThemeState)
		},
		[themeState]
	)

	/**
	 * Render
	 */

	const themeContextValue: ThemeContextState = useMemo(
		() => ({
			mode: themeState.mode,
			setMode,
		}),
		[themeState, setMode]
	)

	const muiTheme = useMemo(() => {
		if (themeState.mode === ThemeMode.Light) return LightTheme
		else return DarkTheme
	}, [themeState])

	return (
		<ThemeContext.Provider value={themeContextValue}>
			<MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
		</ThemeContext.Provider>
	)
}

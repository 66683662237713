import { useCallback, useState } from 'react'
import { Modal } from '@mui/material'

import Tips from 'domain/Support/PerspectiveSolver/core/tips/Tips'

import Button from './Button/Button'
import Layout from './Layout/Layout'

import './styles.css'

type TipsProps = {
	tips: Tips
	instruction?: string
	openOnMount?: boolean
	onClose?: () => void
}

function TipsComponent({
	instruction = 'Clique ici pour avoir des précisions sur la consigne, voir des exemples et le cas pratique',
	tips,
	openOnMount = false,
	onClose,
}: TipsProps) {
	/**
	 * States
	 */

	const [isOpen, setOpen] = useState<boolean>(openOnMount)
	const [closable, setClosable] = useState<boolean>(!openOnMount)

	/**
	 * Callbacks
	 */

	const openTips = useCallback(() => {
		setOpen(true)
	}, [])

	const closeTips = useCallback(() => {
		setOpen(false)
		if (onClose) onClose()

		if (closable === false) setClosable(true)
	}, [closable, onClose])

	/**
	 * Render
	 */

	return (
		<>
			<Button message={instruction} onClick={openTips} />
			<Modal open={isOpen} onClose={closeTips}>
				<div className='tips-modal'>
					<Layout tips={tips} closable={closable} close={closeTips} />
				</div>
			</Modal>
		</>
	)
}

export default TipsComponent

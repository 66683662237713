import { useCallback, useMemo, useState } from 'react'
import { produce } from 'immer'
import { nanoid } from 'nanoid'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'
import CoachingStep from 'domain/Support/PerspectiveSolver/core/CoachingStep'
import CoachingThought from 'domain/Support/PerspectiveSolver/core/CoachingThought'

import TipsMock from 'domain/Support/PerspectiveSolver/core/data-access/tips/PositiveActionsFormTips'

import ConfirmationButton from 'presentation/views/components/ConfirmationButton/ConfirmationButton'
import Slider from 'presentation/views/components/Slider/Slider'
import Tips from 'presentation/views/components/Tips/Tips'
import useAuth from 'presentation/hooks/useAuth'

import NewActionForm from './NewActionForm/NewActionForm'
import { useMediaQuery, useTheme } from '@mui/material'

import { getFlagValue, setFlagValue } from 'common/storage-interactor'

type PositiveActionsFormProps = {
	situation: CoachingSituation
	updateSituation: (updatedSituation: CoachingSituation) => void
}

function PositiveActionsForm({
	situation,
	updateSituation,
}: PositiveActionsFormProps) {
	const theme = useTheme()
	const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const { authUser } = useAuth()

	/**
	 * States
	 */

	const [actions, setActions] = useState<Array<CoachingThought>>(
		situation.transformation.actions.slice()
	)

	/**
	 * Memos
	 */

	const isConfirmable = useMemo(() => actions.length >= 4, [actions])

	const showTipsOnMount: boolean = useMemo(() => {
		if (getFlagValue('positive-actions-tips-seen', authUser) === false)
			return true
		return false
	}, [authUser])

	/**
	 * Memo
	 */

	const feeling = useMemo(() => {
		return situation.transformation.feeling.toLocaleLowerCase()
	}, [situation])

	/**
	 * Callbacks
	 */

	const addAction = useCallback(
		(action: string) => {
			const updatedThoughts = produce(actions, (actions) => {
				actions.push({
					id: nanoid(),
					text: action,
				})
				return actions
			})
			setActions(updatedThoughts)
		},
		[actions]
	)

	const updateAction = useCallback(
		(content: string, index: number) => {
			const updatedActions = produce(actions, (actions) => {
				actions[index].text = content
				return actions
			})
			setActions(updatedActions)
		},
		[actions]
	)

	const removeAction = useCallback(
		(index: number) => {
			const updatedActions = produce(actions, (actions) => {
				return actions.filter(
					(actions, actionIndex) => actionIndex !== index
				)
			})
			setActions(updatedActions)
		},
		[actions]
	)

	const confirmActions = useCallback(() => {
		if (actions.length < 4) return

		const updatedSituation = produce(situation, (situation) => {
			situation.transformation.actions = actions.slice()
			situation.currentStep = CoachingStep.PositiveThoughts
			return situation
		})

		updateSituation(updatedSituation)
	}, [actions, situation, updateSituation])

	/**
	 * Render
	 */

	return (
		<>
			<div className='coaching-situation-content'>
				<h4>
					Décris comment agirait une personne {feeling} dans cette
					situation.
				</h4>
				<p style={{ marginBottom: '16px' }}>
					Commence par <b>"Je"</b>. Il te faut au moins{' '}
					<b>4 actions ou attitudes</b>.
				</p>
				<NewActionForm addAction={addAction} />
				<Slider
					title='Mes actions'
					content={actions}
					reversed
					editable
					editContent={updateAction}
					removeContent={removeAction}
				/>
			</div>
			{smallScreen && (
				<div className='coaching-situation-tips'>
					<Tips
						instruction={`Qu’est ce qu’une action ou une attitude ?`}
						tips={TipsMock}
						openOnMount={showTipsOnMount}
						onClose={() => {
							setFlagValue(
								'positive-actions-tips-seen',
								true,
								authUser
							)
						}}
					/>
				</div>
			)}
			<div
				style={{ marginTop: '16px' }}
				className='coaching-situation-confirm'
			>
				<ConfirmationButton
					label={`C'est bon`}
					isShown={isConfirmable}
					confirm={confirmActions}
				/>
			</div>
		</>
	)
}

export default PositiveActionsForm

import { Navigate, useLocation } from 'react-router-dom'

import useAuth from 'presentation/hooks/useAuth'

export default function View({ children }: { children: JSX.Element }) {
	const { authUser, request } = useAuth()

	const location = useLocation()

	if (request.error)
		return <Navigate to='/error' state={{ from: location }} />
	else if (request.type === 'invite')
		return <Navigate to='/invite' state={{ from: location }} />
	else if (request.type === 'recovery')
		return <Navigate to='/recovery' state={{ from: location }} />
	else if (!authUser)
		return <Navigate to='/login' state={{ from: location }} replace />
	else return children
}

import { useCallback } from 'react'
import { Box, Tab, Tabs, useTheme, useMediaQuery } from '@mui/material'

type Props = {
	problematicsTypeShown: number
	selectProblematicsTypeShown: (typeShown: number) => void
}

export default function View({
	problematicsTypeShown,
	selectProblematicsTypeShown,
}: Props) {
	/**
	 * Dependencies
	 */

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	/**
	 * Callbacks
	 */

	const selectStartedAndWaitingProblematics = useCallback(() => {
		selectProblematicsTypeShown(0)
	}, [selectProblematicsTypeShown])

	const selectDoneProblematics = useCallback(() => {
		selectProblematicsTypeShown(1)
	}, [selectProblematicsTypeShown])

	/**
	 * Render
	 */

	return (
		<>
			{isMobile ? (
				<>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							width: '100%',
							position: 'relative',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								width: '100%',
								height: 'auto',
								position: 'relative',
								padding: '32px 16px 8px',
								alignContent: 'center',
								justifyContent: 'center',
							}}
						>
							<h2
								style={{
									margin: '0px',
								}}
							>
								Mes notes
							</h2>
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								width: '100%',
								height: 'fit-content',
								position: 'relative',
								alignContent: 'center',
								justifyContent: 'center',
								padding: '16px 16px 8px',
							}}
						>
							<Tabs
								value={problematicsTypeShown}
								textColor='secondary'
								sx={{
									width: '100%',
									height: '100%',
								}}
							>
								<Tab
									label='Mes sujets'
									onClick={
										selectStartedAndWaitingProblematics
									}
								/>
								<Tab
									label='Historique'
									onClick={selectDoneProblematics}
								/>
								{/*<Tab label='Explorer' />*/}
							</Tabs>
						</Box>
					</Box>
				</>
			) : (
				<>
					<Tabs value={problematicsTypeShown} textColor='secondary'>
						<Tab
							label='Mes sujets'
							onClick={selectStartedAndWaitingProblematics}
						/>
						<Tab
							label='Historique'
							onClick={selectDoneProblematics}
						/>
						{/*<Tab label='Explorer' />*/}
					</Tabs>
				</>
			)}
		</>
	)
}

import { useEffect } from 'react'
import { Box } from '@mui/material'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'

import ConfirmationButton from 'presentation/views/components/ConfirmationButton/ConfirmationButton'

import useAuth from 'presentation/hooks/useAuth'

import { setFlagValue } from 'common/storage-interactor'

type ResultSuccessFormProps = {
	situation: CoachingSituation
	updateSituation: (updatedSituation: CoachingSituation) => void
	quitCoaching: () => void
}

function ResultSuccess({
	situation,
	updateSituation,
	quitCoaching,
}: ResultSuccessFormProps) {
	const { authUser } = useAuth()

	useEffect(() => {
		if (authUser === null)
			setFlagValue('demo-done', true, authUser, 'local')
	}, [authUser])

	/**
	 * Render
	 */

	return (
		<>
			<div className='coaching-situation-content'>
				<Box
					sx={{
						display: 'flex',
						height: '100%',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<img
						src={
							process.env.PUBLIC_URL +
							'/assets/images/EndOfCoaching.png'
						}
						alt='Personne qui saute de joie'
						style={{ height: '250px' }}
					/>

					<h1>Bravo !</h1>
					<p style={{ textAlign: 'center' }}>
						Ta session est terminée, tu retrouveras le récapitulatif
						dans l'onglet <b>Notes</b>, en cliquant sur{' '}
						<b>Historique</b>.
					</p>
				</Box>
			</div>
			<div className='coaching-situation-confirm'>
				<ConfirmationButton
					label={`Quitter`}
					isShown={true}
					confirm={quitCoaching}
				/>
			</div>
		</>
	)
}

export default ResultSuccess

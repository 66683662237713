import { useCallback, useMemo, useRef, useState } from 'react'
import { IconButton, OutlinedInput } from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import './styles.css'

type ItemProps = {
	index: number
	text: string
	editable: boolean
	editContent?: (content: string, index: number) => void
	removeContent?: (index: number) => void
}

function Item({
	index,
	text,
	editable = false,
	editContent = undefined,
	removeContent = undefined,
}: ItemProps) {
	/**
	 * States
	 */

	const [editing, setEditing] = useState(false)
	const [editedText, setEditedText] = useState(text)

	/**
	 * Refs
	 */

	const editionFieldRef = useRef<HTMLInputElement>(null)

	/**
	 * Callbacks
	 */

	const startEdition = useCallback(() => {
		setEditing(true)
		requestAnimationFrame(() => {
			if (editionFieldRef.current === null) return

			editionFieldRef.current.focus()
			editionFieldRef.current.scroll({
				top: 9999,
				behavior: 'auto',
			})
			let tmpValue = editionFieldRef.current.value
			editionFieldRef.current.value = ''
			editionFieldRef.current.value = tmpValue
		})
	}, [])

	const confirmEdition = useCallback(() => {
		setEditing(false)
		if (editedText.trim() !== '') {
			if (editContent !== undefined) editContent(editedText, index)
		} else {
			if (removeContent !== undefined) removeContent(index)
		}
	}, [index, editedText, editContent, removeContent])

	const cancelEdition = useCallback(() => {
		setEditing(false)
		setEditedText(text)
	}, [text])

	const onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setEditedText(event.target.value)
		},
		[]
	)

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent) => {
			if (event.key === 'Enter') confirmEdition()
		},
		[confirmEdition]
	)

	const remove = useCallback(() => {
		if (removeContent !== undefined) removeContent(index)
	}, [index, removeContent])

	/**
	 * Render
	 */

	const textDisplay = useMemo(() => {
		const textClass = editable
			? 'slider-editor-item-text'
			: 'slider-editor-item-text-fullwidth'
		return (
			<>
				<div className={textClass}>{text}</div>
				{editable && (
					<div className='slider-editor-item-actions'>
						<div className='slider-editor-item-actions-root'>
							<div className='slider-editor-item-actions-buttons'>
								<IconButton onClick={startEdition}>
									<EditIcon />
								</IconButton>
								<IconButton onClick={remove}>
									<DeleteIcon />
								</IconButton>
							</div>
						</div>
					</div>
				)}
			</>
		)
	}, [text, editable, startEdition, remove])

	const textEdition = useMemo(() => {
		return (
			<>
				<div className='slider-editor-item-text'>
					<OutlinedInput
						placeholder={`Ma pensée...`}
						value={editedText}
						onChange={onChange}
						onKeyDown={onKeyDown}
						inputRef={editionFieldRef}
						multiline
						style={{ padding: '12px' }}
						maxRows={2}
						fullWidth
					/>
				</div>
				<div className='slider-editor-item-actions'>
					<div className='slider-editor-item-actions-root'>
						<div className='slider-editor-item-actions-buttons'>
							<IconButton onClick={confirmEdition}>
								<CheckIcon />
							</IconButton>
							<IconButton onClick={cancelEdition}>
								<CloseIcon />
							</IconButton>
						</div>
					</div>
				</div>
			</>
		)
	}, [editedText, onChange, onKeyDown, confirmEdition, cancelEdition])

	const textRender = useMemo(() => {
		if (editing) return textEdition
		else return textDisplay
	}, [editing, textEdition, textDisplay])

	return <div className='slider-editor-item'>{textRender}</div>
}

export default Item

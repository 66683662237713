enum CoachingStep {
	NeutralAnalysis,
	NeutralFreeDescription,
	NeutralSituation,

	NegativeFeeling,
	NegativeThoughts,
	NegativeThoughtChoice,

	PositiveFeeling,
	PositiveActions,
	PositiveThoughts,
	PositiveThoughtChoice,
	PositiveRecap,

	ResultFeeling,
	ResultSuccess,
	ResultRedo,
	ResultNoNeutralSituation,
	ResultInvalidPositiveThoughts,
	ResultAdditionalSituation,
	ResultInvalidSituation,
	ResultPersistingSymptoms,
}

export default CoachingStep

import { SvgIcon, SvgIconProps } from '@mui/material'

export default function HomeIconInactive(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<svg
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M14.9455 9.62867L13.8396 9.46799L13.3451 8.46596C12.7949 7.35109 11.2051 7.35109 10.6549 8.46596L10.1604 9.46799L9.05457 9.62867C7.82424 9.80745 7.33297 11.3194 8.22325 12.1872L9.02342 12.9672L8.83452 14.0685C8.62436 15.2939 9.9105 16.2283 11.0109 15.6498L12 15.1298L12.9891 15.6498C14.0895 16.2283 15.3757 15.2939 15.1655 14.0685L14.9766 12.9672L15.7768 12.1872C16.667 11.3194 16.1758 9.80745 14.9455 9.62867ZM11.5516 8.90852C11.735 8.5369 12.265 8.5369 12.4484 8.90853L13.0592 10.1463C13.1321 10.2938 13.2729 10.3961 13.4357 10.4198L14.8017 10.6183C15.2118 10.6779 15.3755 11.1819 15.0788 11.4711L14.0904 12.4346C13.9725 12.5495 13.9187 12.715 13.9466 12.8771L14.1799 14.2376C14.2499 14.646 13.8212 14.9575 13.4544 14.7647L12.2327 14.1224C12.087 14.0458 11.913 14.0458 11.7673 14.1224L10.5456 14.7647C10.1788 14.9575 9.75007 14.646 9.82013 14.2376L10.0535 12.8771C10.0813 12.715 10.0275 12.5495 9.90966 12.4346L8.92126 11.4711C8.6245 11.1819 8.78825 10.6779 9.19836 10.6183L10.5643 10.4198C10.7272 10.3961 10.8679 10.2938 10.9408 10.1463L11.5516 8.90852Z'
					className='iconfill'
				/>
				<path
					d='M19.0207 7.86478L13.7634 4.04512C12.7119 3.28119 11.2881 3.28118 10.2367 4.04512L4.97934 7.86478C3.92788 8.62871 3.4879 9.98281 3.88953 11.2189L5.89764 17.3992C6.29926 18.6353 7.45113 19.4722 8.75081 19.4722H15.2492C16.5489 19.4722 17.7008 18.6353 18.1024 17.3992L20.1105 11.2189C20.5121 9.98281 20.0721 8.62871 19.0207 7.86478ZM10.8244 4.85413C11.5254 4.34485 12.4746 4.34485 13.1756 4.85413L18.4329 8.6738C19.1339 9.18308 19.4272 10.0858 19.1594 10.9099L17.1513 17.0902C16.8836 17.9142 16.1157 18.4722 15.2492 18.4722H8.75081C7.88436 18.4722 7.11645 17.9142 6.8487 17.0902L4.84058 10.9099C4.57284 10.0858 4.86615 9.18308 5.56713 8.67379L10.8244 4.85413Z'
					className='iconfill'
				/>
			</svg>
		</SvgIcon>
	)
}

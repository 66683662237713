import { useCallback, useMemo, useState } from 'react'
import { produce } from 'immer'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'
import CoachingStep from 'domain/Support/PerspectiveSolver/core/CoachingStep'
import FeelingType from 'domain/Psycho/core/FeelingType'

import FeelingsMock from 'domain/Psycho/core/data-access/FeelingsMock'

import ConfirmationButton from 'presentation/views/components/ConfirmationButton/ConfirmationButton'

type NegativeFeelingFormProps = {
	situation: CoachingSituation
	updateSituation: (updatedSituation: CoachingSituation) => void
}

function NegativeFeelingForm({
	situation,
	updateSituation,
}: NegativeFeelingFormProps) {
	/**
	 * States
	 */

	const [feeling, setFeeling] = useState<string>(situation.analysis.feeling)

	/**
	 * Memo
	 */

	const feelings = useMemo(() => {
		const feelings = []
		for (let i = 0; i < FeelingsMock.length; i++) {
			if (FeelingsMock[i].type === FeelingType.Negative)
				feelings.push(FeelingsMock[i].label)
		}
		return feelings
	}, [])

	const isConfirmable = useMemo(() => feeling !== '', [feeling])

	/**
	 * Callbacks
	 */

	const confirmFeeling = useCallback(() => {
		if (feeling.trim().length === 0) return

		const updatedSituation = produce(situation, (situation) => {
			situation.analysis.feeling = feeling.trim()
			situation.currentStep = CoachingStep.NegativeThoughts
			return situation
		})

		updateSituation(updatedSituation)
	}, [feeling, situation, updateSituation])

	const onChange = useCallback((event: SelectChangeEvent) => {
		setFeeling(event.target.value as string)
	}, [])

	/**
	 * Render
	 */

	const optionsRender = useMemo(() => {
		const optionsRender = []

		for (let i = 0; i < feelings.length; i++) {
			const feeling = feelings[i]
			optionsRender.push(
				<MenuItem key={i} value={feeling}>
					{feeling}
				</MenuItem>
			)
		}

		return optionsRender
	}, [feelings])

	return (
		<>
			<div className='coaching-situation-content'>
				<h3>Décris ton ressenti face à cette situation</h3>
				<p style={{ marginBottom: '16px' }}>
					Choisis le ressenti qui correspond le mieux.
				</p>
				<Select
					value={feeling}
					onChange={onChange}
					displayEmpty
					fullWidth
				>
					<MenuItem value=''>
						<em>Choisir</em>
					</MenuItem>
					{optionsRender}
				</Select>
			</div>
			<div
				style={{ marginTop: '16px' }}
				className='coaching-situation-confirm'
			>
				<ConfirmationButton
					label={`Je me sens ${feeling.toLowerCase()}`}
					isShown={isConfirmable}
					confirm={confirmFeeling}
				/>
			</div>
		</>
	)
}

export default NegativeFeelingForm

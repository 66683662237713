import { useMemo } from 'react'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'
import CoachingStep from 'domain/Support/PerspectiveSolver/core/CoachingStep'

import NegativeFeelingForm from './NegativeFeelingForm/NegativeFeelingForm'
import NegativeThoughtsFormView from './NegativeThoughtsForm/NegativeThoughtsForm'
import NegativeThoughtChoiceFormView from './NegativeThoughtChoiceForm/NegativeThoughtChoiceForm'

type NegativeAnalysisProps = {
	situation: CoachingSituation
	situations: Array<CoachingSituation>
	updateSituation: (updatedSituation: CoachingSituation) => void
}

function NegativeAnalysis({
	situation,
	situations,
	updateSituation,
}: NegativeAnalysisProps) {
	/**
	 * Render
	 */

	const stepForm = useMemo(() => {
		if (situation.currentStep === CoachingStep.NegativeFeeling) {
			return (
				<NegativeFeelingForm
					situation={situation}
					updateSituation={updateSituation}
				/>
			)
		} else if (situation.currentStep === CoachingStep.NegativeThoughts) {
			return (
				<NegativeThoughtsFormView
					situation={situation}
					updateSituation={updateSituation}
				/>
			)
		} else if (
			situation.currentStep === CoachingStep.NegativeThoughtChoice
		) {
			return (
				<NegativeThoughtChoiceFormView
					situation={situation}
					updateSituation={updateSituation}
				/>
			)
		} else return null
	}, [situation, updateSituation])

	return stepForm
}

export default NegativeAnalysis

import { Button } from '@mui/material'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'

type ResultInvalidSituationFormProps = {
	situation: CoachingSituation
	updateSituation: (updatedSituation: CoachingSituation) => void
	quitCoaching: () => void
}

function ResultInvalidSituationForm({
	situation,
	updateSituation,
	quitCoaching,
}: ResultInvalidSituationFormProps) {
	/**
	 * Render
	 */

	return (
		<div className='coaching-situation-content'>
			<h3 style={{ flex: '0 0 fit-content' }}>
				Ma problématique concerne de l’anxiété, des angoisses, une
				séparation de moins de 2 ans ou une phobie
			</h3>
			<div style={{ flex: '1 0 0', overflowY: 'auto' }}>
				<p>
					Certaines thématiques (l’anxiété, les angoisses, les
					séparations récentes, les phobies) peuvent demander un suivi
					thérapeutique plus profond (par exemple en utilisant des
					outils comme l’hypnose, EMDR, une thérapie
					cognitivo-comportementale, etc...).
				</p>
				<p>
					Je te conseille de contacter un thérapeute qui correspond à
					tes besoins.
				</p>
				<p>
					Lorsque la thérapie aura bien avancé et que tu commenceras à
					voir des progrès, tu pourras de nouveau utiliser le coaching
					pour cette problématique !
				</p>
				<p>Je te souhaite un bon accompagnement.</p>
			</div>

			<div style={{ flex: '0 0 fit-content' }}>
				<Button
					onClick={quitCoaching}
					variant='contained'
					style={{ color: 'white', margin: '8px 0px' }}
					fullWidth
				>
					Retour à l’accueil
				</Button>
			</div>
		</div>
	)
}

export default ResultInvalidSituationForm

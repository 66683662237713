import { Box } from '@mui/material'

export default function View() {
	return (
		<Box sx={{ height: '100%', maxHeight: '100%', overflow: 'auto' }}>
			<p>
				Super ! Ton niveau de bien-être général indique que tu sais
				conscientiser beaucoup de schémas inconscients et tu es
				certainement proactif-ve à maintenir une forme de bien-être et
				d’épanouissement dans ta vie. Continue ce que tu as déjà mis en
				place par le passé.
			</p>
			<br />
			<p>
				Pour aller encore plus loin dans ton épanouissement, grâce à
				l’application tu peux développer de nouvelles prises de
				conscience et transformer les pensées ou croyances qui ne sont
				plus adaptées à ton idéal de vie.
			</p>
		</Box>
	)
}

import { useCallback, useRef, useState } from 'react'
import { Box, Button, Modal } from '@mui/material'

import useAuth from 'presentation/hooks/useAuth'

import { getFlagValue, setFlagValue } from 'common/storage-interactor'

import './styles.css'

export default function View() {
	const { authUser } = useAuth()

	/**
	 * States
	 */

	const [modalOpened, setModalOpened] = useState<boolean>(true)

	/**
	 * Refs
	 */

	const videoRef = useRef<HTMLVideoElement>(null)

	/**
	 * Callbacks
	 */

	const hideVideo = useCallback(() => {
		if (videoRef.current !== null) videoRef.current.pause()
		setFlagValue('is-presentation-video-seen', true, authUser)
	}, [authUser])

	/**
	 * Render
	 */

	return (
		<Modal
			open={modalOpened}
			onClose={() => {
				setModalOpened(false)
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',

					height: '100%',
					width: '100%',
				}}
			>
				<Box className='tips-layout' sx={{ padding: '16px' }}>
					<Box
						sx={{
							flex: '1 0 0',
							overflow: 'hidden',
						}}
					>
						<Box
							sx={{
								height: '100%',
								maxHeight: '100%',
								overflow: 'auto',
							}}
						>
							<br />
							{getFlagValue(
								'is-presentation-video-seen',
								authUser
							) === false ? (
								<h3>Regarde la vidéo de présentation !</h3>
							) : (
								<h3>Avant de commencer...</h3>
							)}
							<br />
							{getFlagValue(
								'is-presentation-video-seen',
								authUser
							) === false ? (
								<>
									<p>
										On t'a préparé une vidéo pour mieux
										comprendre le processus
									</p>
									<br />
									<p>
										Elle te sera proposé à chaque fois afin
										que tu puisses te rafraichir la mémoire
									</p>
									<br />
								</>
							) : (
								<>
									<p>Te rappelles-tu bien du processus ?</p>
									<br />
									<p>
										N'hésite pas à regarder la vidéo de
										présentation de nouveau pour te
										rafraîchir la mémoire !
									</p>
									<br />
								</>
							)}

							<video
								style={{ maxWidth: '100%' }}
								ref={videoRef}
								controls
							>
								<source
									src={
										'/assets/videos/Présentation-Coaching.mp4#t=0.1'
									}
									type='video/mp4'
								/>
							</video>
						</Box>
					</Box>

					<Box
						sx={{
							display: 'flex',
							flex: '0 0 fit-content',
							justifyContent: 'center',
							padding: '8px',
						}}
					>
						<Button
							variant='contained'
							style={{ color: 'white' }}
							onClick={() => {
								hideVideo()
								setModalOpened(false)
							}}
						>
							Commencer la session
						</Button>
					</Box>
				</Box>
			</Box>
		</Modal>
	)
}

import { PropsWithChildren } from 'react'

import useAuth from 'presentation/hooks/useAuth'

export default function LoadingScreen({
	children,
}: PropsWithChildren): JSX.Element {
	const { initialized } = useAuth()

	// TODO: Show loading screen
	if (initialized === false) return <></>

	return <>{children}</>
}

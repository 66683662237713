import { useCallback, useMemo } from 'react'
import { Box } from '@mui/material'

import SnapshotDTO from 'domain/Diagnostic/WellBeingSnapshot/Snapshot/interfaces/SnapshotDTO'

import Category from './Category/View'

import shownCategories from './ShownCategories'

interface Props {
	snapshot: SnapshotDTO | null
}

export default function View({ snapshot }: Props) {
	const getCategoryScore = useCallback(
		(label: string) => {
			if (snapshot === null) return null

			for (let i = 0; i < snapshot.categoriesWithScores.length; i++) {
				if (snapshot.categoriesWithScores[i].label === label)
					return snapshot.categoriesWithScores[i].score
			}

			return null
		},
		[snapshot]
	)

	const categories = useMemo(() => {
		if (snapshot === null) return shownCategories

		let categories = []
		for (let i = 0; i < shownCategories.length; i++) {
			const shownCategory = shownCategories[i]

			categories.push({
				label: shownCategory.label,
				icon: shownCategory.icon,
				score: getCategoryScore(shownCategory.label),
			})
		}

		categories = categories.sort((categoryA, categoryB) => {
			if (categoryA.score === null || categoryB.score === null) return 0

			if (categoryA.score > categoryB.score) return -1
			if (categoryB.score > categoryA.score) return 1

			return categoryA.label.localeCompare(categoryB.label, 'fr-FR')
		})

		return categories
	}, [snapshot, getCategoryScore])

	const categoriesRender = useMemo(() => {
		return categories.map((category, index) => (
			<Category
				key={index}
				label={category.label}
				score={category.score}
				icon={category.icon}
			/>
		))
	}, [categories])

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'flex-start',
				height: 'max-content',
				width: '100%',
				gap: '8px',
			}}
		>
			{categoriesRender}
		</Box>
	)
}

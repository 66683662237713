import { createBrowserRouter } from 'react-router-dom'

import Home from 'presentation/views/pages/Home/View'
import Notes from 'presentation/views/pages/Notes/View'
import WellBeingSnapshot from 'presentation/views/pages/WellBeingSnapshot/View'
import Coaching from 'presentation/views/pages/Coaching/View'
import CoachingDemo from 'presentation/views/pages/CoachingDemo/View'
import WithNavBar from './WithNavBar'
import SignIn from './Auth/SignIn'
import RequireAuth from './RequireAuth'
import RequireGuest from './RequireGuest'
import PasswordReset from './Auth/PasswordReset'
import PasswordRecovery from './Auth/PasswordRecovery'
import InviteConfirmation from './Auth/InviteConfirmation'
import InvalidLink from './Auth/InvalidLink'

const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<RequireAuth>
				<WithNavBar />
			</RequireAuth>
		),
		children: [
			{
				path: '/',
				element: <Home />,
			},
			{
				path: '/notes',
				element: <Notes />,
			},
			{
				path: '/etoile-de-vie',
				element: <WellBeingSnapshot />,
			},
			{
				path: '/autocoaching',
				element: <Coaching />,
			},
		],
	},
	{
		path: '/autocoaching/:autocoachingId',
		element: (
			<RequireAuth>
				<Coaching />
			</RequireAuth>
		),
	},
	{
		path: '/essai',
		element: (
			<RequireGuest>
				<CoachingDemo />
			</RequireGuest>
		),
	},
	{
		path: '/login',
		element: <SignIn />,
	},
	{
		path: '/password-reset',
		element: <PasswordReset />,
	},
	{
		path: '/recovery',
		element: <PasswordRecovery />,
	},
	{
		path: '/invite',
		element: <InviteConfirmation />,
	},
	{
		path: '/error',
		element: <InvalidLink />,
	},
])

export default router

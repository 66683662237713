import { useCallback, useMemo, useState } from 'react'
import { produce } from 'immer'
import { nanoid } from 'nanoid'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'
import CoachingStep from 'domain/Support/PerspectiveSolver/core/CoachingStep'
import CoachingThought from 'domain/Support/PerspectiveSolver/core/CoachingThought'

import TipsMock from 'domain/Support/PerspectiveSolver/core/data-access/tips/NegativeThoughtsFormTips'

import ConfirmationButton from 'presentation/views/components/ConfirmationButton/ConfirmationButton'
import Slider from 'presentation/views/components/Slider/Slider'
import Tips from 'presentation/views/components/Tips/Tips'
import useAuth from 'presentation/hooks/useAuth'

import ThoughtForm from './NewThoughtForm/NewThoughtForm'
import { useMediaQuery, useTheme } from '@mui/material'

import { getFlagValue, setFlagValue } from 'common/storage-interactor'

type NegativeThoughtsFormProps = {
	situation: CoachingSituation
	updateSituation: (updatedSituation: CoachingSituation) => void
}

function NegativeThoughtsForm({
	situation,
	updateSituation,
}: NegativeThoughtsFormProps) {
	const theme = useTheme()
	const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const { authUser } = useAuth()

	/**
	 * States
	 */

	const [thoughts, setThoughts] = useState<Array<CoachingThought>>(
		situation.analysis.thoughts.slice()
	)

	/**
	 * Memos
	 */

	const isConfirmable = useMemo(() => thoughts.length > 0, [thoughts])

	const showTipsOnMount: boolean = useMemo(() => {
		if (getFlagValue('negative-thoughts-tips-seen', authUser) === false)
			return true
		return false
	}, [authUser])

	/**
	 * Functions
	 */

	const confirmSeveralThoughts = useCallback(() => {
		const updatedSituation = produce(situation, (situation) => {
			situation.analysis.thoughts = thoughts.slice()
			situation.currentStep = CoachingStep.NegativeThoughtChoice
			return situation
		})

		updateSituation(updatedSituation)
	}, [thoughts, situation, updateSituation])

	const confirmSingleThought = useCallback(() => {
		const updatedSituation = produce(situation, (situation) => {
			situation.analysis.thoughts = thoughts.slice()
			situation.analysis.mostPowerfulThoughtIndex = thoughts.length - 1
			situation.currentStep = CoachingStep.PositiveFeeling
			return situation
		})

		updateSituation(updatedSituation)
	}, [thoughts, situation, updateSituation])

	/**
	 * Callbacks
	 */

	const addThought = useCallback(
		(thought: string) => {
			const updatedThoughts = produce(thoughts, (thoughts) => {
				thoughts.push({ id: nanoid(), text: thought })
				return thoughts
			})
			setThoughts(updatedThoughts)
		},
		[thoughts]
	)

	const updateThought = useCallback(
		(content: string, index: number) => {
			const updatedThoughts = produce(thoughts, (thoughts) => {
				thoughts[index].text = content
				return thoughts
			})
			setThoughts(updatedThoughts)
		},
		[thoughts]
	)

	const removeThought = useCallback(
		(index: number) => {
			const updatedThoughts = produce(thoughts, (thoughts) => {
				return thoughts.filter(
					(thought, thoughtIndex) => thoughtIndex !== index
				)
			})
			setThoughts(updatedThoughts)
		},
		[thoughts]
	)

	const confirmThoughts = useCallback(() => {
		if (thoughts.length === 1) confirmSingleThought()
		else if (thoughts.length > 1) confirmSeveralThoughts()
	}, [thoughts, confirmSeveralThoughts, confirmSingleThought])

	/**
	 * Render
	 */

	return (
		<>
			<div className='coaching-situation-content'>
				<h3>Détaille tes pensées sur la situation</h3>
				<p style={{ marginBottom: '16px' }}>
					Elles doivent être en rapport avec la situation.
				</p>
				<ThoughtForm addThought={addThought} />
				<Slider
					title='Mes pensées'
					content={thoughts}
					reversed
					editable
					editContent={updateThought}
					removeContent={removeThought}
				/>
			</div>
			{smallScreen && (
				<div className='coaching-situation-tips'>
					<Tips
						instruction='Comment identifier tes pensées actuelles ?'
						tips={TipsMock}
						openOnMount={showTipsOnMount}
						onClose={() => {
							setFlagValue(
								'negative-thoughts-tips-seen',
								true,
								authUser
							)
						}}
					/>
				</div>
			)}
			<div
				style={{ marginTop: '16px' }}
				className='coaching-situation-confirm'
			>
				<ConfirmationButton
					label={`J'ai fini`}
					isShown={isConfirmable}
					confirm={confirmThoughts}
				/>
			</div>
		</>
	)
}

export default NegativeThoughtsForm

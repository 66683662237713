var passwordValidator = require('password-validator');

var schema = new passwordValidator();

schema
.is().min(8)                                                                  
.has().uppercase()                                                  
.has().digits(1)                                
.has().not().spaces()     
.has().symbols(1)       

export {schema}     

export function hasDigits(password: string, length?: number) {
    return new passwordValidator().has().digits(length).validate(password);
}

export function hasSymbol(password: string, length?: number) {
    return new passwordValidator().has().symbols(length).validate(password);
}

export function hasUpperCase(password: string, length?: number) {
    return new passwordValidator().has().uppercase(length).validate(password);
}

export function isMinLength(password: string, length?: number) {
    return new passwordValidator().is().min(length).validate(password);
}
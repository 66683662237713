import Tips from 'domain/Support/PerspectiveSolver/core/tips/Tips'

const tips: Tips = {
	instruction: {
		title: '', //`Comment trouver de nouvelles pensées agréables ?`,
		pages: [
			{
				text: [
					`Qu'est-ce qu’une pensée ?`,
					`Une pensée doit être formulée au <b>présent</b>, de manière <b>positive</b>, et doit être <b>réaliste</b>.`,
					`Attention :`,
					`❌ “Je me sens confiant” est une attitude.
❌ “Je pourrais être plus productif” est une action au conditionnel.
❌ "Ça ne sert à rien de stresser” est une formulation négative.`,
					`Quelques bons exemples : `,
					`✅ “Je sais que j’ai déjà réussi lors d’une expérience similaire”
✅ “J’adore progresser dans ce domaine, ça me fait plaisir”
✅ “Je peux avoir confiance en moi et faire de mon mieux”`,
				],
				confirmLabel: `Comment trouver une pensée ?`,
			},
			{
				text: [
					`Comment trouver une pensée ?`,
					`De la même manière que pour les actions, <b>imagine une personne qui se sent bien dans ta situation</b>. Voici des questions qui peuvent t’aider :`,
					`- Que <b>pense</b> une personne qui <b>fait les actions que tu as identifiées ?</b>`,
					`- Sur quoi <b>se focalise-t-elle</b> si elle se sent bien ?`,
					`Est-ce que la pensée que tu as identifiée te fait du bien ?`,
					`- Si ce n’est pas le cas, prends le temps de réfléchir à une autre pensée.
- Si oui, super, c’est une pensée qui te convient !`,
				],
				confirmLabel: `J'écris mes pensées agréables`,
			},
		],
	},
	inspiration: {
		text: [
			`Peu importe ce qui se passe, je ferai de mon mieux`,
			`Je tente et je verrai bien car j’ai le droit de ne pas réussir du 1er coup`,
			`Et si c’était facile ? / Et si c’était faux ? / Et si j’avais le droit de…`,
			`Et si j’étais déjà sur le bon chemin ?`,
			`Je suis déjà entrain de réussir (lister ici tes accomplissements)`,
			`Je sais que ça va bien se passer`,
			`J’ai confiance dans mes capacités (ici, lister tes compétences)`,
			`Je me sens confiante dans mon engagement à réaliser mes projets`,
			`La somme d’argent que j’ai actuellement ne veut rien dire de moi`,
			`J’ai le droit de ne rien faire et d’être improductif·ve`,
			`J’ai le droit de ne pas gagner d’argent pendant 1 mois`,
			`Ce n’est juste qu’une question de temps, ça va évoluer`,
			`Je connais mes valeurs et je me perçois comme une bonne personne (ici lister tes qualités, et valeurs)`,
			`Plus j’accueille mes émotions, plus je les libère`,
			`Plus j’accepte les situations qui sont hors de contrôle, plus je peux me détendre`,
			`Les émotions/avis de cette personne ne m’appartiennent pas`,
			`J’ai le droit d’être heureux·se / riche …`,
			`Si cette personne ne m’accepte pas comme je suis, alors elle ne me correspond pas`,
			`Cette personne a le droit de me juger, cela ne veut rien dire de ma valeur et de qui je suis`,
			`Je fais toujours bonne impression lorsque je suis mes valeurs`,
			`C’est ok si je ne gère pas tout`,
			`J’ai droit à l’erreur car je progresse à mon rythme`,
			`Je sais que je peux être aidé·e en cas de besoin`,
			`Je suis déjà sur le chemin de la réussite (ici, donner tes preuves)`,
		],
	},
}

export default tips

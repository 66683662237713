import { useState } from 'react'
import { Box } from '@mui/material'

import MenuDrawer from './MenuDrawer/View'
import Header from './Header/View'
import WellcomeTitle from './WellcomeTitle/View'
import WellnessRecap from './WellnessRecap/View'
import PerceptionSolver from './PerceptionSolver/View'

export default function View() {
	const [drawerOpened, setDrawerOpened] = useState<boolean>(false)

	return (
		<>
			<MenuDrawer
				opened={drawerOpened}
				close={() => {
					setDrawerOpened(false)
				}}
			/>
			<Box
				sx={{
					position: 'absolute',
					top: '0',
					bottom: '0',
					right: '0',
					left: '0',
					backgroundImage:
						'linear-gradient(180deg, rgba(253, 248, 241, 0.00) 51.5%, #FDF8F1 76%), url(assets/images/BGEagle.jpg)',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					zIndex: '-100',
				}}
			></Box>
			<Box
				sx={{
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					width: '100%',
				}}
			>
				<Box
					sx={{
						flex: '0 0 auto',
						padding: '32px 16px 8px 16px',
						width: '100%',
					}}
				>
					<Header
						openMenu={() => {
							setDrawerOpened(true)
						}}
					/>
				</Box>
				<Box
					sx={{
						flex: '0 0 auto',
						padding: '0px 16px',
						width: '100%',
					}}
				>
					<WellcomeTitle />
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-end',
						padding: '0px 16px 24px 16px',
						width: '100%',
						height: '100%',
						gap: '16px',
					}}
				>
					<Box>
						<PerceptionSolver />
					</Box>
					<Box>
						<WellnessRecap />
					</Box>
				</Box>
			</Box>
		</>
	)
}

import { PropsWithChildren, useCallback } from 'react'
import { Box, SwipeableDrawer } from '@mui/material'

type Anchor = 'top' | 'left' | 'bottom' | 'right'

interface ModalProps {
	isOpen: boolean
	anchor: 'bottom' | 'top' | 'right' | 'left' | undefined
	maximized?: boolean
	close?: () => void
}

export default function View({
	isOpen,
	anchor = 'bottom',
	maximized = false,
	close,
	children,
}: PropsWithChildren<ModalProps>) {
	/**
	 * Callbacks
	 */

	const onToggleDrawer = useCallback(
		(anchor: Anchor, open: boolean) =>
			(event: React.KeyboardEvent | React.MouseEvent) => {
				if (open === false) {
					if (close) close()
				}
			},
		[close]
	)

	/**
	 * Render
	 */

	return (
		<SwipeableDrawer
			open={isOpen}
			onClose={onToggleDrawer(anchor, false)}
			onOpen={onToggleDrawer(anchor, false)}
			anchor={anchor}
			PaperProps={{
				sx: {
					padding: '40px 16px 24px',
					borderRadius: '32px 32px 0px 0px',
				},
			}}
		>
			<Box
				sx={{
					height: maximized ? '80vh' : 'fit-content',
					maxHeight: '80vh',
					minHeight: '40vh',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				{children}
			</Box>
		</SwipeableDrawer>
	)
}

import { useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import {
	Avatar,
	Button,
	CssBaseline,
	TextField,
	Box,
	Typography,
} from '@mui/material'

import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Container from '@mui/material/Container'

import useAuth from 'presentation/hooks/useAuth'

import { Copyright } from '../../components/Auth/Copyright'
import { PasswordRequirements } from '../../components/Auth/PasswordRequirements'
import { schema } from './lib/password-validator'

export default function InviteConfirmation() {
	/**
	 * Dependencies
	 */

	const navigate = useNavigate()

	/**
	 * State
	 */

	const [password, setPassword] = useState('')
	const { updatePassword, request } = useAuth()

	/**
	 * Callbacks
	 */

	const handleSubmit = useCallback(
		async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault()

			let formData = new FormData(event.currentTarget)
			let password = formData.get('password') as string
			if (!schema.validate(password)) return

			try {
				await updatePassword(password)
				navigate('/')
			} catch (error) {
				console.error('An unexpected error happened')
			}
		},
		[navigate, updatePassword]
	)

	/**
	 * Effects
	 */

	useEffect(() => {
		if (request.type !== 'invite') navigate('/')
	}, [request, navigate])

	/**
	 * Render
	 */

	return (
		<Container component='main' maxWidth='xs'>
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component='h1' variant='h5'>
					Finalisation de la création de ton compte
				</Typography>
				<Box
					component='form'
					onSubmit={handleSubmit}
					noValidate
					sx={{ mt: 1 }}
				>
					<TextField
						margin='normal'
						required
						fullWidth
						name='password'
						label='Mot de passe'
						type='password'
						id='password'
						autoComplete='current-password'
						onChange={(event) => {
							setPassword(event.target.value)
						}}
					/>
					<Button
						type='submit'
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2 }}
					>
						Définir mon mot de passe
					</Button>
					<PasswordRequirements password={password} />
				</Box>
			</Box>
			<Copyright sx={{ mt: 8, mb: 4 }} />
		</Container>
	)
}

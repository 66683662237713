import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Box, Button, OutlinedInput } from '@mui/material'

import QuestionDTO from 'domain/Diagnostic/WellBeingSnapshot/CategoriesQuestionnaire/interfaces/data-transfer-objects/QuestionDTO'

interface Props {
	question: QuestionDTO
	setAnswer: (answer: string) => Promise<void>
	confirmAnswer: () => Promise<void>
}

export default function View({ question, setAnswer, confirmAnswer }: Props) {
	/**
	 * States
	 */

	const [input, setInput] = useState<string>(
		question.answer ? question.answer : ''
	)

	/**
	 * Refs
	 */

	const previousQuestionId = useRef(question.id)

	/**
	 * Callbacks
	 */

	const onInputChanged = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setInput(event.target.value)
		},
		[]
	)

	const onConfirmAnswer = useCallback(async () => {
		if (input.trim() !== '') {
			await setAnswer(input.trim())
		}

		setInput('')
		await confirmAnswer()
	}, [input, setAnswer, confirmAnswer])

	/**
	 * Effects
	 */

	useEffect(() => {
		if (previousQuestionId.current !== question.id) {
			previousQuestionId.current = question.id
			setInput(question.answer ? question.answer : '')
		}
	}, [question])

	/**
	 * Render
	 */

	const confirmButtonRender = useMemo(() => {
		const buttonLabel =
			question.answer === undefined
				? `Question suivante`
				: `Valider la réponse`

		return (
			<Button
				sx={{ marginTop: '8px' }}
				variant='contained'
				color='primary'
				onClick={onConfirmAnswer}
				fullWidth
			>
				{buttonLabel}
			</Button>
		)
	}, [question, onConfirmAnswer])

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				overlflow: 'hidden',
				height: '100%',
			}}
		>
			<Box sx={{ flex: '0 0 fit-content', padding: '8px 0' }}>
				<h3>{question.wording}</h3>
			</Box>

			<Box sx={{ flex: '1 0 0' }}>
				<OutlinedInput
					placeholder={`Réponse à la question`}
					value={input}
					onChange={onInputChanged}
					style={{ padding: '12px' }}
					multiline
					maxRows={2}
					fullWidth
				/>
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-end',
					flex: '0 0 fit-content',
				}}
			>
				{confirmButtonRender}
			</Box>
		</Box>
	)
}

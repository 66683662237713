import { nanoid } from 'nanoid'

import ProblematicDTO from 'domain/Diagnostic/Problematic/interfaces/ProblematicDTO'

import ProblematicRepository from 'domain/Diagnostic/Problematic/interfaces/ProblematicRepository'

import { getProblematics, setProblematics } from 'common/storage-interactor'

class LocalStorageProblematicRepository implements ProblematicRepository {
	getProblematic = async (id: string) => {
		const problematics: Array<ProblematicDTO> = await this.getProblematics()

		for (let i = 0; i < problematics.length; i++) {
			if (problematics[i].id === id) return problematics[i]
		}

		throw Error(`Failed to get problematic with id ${id}`)
	}

	getProblematics = async () => {
		const problematics: Array<ProblematicDTO> = []
		const rawProblematics: any = await getProblematics()

		if (rawProblematics === null) return problematics

		if (Array.isArray(rawProblematics) === false)
			throw Error('Stored problematics format seems corrupted')

		for (let i = 0; i < rawProblematics.length; i++) {
			const rawProblematic = rawProblematics[i]

			const problematic: ProblematicDTO = {
				id: rawProblematic.id,
				freeDescription: rawProblematic.freeDescription,
				description: rawProblematic.description,
				categories: rawProblematic.categories,
				supports: rawProblematic.supports,
				modificationDate: rawProblematic.modificationDate,
				creationDate: rawProblematic.creationDate,
			}

			problematics.push(problematic)
		}

		return problematics
	}

	addProblematic = async (description: string, categories: Array<string>) => {
		const nowDate = Date.now()

		const problematic: ProblematicDTO = {
			id: nanoid(),
			freeDescription: description,
			description: '',
			categories: categories,
			supports: [],
			modificationDate: nowDate,
			creationDate: nowDate,
		}

		const problematics: Array<ProblematicDTO> = await this.getProblematics()
		problematics.unshift(problematic)

		setProblematics(problematics)
		return problematic
	}

	updateProblematic = async (problematic: ProblematicDTO) => {
		const problematics: Array<ProblematicDTO> = await this.getProblematics()

		for (let i = 0; i < problematics.length; i++) {
			if (problematic.id === problematics[i].id) {
				problematics[i] = problematic
			}
		}

		setProblematics(problematics)
	}

	removeProblematic = async (id: string) => {
		let problematics: Array<ProblematicDTO> = await this.getProblematics()

		problematics = problematics.filter(
			(problematic) => problematic.id !== id
		)

		setProblematics(problematics)
	}
}

export default LocalStorageProblematicRepository

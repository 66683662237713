import {
	// AuthError,
	AuthTokenResponse,
	createClient,
	SupabaseClient,
	UserResponse,
} from '@supabase/supabase-js'

import DomainAuthError from '../interfaces/AuthError'
import AuthRepository from '../interfaces/AuthRepository'
import AuthUserDTO from '../interfaces/AuthUserDTO'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL!
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY!
const domain = process.env.REACT_APP_DOMAIN!

class SupabaseAuthRepository implements AuthRepository {
	supabaseClient: SupabaseClient<any, 'public', any>

	constructor() {
		this.supabaseClient = createClient(supabaseUrl, supabaseAnonKey)
	}

	login = async (email: string, password: string) => {
		const { data, error }: AuthTokenResponse =
			await this.supabaseClient.auth.signInWithPassword({
				email,
				password,
			})

		if (error?.status === 400) throw DomainAuthError.InvalidCredentials
		else if (error) throw DomainAuthError.ConnectionFailed

		const user: AuthUserDTO = {
			id: data.user.id,
			email: data.user.email ?? '',
		}

		return user
	}

	logout = async () => {
		/*const result: {
			error: AuthError | null
		} = */ await this.supabaseClient.auth.signOut()

		// TODO: parse result to throw an exception if needed
	}

	getUser = async () => {
		const sessionResponse = await this.supabaseClient.auth.getSession()
		// TODO: parse result to throw an exception if needed

		const userResponse = sessionResponse?.data?.session?.user

		if (userResponse === null || userResponse?.id === undefined) return null

		const user: AuthUserDTO = {
			id: userResponse?.id,
			email: userResponse.email ?? '',
		}

		return user
	}

	updatePassword = async (password: string) => {
		const { error }: UserResponse =
			await this.supabaseClient.auth.updateUser({
				password: password,
			})

		if (error) throw DomainAuthError.BadFormat
		// else if (error) throw DomainAuthError.ConnectionFailed
	}

	resetPassword = async (email: string) => {
		/*const result:
			| { data: {}; error: null }
			| { data: null; error: AuthError } =
			*/ await this.supabaseClient.auth.resetPasswordForEmail(email, {
			redirectTo: `${domain}/recovery`,
		})
		// TODO: parse result to throw an exception if needed
	}
}

export default SupabaseAuthRepository

import Feeling from 'domain/Psycho/core/Feeling'
import FeelingType from 'domain/Psycho/core/FeelingType'

const rawFeelingsMock = [
	{
		type: FeelingType.Negative,
		label: 'Agacé·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Agité·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Anxieux·se',
	},
	{
		type: FeelingType.Negative,
		label: 'Apeuré·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Blasé·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Blessé·e',
	},
	{
		type: FeelingType.Positive,
		label: 'Calme',
	},
	{
		type: FeelingType.Negative,
		label: 'Coincé·e',
	},
	{
		type: FeelingType.Positive,
		label: 'Confiant·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Confus·e',
	},
	{
		type: FeelingType.Positive,
		label: 'Content·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Contrarié·e',
	},
	{
		type: FeelingType.Positive,
		label: 'Curieux·se',
	},
	{
		type: FeelingType.Negative,
		label: 'Débordé·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Découragé·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Déçu·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Dégoûté·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Effrayé·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Embêté·e',
	},
	{
		type: FeelingType.Negative,
		label: 'En colère',
	},
	{
		type: FeelingType.Positive,
		label: 'En paix',
	},
	{
		type: FeelingType.Positive,
		label: 'Enthousiaste',
	},
	{
		type: FeelingType.Negative,
		label: 'Epuisé·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Fatigué·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Frustré·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Hésitant·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Honteux·se',
	},
	{
		type: FeelingType.Negative,
		label: 'Impatient·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Impuissant·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Inconfortable',
	},
	{
		type: FeelingType.Positive,
		label: 'Joyeux·se',
	},
	{
		type: FeelingType.Negative,
		label: `Mal à l'aise`,
	},
	{
		type: FeelingType.Positive,
		label: 'Motivé·e',
	},
	{
		type: FeelingType.Negative,
		label: `Perplexe`,
	},
	{
		type: FeelingType.Negative,
		label: `Préoccupé·e`,
	},
	{
		type: FeelingType.Positive,
		label: 'Rassuré·e',
	},
	{
		type: FeelingType.Positive,
		label: 'Reconnaissant·e',
	},
	{
		type: FeelingType.Positive,
		label: 'Satisfait·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Saturé·e',
	},
	{
		type: FeelingType.Positive,
		label: 'Serein·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Seul·e',
	},
	{
		type: FeelingType.Positive,
		label: 'Soulagé·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Stressé·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Surpris·e',
	},
	{
		type: FeelingType.Negative,
		label: 'Tendu·e',
	},
	{
		type: FeelingType.Positive,
		label: 'Tranquille',
	},
	{
		type: FeelingType.Negative,
		label: 'Triste',
	},
]

const feelingsMock: Array<Feeling> = []

for (let i = 0; i < rawFeelingsMock.length; i++) {
	const rawFeeling = rawFeelingsMock[i]
	const feeling: Feeling = {
		type: rawFeeling.type,
		label: rawFeeling.label,
	}

	feelingsMock.push(feeling)
}

export default feelingsMock

import { useCallback } from 'react'
import { ListItemButton, ListItemText } from '@mui/material'

import CoachingThought from 'domain/Support/PerspectiveSolver/core/CoachingThought'

import ListItemTextWithNumber from 'presentation/views/components/ListItemTextWithNumber/View'

type ThoughtProps = {
	thought: CoachingThought
	index: number
	select: (thoughtIndex: number) => void
	isSelected: boolean
}

function Thought({ thought, index, select, isSelected }: ThoughtProps) {
	const onClick = useCallback(() => {
		select(index)
	}, [index, select])

	return (
		<ListItemButton onClick={onClick} selected={isSelected}>
			<ListItemText
				primary={
					<ListItemTextWithNumber
						index={index}
						text={thought.text}
						selected={isSelected}
					/>
				}
			/>
		</ListItemButton>
	)
}

export default Thought

import { ChangeEvent, useCallback } from 'react'

import { Box, TextField, InputAdornment } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'

type Props = {
	search: string
	onSearchChange: (search: string) => void
}

export default function View({ search, onSearchChange }: Props) {
	const onChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			onSearchChange(event.target.value)
		},
		[onSearchChange]
	)

	return (
		<Box
			sx={{
				display: 'flex',
				gap: '8px',
				alignItems: 'center',
				height: '100%',
			}}
		>
			<TextField
				placeholder='Rechercher un sujet'
				type='search'
				InputProps={{
					startAdornment: (
						<InputAdornment position='start'>
							<SearchIcon
								sx={{
									height: '20px',
								}}
							/>
						</InputAdornment>
					),
				}}
				variant='filled'
				sx={{
					width: '100%',
					justifyContent: 'center',
				}}
				onChange={onChange}
			/>
			{/*<Box
				sx={{
					flex: '0 0 auto',
				}}
			>
				<Button
					color='secondary'
					variant='contained'
					size='small'
					sx={{
						boxShadow: 'unset',
						border: '1px solid #FDF8F1',
						padding: '10px 12px',
						height: '47px',
					}}
					startIcon={<FilterListIcon />}
				>
					Filtrer
				</Button>
			</Box>*/}
		</Box>
	)
}

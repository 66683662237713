import { useMemo } from 'react'

import { List, ListItem, ListItemText } from '@mui/material'

import CoachingAction from 'domain/Support/PerspectiveSolver/core/CoachingAction'

type ActionsListProps = {
	actions: Array<CoachingAction>
}

function ActionsList({ actions }: ActionsListProps) {
	/**
	 * Render
	 */

	const actionsRender = useMemo(() => {
		return actions.map((action, index) => (
			<ListItem key={action.id}>
				<ListItemText primary={action.text} />
			</ListItem>
		))
	}, [actions])

	if (actions.length === 0) return null

	return <List dense>{actionsRender}</List>
}

export default ActionsList

import { useCallback, useMemo } from 'react'

import { Box, Button, useTheme } from '@mui/material'

import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import Diversity3Icon from '@mui/icons-material/Diversity3'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import WorkIcon from '@mui/icons-material/Work'

type Props = {
	id: string
	label: string
	selected: boolean
	add: (id: string) => void
	remove: (id: string) => void
}

export default function View({ id, label, selected, add, remove }: Props) {
	/**
	 * Dependencies
	 */

	const theme = useTheme()

	/**
	 * Callbacks
	 */

	const onClick = useCallback(() => {
		if (selected) remove(id)
		else add(id)
	}, [id, selected, add, remove])

	/**
	 * Memo
	 */

	const iconColor = useMemo(() => {
		if (selected) return theme.palette.primary.contrastText
		else return theme.palette.text.disabled
	}, [theme, selected])

	/**
	 * Render
	 */

	const backgroundColor = useMemo(() => {
		switch (label) {
			case 'Amitié':
				return '#E17F48'
			case 'Amour':
				return '#C54F4F'
			case 'Argent':
				return '#4E8BC5'
			case 'Émotions':
				return '#A05DCA'
			case 'Famille':
				return '#C95478'
			case 'Santé':
				return '#5FA69B'
			case 'Sport':
				return '#48A0BC'
			case 'Travail':
				return '#785FDB'
		}
	}, [label])

	const iconRender = useMemo(() => {
		switch (label) {
			case 'Amitié':
				return <Diversity3Icon style={{ color: iconColor }} />
			case 'Amour':
				return <FavoriteIcon style={{ color: iconColor }} />
			case 'Argent':
				return <AccountBalanceIcon style={{ color: iconColor }} />
			case 'Émotions':
				return <EmojiEmotionsIcon style={{ color: iconColor }} />
			case 'Famille':
				return <FamilyRestroomIcon style={{ color: iconColor }} />
			case 'Santé':
				return <LocalHospitalIcon style={{ color: iconColor }} />
			case 'Sport':
				return <FitnessCenterIcon style={{ color: iconColor }} />
			case 'Travail':
				return <WorkIcon style={{ color: iconColor }} />
		}
	}, [label, iconColor])

	return (
		<Box
			sx={{
				width: 'fit-content',
			}}
		>
			<Button
				onClick={onClick}
				size='small'
				variant='contained'
				color={selected ? 'primary' : 'secondary'}
				startIcon={iconRender}
				style={{
					color: selected ? undefined : '#777777',
					backgroundColor: selected ? backgroundColor : '#F7FAFA',
					boxShadow: 'none',
					borderRadius: '4px',
					padding: '12px 16px',
					border: 'none',
				}}
			>
				{label}
			</Button>
		</Box>
	)
}

import { Navigate } from 'react-router-dom'

import useAuth from 'presentation/hooks/useAuth'

export default function View({ children }: { children: JSX.Element }) {
	const { authUser } = useAuth()

	if (authUser) return <Navigate to='/' replace />
	else return children
}

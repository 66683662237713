import { Box } from '@mui/material'

export default function View() {
	return (
		<Box sx={{ height: '100%', maxHeight: '100%', overflow: 'auto' }}>
			<p>
				Ton niveau de bien-être général indique que tu as une importante
				marge d’amélioration. Pour cela, tu peux commencer par
				développer ta capacité à prendre conscience de tes schémas
				inconscients (pensées, émotions, croyances…).
			</p>
			<br />
			<p>
				En allant répondre aux différentes questions des catégories, tu
				peux découvrir d’éventuelles pensées ou croyances désagréables
				qui seront à utiliser dans la partie “auto-coaching”. Avec une
				session d’auto-coaching quotidienne d’environ 10 minutes, tu
				peux grandement augmenter ton niveau de bien-être.
			</p>
			<br />
			<p>
				Nous te conseillons également d’être accompagné par un
				thérapeute ou un coach en parallèle de l’utilisation de
				l’application.
			</p>
		</Box>
	)
}

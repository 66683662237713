import { useCallback, useEffect, useMemo } from 'react'
import { Box, Button, List } from '@mui/material'

import Question from 'domain/Diagnostic/WellBeingSnapshot/CategoriesQuestionnaire/interfaces/data-transfer-objects/QuestionDTO'

import Answer from './Answer/View'

interface Props {
	questions: Array<Question>
	selectedAnswers: Array<string>
	toggleAnswerSelection: (questionId: string) => Promise<void>
	confirmAnswer: () => Promise<void>
}

export default function View({
	questions,
	selectedAnswers,
	toggleAnswerSelection,
	confirmAnswer,
}: Props) {
	/**
	 * Callbacks
	 */

	const onToggleAnswer = useCallback(
		(questionId: string) => async () => {
			await toggleAnswerSelection(questionId)
		},
		[toggleAnswerSelection]
	)

	const onKeyPress = useCallback(
		(event: KeyboardEvent) => {
			const number = parseInt(event.key)

			if (event.key === 'Enter') {
				confirmAnswer()
			} else if (Number.isNaN(number) === false) {
				if (number - 1 >= 0 && number - 1 < questions.length)
					toggleAnswerSelection(questions[number - 1].id)
			}
		},
		[questions, toggleAnswerSelection, confirmAnswer]
	)

	/**
	 * Effects
	 */

	useEffect(() => {
		window.addEventListener('keypress', onKeyPress)
		return () => {
			window.removeEventListener('keypress', onKeyPress)
		}
	}, [onKeyPress])

	/**
	 * Render
	 */

	const answersRender = useMemo(() => {
		const answersRender = []

		let displayIndex = 0
		for (let i = 0; i < questions.length; i++) {
			const question = questions[i]

			if (question.answer === undefined || question.answer.trim() === '')
				continue

			const selectedAnswer = selectedAnswers.find(
				(selectedAnswer) => selectedAnswer === question.id
			)
			const selected = selectedAnswer !== undefined ? true : false

			answersRender.push(
				<Answer
					key={i}
					label={`${question.answerPrefix}${question.answer}`}
					isSelected={selected}
					index={displayIndex}
					select={onToggleAnswer(question.id)}
				/>
			)

			displayIndex++
		}

		return answersRender
	}, [questions, selectedAnswers, onToggleAnswer])

	const confirmButtonRender = useMemo(() => {
		if (selectedAnswers.length === 0) return null

		return (
			<Button
				sx={{ marginTop: '8px' }}
				variant='contained'
				color='primary'
				onClick={confirmAnswer}
				fullWidth
			>
				Enregistrer les problématiques
			</Button>
		)
	}, [selectedAnswers, confirmAnswer])

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				overlflow: 'hidden',
				height: '100%',
			}}
		>
			<Box sx={{ flex: '0 0 fit-content', padding: '0 8px' }}>
				<h3>Quels sujets veux-tu enregistrer dans tes notes ?</h3>
			</Box>
			<Box
				sx={{
					flex: '1 0 0',
				}}
			>
				<List>{answersRender}</List>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flex: '0 0 fit-content',
					justifyContent: 'flex-end',
				}}
			>
				{confirmButtonRender}
			</Box>
		</Box>
	)
}

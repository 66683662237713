import { useCallback, useMemo, useState } from 'react'
import { List, useMediaQuery, useTheme } from '@mui/material'
import { produce } from 'immer'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'
import CoachingStep from 'domain/Support/PerspectiveSolver/core/CoachingStep'

import ConfirmationButton from 'presentation/views/components/ConfirmationButton/ConfirmationButton'
import TipsMock from 'domain/Support/PerspectiveSolver/core/data-access/tips/PositiveThoughtChoiceForm'

import Tips from 'presentation/views/components/Tips/Tips'
import useAuth from 'presentation/hooks/useAuth'

import Thought from './Thought/Thought'

import { getFlagValue, setFlagValue } from 'common/storage-interactor'

type PositiveThoughtChoiceFormProps = {
	situation: CoachingSituation
	updateSituation: (updatedSituation: CoachingSituation) => void
}

function PositiveThoughtChoiceForm({
	situation,
	updateSituation,
}: PositiveThoughtChoiceFormProps) {
	const theme = useTheme()
	const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const { authUser } = useAuth()

	/**
	 * States
	 */

	const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

	/**
	 * Memos
	 */

	const isConfirmable = useMemo(() => selectedIndex !== null, [selectedIndex])

	const showTipsOnMount: boolean = useMemo(() => {
		if (
			getFlagValue('positive-thought-choice-tips-seen', authUser) ===
			false
		)
			return true
		return false
	}, [authUser])

	/**
	 * Callbacks
	 */

	const confirmThought = useCallback(() => {
		if (selectedIndex === null) return

		const updatedSituation = produce(situation, (situation) => {
			situation.analysis.mostPowerfulThoughtIndex = selectedIndex
			situation.currentStep = CoachingStep.PositiveRecap
			return situation
		})

		updateSituation(updatedSituation)
	}, [selectedIndex, situation, updateSituation])

	/**
	 * Render
	 */

	const thoughtsRender = useMemo(() => {
		return situation.transformation.thoughts.map((thought, index) => (
			<Thought
				key={thought.id}
				thought={thought}
				index={index}
				select={setSelectedIndex}
				isSelected={selectedIndex === index}
			/>
		))
	}, [selectedIndex, situation])

	return (
		<>
			<div className='coaching-situation-content'>
				<div style={{ flex: '0 0 fit-content' }}>
					<h3>Choisis la pensée la plus agréable</h3>
				</div>
				<div
					style={{
						flex: '1 0 0',
						maxHeight: '100%',
						overflow: 'auto',
					}}
				>
					<List>{thoughtsRender}</List>
				</div>
			</div>
			{smallScreen && (
				<div className='coaching-situation-tips'>
					<Tips
						instruction='Comment choisir le sentiment le plus agréable ?'
						tips={TipsMock}
						openOnMount={showTipsOnMount}
						onClose={() => {
							setFlagValue(
								'positive-thought-choice-tips-seen',
								true,
								authUser
							)
						}}
					/>
				</div>
			)}
			<div
				style={{ marginTop: '16px' }}
				className='coaching-situation-confirm'
			>
				<ConfirmationButton
					label={`J'ai choisi`}
					isShown={isConfirmable}
					confirm={confirmThought}
				/>
			</div>
		</>
	)
}

export default PositiveThoughtChoiceForm

import { Button } from '@mui/material'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'

type ResultPersistingSymptomsFormProps = {
	situation: CoachingSituation
	updateSituation: (updatedSituation: CoachingSituation) => void
	quitCoaching: () => void
}

function ResultPersistingSymptomsForm({
	situation,
	updateSituation,
	quitCoaching,
}: ResultPersistingSymptomsFormProps) {
	/**
	 * Render
	 */

	return (
		<div className='coaching-situation-content'>
			<h3 style={{ flex: '0 0 fit-content' }}>
				J’ai déjà fait plusieurs sessions de coaching sur ce même sujet.
			</h3>
			<div style={{ flex: '1 0 0', overflowY: 'auto' }}>
				<p>
					Certaines thématiques peuvent demander un suivi
					thérapeutique plus profond (par exemple en utilisant des
					outils comme l’hypnose, EMDR, une thérapie
					cognitivo-comportementale, etc...). ou tout simplement un
					coaching plus personnalisé.
				</p>
				<p>
					Je te conseille de contacter un thérapeute ou un coach qui
					correspond à tes besoins.
				</p>
				<p>
					Ensuite, le coaching pourra être complémentaire à
					l'accompagnement et t’aider à mieux avancer sur ta
					problématique.
				</p>
				<p>Je te souhaite un bon accompagnement.</p>
			</div>

			<div style={{ flex: '0 0 fit-content' }}>
				<Button
					onClick={quitCoaching}
					variant='contained'
					style={{ color: 'white', margin: '8px 0px' }}
					fullWidth
				>
					Retour à l’accueil
				</Button>
			</div>
		</div>
	)
}

export default ResultPersistingSymptomsForm

import Tips from 'domain/Support/PerspectiveSolver/core/tips/Tips'

const tips: Tips = {
	instruction: {
		title: '', //`Comment identifier mes pensées ?`,
		pages: [
			{
				text: [
					`Comment identifier tes pensées ?`,
					`Pour t’aider, <b>repense à ta situation</b> et poses-toi les questions :`,
					`- Quelles sont les pensées qui me viennent spontanément ?
- Quelles images me viennent en premier à l’esprit ?`,
					`Une pensée c’est par exemple :`,
					`- <i>Je ne pense pas y arriver</i>
- <i>J’ai peur de regretter ma décision</i>
- <i>Et si je manquais d’argent avec ce travail ?</i>
- <i>Je ne sais pas quoi faire de ma vie</i>`,
					`En général, il y a entre <b>2 et 5 pensées.</b>`,
					`Attention, ajoute seulement des pensées <b>en lien avec ton objectif pour ce coaching</b>  !`,
				],
				confirmLabel: `J'écris mes pensées`,
			},
		],
	},
}

export default tips

import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Button } from '@mui/material'

import Instruction from 'domain/Support/PerspectiveSolver/core/tips/Instruction'

import InstructionPage from './InstructionPage/InstructionPage'
import Stepper from './Stepper/Stepper'

import './styles.css'

type InstructionComponentProps = {
	instruction: Instruction
	pageIndex: number
	confirmable: boolean
	updatePageIndex: (index: number) => void
	close: () => void
}

function InstructionComponent({
	instruction,
	pageIndex,
	confirmable,
	updatePageIndex,
	close,
}: InstructionComponentProps) {
	/**
	 * Refs
	 */

	const contentElementRef = useRef<HTMLDivElement>(null)

	/**
	 * Memo
	 */

	const isLast = useMemo(() => {
		if (pageIndex + 1 >= instruction.pages.length) return true
		return false
	}, [instruction, pageIndex])

	const instructionPage = useMemo(() => {
		if (
			instruction.pages.length === 0 ||
			pageIndex >= instruction.pages.length
		)
			return null

		return instruction.pages[pageIndex]
	}, [instruction, pageIndex])

	/**
	 * Callbacks
	 */

	const onConfirm = useCallback(() => {
		if (isLast) close()
		else {
			updatePageIndex(pageIndex + 1)
		}
	}, [isLast, pageIndex, updatePageIndex, close])

	/**
	 * Effects
	 */

	useEffect(() => {
		if (pageIndex >= 0) contentElementRef.current?.scrollTo({ top: 0 })
	}, [pageIndex])

	/**
	 * Render
	 */

	const confirmButtonRender = useMemo(() => {
		if (isLast && confirmable === false) return null

		if (instructionPage === null) return null

		return (
			<div className='tips-instruction-confirm-button'>
				<Button
					variant='contained'
					style={{ color: 'white' }}
					onClick={onConfirm}
				>
					{instructionPage.confirmLabel}
				</Button>
			</div>
		)
	}, [isLast, confirmable, instructionPage, onConfirm])

	const stepperRender = useMemo(() => {
		if (instruction.pages.length <= 1)
			return <div className='tips-instruction-stepper-placeholder' />

		return (
			<div className='tips-instruction-stepper'>
				<Stepper
					stepsNumber={instruction.pages.length}
					activeStep={pageIndex}
				/>
			</div>
		)
	}, [instruction, pageIndex])

	if (instructionPage === null) return null

	return (
		<div className='tips-instruction'>
			{/*
			<div className='tips-instruction-title'>
				<h3 className='tips-layout-title'>{instruction.title}</h3>
			</div>
			*/}
			<div
				className='tips-instruction-page-container'
				ref={contentElementRef}
			>
				<InstructionPage instructionPage={instructionPage} />
			</div>
			{confirmButtonRender}
			{stepperRender}
		</div>
	)
}

export default InstructionComponent

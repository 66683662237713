import { useCallback, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { BottomNavigation, BottomNavigationAction } from '@mui/material'

import HomeIconActive from 'presentation/views/components/icons/HomeIconActive'
import HomeIconInactive from 'presentation/views/components/icons/HomeIconInactive'
import NotesIconActive from 'presentation/views/components/icons/NotesIconActive'
import NotesIconInactive from 'presentation/views/components/icons/NotesIconInactive'
import SnapshotIconActive from 'presentation/views/components/icons/SnapshotIconActive'
import SnapshotIconInactive from 'presentation/views/components/icons/SnapshotIconInactive'
import ProfileIconActive from 'presentation/views/components/icons/ProfileIconActive'
import ProfileIconInactive from 'presentation/views/components/icons/ProfileIconInactive'

export default function View() {
	const navigate = useNavigate()
	const location = useLocation()

	const currentTabIndex = useMemo(() => {
		if (location.pathname === '/') return 0
		else if (location.pathname === '/notes') return 1
		else if (location.pathname === '/etoile-de-vie') return 2
		else if (location.pathname === '/autocoaching') return 3
		else return 0
	}, [location])

	const onChange = useCallback(
		(_: React.SyntheticEvent<Element, Event>, tabIndex: number) => {
			if (currentTabIndex === tabIndex) return

			if (tabIndex === 0) navigate('/')
			else if (tabIndex === 1) navigate('/notes')
			else if (tabIndex === 2) navigate('/etoile-de-vie')
			else if (tabIndex === 3) navigate('/autocoaching')
		},
		[currentTabIndex, navigate]
	)

	return (
		<BottomNavigation
			sx={{ width: '100%', zIndex: 1000 }}
			showLabels
			value={currentTabIndex}
			onChange={onChange}
		>
			<BottomNavigationAction
				className='bottomNav'
				sx={{ width: '100%' }}
				label='Accueil'
				icon={
					currentTabIndex === 0 ? (
						<HomeIconActive
							sx={{ width: '2rem', height: '2rem' }}
						/>
					) : (
						<HomeIconInactive
							sx={{ width: '2rem', height: '2rem' }}
						/>
					)
				}
			/>
			<BottomNavigationAction
				className='bottomNav'
				sx={{ width: '100%' }}
				label='Notes'
				icon={
					currentTabIndex === 1 ? (
						<NotesIconActive
							sx={{ width: '2rem', height: '2rem' }}
						/>
					) : (
						<NotesIconInactive
							sx={{ width: '2rem', height: '2rem' }}
						/>
					)
				}
			/>
			<BottomNavigationAction
				className='bottomNav'
				sx={{ width: '100%' }}
				label='Etoile de vie'
				icon={
					currentTabIndex === 2 ? (
						<SnapshotIconActive
							sx={{ width: '2rem', height: '2rem' }}
						/>
					) : (
						<SnapshotIconInactive
							sx={{ width: '2rem', height: '2rem' }}
						/>
					)
				}
			/>
			<BottomNavigationAction
				className='bottomNav'
				sx={{ width: '100%' }}
				label='Coaching'
				icon={
					currentTabIndex === 3 ? (
						<ProfileIconActive
							sx={{ width: '2rem', height: '2rem' }}
						/>
					) : (
						<ProfileIconInactive
							sx={{ width: '2rem', height: '2rem' }}
						/>
					)
				}
			/>
		</BottomNavigation>
	)
}

import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { produce } from 'immer'
import { nanoid } from 'nanoid'

import Coaching from 'domain/Support/PerspectiveSolver/core/Coaching'
import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'
import CoachingStep from 'domain/Support/PerspectiveSolver/core/CoachingStep'

import useInteractors from 'presentation/hooks/useInteractors'

import PerceptionSolverDemoVideoModal from 'presentation/views/components/PerceptionSolverDemoVideoModal/View'

import ProblematicPicker from './ProblematicPicker/View'
import Situation from './Situation/Situation'

function createSituation(): CoachingSituation {
	const situation: CoachingSituation = {
		id: nanoid(),

		currentIteration: 1,
		currentStep: CoachingStep.NeutralAnalysis,

		hasFreeDescription: false,
		freeDescription: '',
		description: '',

		analysis: {
			feeling: '',
			thoughts: [],
			mostPowerfulThoughtIndex: 0,
		},

		transformation: {
			feeling: '',
			actions: [],
			thoughts: [],
			mostPowerfulThoughtIndex: 0,
		},

		result: {
			feeling: '',
		},
	}

	return situation
}

export default function View() {
	/**
	 * Base
	 */

	const navigate = useNavigate()
	const { autocoachingId } = useParams()

	const { coachingInteractor } = useInteractors()

	/**
	 * States
	 */

	const [coaching, setCoaching] = useState<Coaching | null>(null)
	const [loadedCoaching, setLoadedCoaching] = useState<boolean>(false)

	/**
	 * Callbacks
	 */

	const loadCoaching = useCallback(async () => {
		if (autocoachingId === undefined) return

		try {
			const coaching = await coachingInteractor.get(autocoachingId)
			setCoaching(coaching)
		} catch (error) {
			alert("Coaching introuvable.\nRetour à l'accueil")
			navigate('/')
		} finally {
			setLoadedCoaching(true)
		}
	}, [coachingInteractor, autocoachingId, navigate])

	const quitCoaching = useCallback(() => {
		navigate('/')
	}, [navigate])

	const updateSituation = useCallback(
		(updatedSituation: CoachingSituation) => {
			if (coaching === null) return

			const updatedCoaching = produce(coaching, (coaching) => {
				for (let i = 0; i < coaching.situations.length; i++) {
					if (coaching.situations[i].id === updatedSituation.id) {
						coaching.situations[i] = updatedSituation
						break
					}
				}
				return coaching
			})

			coachingInteractor.update(updatedCoaching)

			setCoaching(updatedCoaching)
		},
		[coachingInteractor, coaching]
	)

	const startNewSituation = useCallback(() => {
		if (coaching === null) return

		const updatedCoaching = produce(coaching, (coaching) => {
			coaching.situations.push(createSituation())
			return coaching
		})

		coachingInteractor.update(updatedCoaching)

		setCoaching(updatedCoaching)
	}, [coachingInteractor, coaching])

	/**
	 * Effects
	 */

	useEffect(() => {
		if (autocoachingId) loadCoaching()
	}, [autocoachingId, loadCoaching])

	/**
	 * Render
	 */

	if (autocoachingId === undefined)
		return <ProblematicPicker quitCoaching={quitCoaching} />

	if (loadedCoaching === false || coaching === null)
		return <div>Chargement</div>

	return (
		<>
			<Situation
				situation={coaching.situations[coaching.situations.length - 1]}
				situations={coaching.situations}
				updateSituation={updateSituation}
				startNewSituation={startNewSituation}
				quitCoaching={quitCoaching}
			/>
			<PerceptionSolverDemoVideoModal />
		</>
	)
}

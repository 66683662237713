import { nanoid } from 'nanoid'

import CoachingInteractor from '../interfaces/CoachingInteractor'
import CoachingRepository from '../interfaces/CoachingRepository'

import Coaching from './Coaching'
import CoachingSituation from './CoachingSituation'
import CoachingStep from './CoachingStep'

import SupportType from 'domain/Support/interfaces/SupportType'

/**
 * Problematic imports
 */

import ProblematicRepository from 'domain/Diagnostic/Problematic/interfaces/ProblematicRepository'
import LocalStorageProblematicRepository from 'domain/Diagnostic/Problematic/core/LocalStorageProblematicRepository'

/**
 * Class
 */

export default class CoachingInteractorImpl implements CoachingInteractor {
	problematicRepository: ProblematicRepository

	constructor(private coachingRepository: CoachingRepository) {
		// TODO: Inject repository into Constructor from presentation layer
		this.problematicRepository = new LocalStorageProblematicRepository()
	}

	add = async () => {
		//  Create Problematic
		const date = new Date()
		const jour = String(date.getDate()).padStart(2, '0')
		const mois = String(date.getMonth() + 1).padStart(2, '0')
		const annee = date.getFullYear()
		const heures = String(date.getHours()).padStart(2, '0')
		const minutes = String(date.getMinutes()).padStart(2, '0')

		const problematic = await this.problematicRepository.addProblematic(
			`Coaching du ${jour}/${mois}/${annee} ${heures}:${minutes}`,
			[]
		)

		const coaching: Coaching = {
			id: nanoid(),
			problematicId: problematic.id,
			situations: [createSituation()],
		}

		await this.coachingRepository.save(coaching)

		problematic.supports.push({
			type: SupportType.PerspectiveSolver,
			id: coaching.id,
			done: false,
		})

		await this.problematicRepository.updateProblematic(problematic)

		return coaching
	}

	addFromProblematic = async (problematicId: string) => {
		const problematic = await this.problematicRepository.getProblematic(
			problematicId
		)
		const problematicDescription =
			problematic.description === ''
				? problematic.freeDescription
				: problematic.description

		const coaching: Coaching = {
			id: nanoid(),
			problematicId: problematicId,
			situations: [createSituation()],
		}

		coaching.situations[0].description = problematicDescription
		coaching.situations[0].freeDescription = problematicDescription

		await this.coachingRepository.save(coaching)

		problematic.supports.push({
			type: SupportType.PerspectiveSolver,
			id: coaching.id,
			done: false,
		})

		await this.problematicRepository.updateProblematic(problematic)

		return coaching
	}

	get = async (coachingId: string) => {
		return await this.coachingRepository.get(coachingId)
	}

	update = async (coaching: Coaching) => {
		const problematic = await this.problematicRepository.getProblematic(
			coaching.problematicId
		)

		const currentCoachingSituation =
			coaching.situations[coaching.situations.length - 1]
		problematic.freeDescription = currentCoachingSituation.freeDescription
		problematic.description = currentCoachingSituation.description
		problematic.supports[0].done =
			currentCoachingSituation.currentStep === CoachingStep.ResultSuccess

		await this.problematicRepository.updateProblematic(problematic)

		await this.coachingRepository.save(coaching)
	}
}

function createSituation(): CoachingSituation {
	const situation: CoachingSituation = {
		id: nanoid(),

		currentIteration: 1,
		currentStep: CoachingStep.NeutralAnalysis,

		hasFreeDescription: false,
		freeDescription: '',
		description: '',

		analysis: {
			feeling: '',
			thoughts: [],
			mostPowerfulThoughtIndex: 0,
		},

		transformation: {
			feeling: '',
			actions: [],
			thoughts: [],
			mostPowerfulThoughtIndex: 0,
		},

		result: {
			feeling: '',
		},
	}

	return situation
}

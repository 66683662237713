import StrategiesCategories from 'domain/Psycho/core/data-access/StrategiesCategoriesMock'

import CategoriesQuestionnaireDTO from '../interfaces/data-transfer-objects/CategoriesQuestionnaireDTO'
import QuestionDTO from '../interfaces/data-transfer-objects/QuestionDTO'
import CategoryDTO from '../interfaces/data-transfer-objects/CategoryDTO'
import CategorizedAnswer from '../interfaces/data-transfer-objects/CategorizedAnswerDTO'

import Questions from './data-access/QuestionsMock'

class Questionnaire {
	selectedCategories: Array<CategoryDTO>
	questions: Array<QuestionDTO>
	selectedAnswers: Array<string>

	constructor(categoriesQuestionnaire: CategoriesQuestionnaireDTO) {
		this.selectedCategories = categoriesQuestionnaire.selectedCategories
		this.questions = categoriesQuestionnaire.questions
		this.selectedAnswers = categoriesQuestionnaire.selectedAnswers
	}

	toggleCategory = async (categoryId: string) => {
		const selectedCategory = this.selectedCategories.find(
			(category) => category.id === categoryId
		)

		this.selectedCategories = []
		this.questions = []

		if (selectedCategory === undefined) {
			const category = StrategiesCategories.find(
				(strategyCategory) => strategyCategory.id === categoryId
			)
			if (category === undefined)
				throw Error('Tried to toggle a category that does ot exist')

			this.selectedCategories.push({
				id: category.id,
				label: category.label,
			})

			for (let i = 0; i < Questions.length; i++) {
				if (Questions[i].categoryId === categoryId) {
					this.questions.push(Object.assign({}, Questions[i]))
				}
			}
		}

		// TODO: Check if best strategy
		this.selectedAnswers = []
	}

	answerQuestion = async (questionIndex: number, answer: string) => {
		this.questions[questionIndex].answer = answer
	}

	toggleAnswerSelection = async (questionId: string) => {
		const selectedAnswer = this.selectedAnswers.find(
			(answer) => answer === questionId
		)

		if (selectedAnswer !== undefined) {
			this.selectedAnswers = this.selectedAnswers.filter(
				(answer) => answer !== questionId
			)
		} else {
			this.selectedAnswers.push(questionId)
		}
	}

	confirm = async () => {
		const answers: Array<CategorizedAnswer> = []

		for (let i = 0; i < this.questions.length; i++) {
			if (this.questions[i].answer === undefined) continue

			for (let y = 0; y < this.selectedAnswers.length; y++) {
				if (this.questions[i].id === this.selectedAnswers[y]) {
					if (this.questions[i].answer !== undefined) {
						answers.push({
							answer:
								`${this.questions[i].answerPrefix}${this.questions[i].answer}` ??
								'',
							categoryId: this.questions[i].categoryId,
						})
					}

					break
				}
			}
		}

		return answers
	}
}

export default Questionnaire

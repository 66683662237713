import { SvgIcon, SvgIconProps } from '@mui/material'

export default function HomeIconActive(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<svg
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M6 7C6 5.34315 7.34315 4 9 4H16C17.6569 4 19 5.34315 19 7V17C19 18.6569 17.6569 20 16 20H9C7.34315 20 6 18.6569 6 17V15H5.5C5.22386 15 5 14.7761 5 14.5C5 14.2239 5.22386 14 5.5 14H6V12H5.5C5.22386 12 5 11.7761 5 11.5C5 11.2239 5.22386 11 5.5 11H6V9H5.5C5.22386 9 5 8.77614 5 8.5C5 8.22386 5.22386 8 5.5 8H6V7ZM8.5 6C8.22386 6 8 6.22386 8 6.5V17.5C8 17.7761 8.22386 18 8.5 18C8.77614 18 9 17.7761 9 17.5V6.5C9 6.22386 8.77614 6 8.5 6ZM10 7.5C10 7.22386 10.2239 7 10.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H10.5C10.2239 8 10 7.77614 10 7.5ZM10.5 9C10.2239 9 10 9.22386 10 9.5C10 9.77614 10.2239 10 10.5 10H15.5C15.7761 10 16 9.77614 16 9.5C16 9.22386 15.7761 9 15.5 9H10.5Z'
					className='iconfill'
				/>
			</svg>
		</SvgIcon>
	)
}

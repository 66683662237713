import {
  Box,
  Grid,
  Link,
  Avatar,
  Button,
  Container,
  Typography,
  FormControl,
  CssBaseline,
} from "@mui/material";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { useNavigate } from "react-router-dom";

import { Copyright } from "../../components/Auth/Copyright";

export default function InvalidLink() {
  const navigate = useNavigate();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Ce lien n'est pas valide ou a expiré, avez vous oublié votre mot de
          passe ?
        </Typography>
        <FormControl sx={{ mt: 1 }} fullWidth>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => {
              navigate("/password-reset");
            }}
          >
            Réinitialiser mon mot de passe
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                href="/login"
                variant="body2"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Se connecter
              </Link>
            </Grid>
          </Grid>
        </FormControl>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}

type Content = {
	id: string
	text: string
}

const examples: Array<Content> = [
	{
		id: '1',
		text: `Demain j'ai mon premier jour de travail`,
	},
	{
		id: '2',
		text: `Je vois ma sœur durant le déjeuner`,
	},
	{
		id: '3',
		text: `Ma situation financière pour le mois prochain`,
	},
	{
		id: '4',
		text: `La semaine prochaine je prends l'avion`,
	},
	{
		id: '5',
		text: `J'aimerais parler avec mon patron`,
	},
	{
		id: '6',
		text: `Hier mon conjoint m'a dit "tu ne participes pas assez à la maison"`,
	},
	{
		id: '7',
		text: `J'ai vu ma collègue lever les yeux quand j'ai parlé de mon weekend`,
	},
	{
		id: '8',
		text: `Je suis en reconversion professionnelle`,
	},
]

export default examples

import { useCallback, useMemo } from 'react'
import { produce } from 'immer'
import { Box, Paper, useTheme, useMediaQuery } from '@mui/material'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'
import CoachingStep from 'domain/Support/PerspectiveSolver/core/CoachingStep'

import Tips from 'presentation/views/components/Tips/Layout/Layout'

import NavigationBar from './NavigationBar/NavigationBar'
import NeutralObservation from './NeutralObservation/NeutralObservation'
import NegativeAnalysis from './NegativeAnalysis/NegativeAnalysis'
import PositiveTransformation from './PositiveTransformation/PositiveTransformation'
import Result from './Result/Result'

import SituationFormTips from 'domain/Support/PerspectiveSolver/core/data-access/tips/SituationFormTips'
import FreeDescriptionFormTips from 'domain/Support/PerspectiveSolver/core/data-access/tips/FreeDescriptionFormTips'
import NegativeThoughtChoiceForm from 'domain/Support/PerspectiveSolver/core/data-access/tips/NegativeThoughtChoiceForm'
import NegativeThoughtsFormTips from 'domain/Support/PerspectiveSolver/core/data-access/tips/NegativeThoughtsFormTips'
import PositiveActionsFormTips from 'domain/Support/PerspectiveSolver/core/data-access/tips/PositiveActionsFormTips'
import PositiveFeelingFormTips from 'domain/Support/PerspectiveSolver/core/data-access/tips/PositiveFeelingFormTips'
import PositiveThoughtChoiceForm from 'domain/Support/PerspectiveSolver/core/data-access/tips/PositiveThoughtChoiceForm'
import PositiveThoughtsFormTips from 'domain/Support/PerspectiveSolver/core/data-access/tips/PositiveThoughtsFormTips'
import ResultFeelingForm from 'domain/Support/PerspectiveSolver/core/data-access/tips/ResultFeelingForm'

import './styles.css'

type SituationViewProps = {
	situation: CoachingSituation
	situations: Array<CoachingSituation>
	updateSituation: (updatedSituation: CoachingSituation) => void
	startNewSituation: () => void
	quitCoaching: () => void
}

function SituationView({
	situation,
	situations,
	updateSituation,
	startNewSituation,
	quitCoaching,
}: SituationViewProps) {
	const theme = useTheme()
	const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

	/**
	 * Memo
	 */

	const isNeutralObservationStep = useMemo(() => {
		if (situation.currentStep === CoachingStep.NeutralAnalysis) return true
		if (situation.currentStep === CoachingStep.NeutralFreeDescription)
			return true
		if (situation.currentStep === CoachingStep.NeutralSituation) return true
		return false
	}, [situation])

	const isNegativeAnalysisStep = useMemo(() => {
		if (situation.currentStep === CoachingStep.NegativeFeeling) return true
		if (situation.currentStep === CoachingStep.NegativeThoughts) return true
		if (situation.currentStep === CoachingStep.NegativeThoughtChoice)
			return true
		return false
	}, [situation])

	const isPositiveTransformationStep = useMemo(() => {
		if (situation.currentStep === CoachingStep.PositiveFeeling) return true
		if (situation.currentStep === CoachingStep.PositiveActions) return true
		if (situation.currentStep === CoachingStep.PositiveThoughts) return true
		if (situation.currentStep === CoachingStep.PositiveThoughtChoice)
			return true
		if (situation.currentStep === CoachingStep.PositiveRecap) return true
		return false
	}, [situation])

	const isResultStep = useMemo(() => {
		if (situation.currentStep === CoachingStep.ResultFeeling) return true
		if (situation.currentStep === CoachingStep.ResultSuccess) return true
		if (situation.currentStep === CoachingStep.ResultRedo) return true
		if (situation.currentStep === CoachingStep.ResultNoNeutralSituation)
			return true
		if (
			situation.currentStep === CoachingStep.ResultInvalidPositiveThoughts
		)
			return true
		if (situation.currentStep === CoachingStep.ResultAdditionalSituation)
			return true
		if (situation.currentStep === CoachingStep.ResultInvalidSituation)
			return true
		if (situation.currentStep === CoachingStep.ResultPersistingSymptoms)
			return true

		return false
	}, [situation])

	/**
	 * Callbacks
	 */

	const goBack = useCallback(() => {
		if (situation.currentStep === CoachingStep.NeutralAnalysis) return

		const updatedSituation = produce(situation, (situation) => {
			if (situation.currentStep === CoachingStep.NeutralFreeDescription)
				situation.currentStep = CoachingStep.NeutralAnalysis
			else if (situation.currentStep === CoachingStep.NeutralSituation) {
				if (situation.hasFreeDescription === true)
					situation.currentStep = CoachingStep.NeutralFreeDescription
				else situation.currentStep = CoachingStep.NeutralAnalysis
			} else if (situation.currentStep === CoachingStep.NegativeFeeling)
				situation.currentStep = CoachingStep.NeutralSituation
			else if (situation.currentStep === CoachingStep.NegativeThoughts)
				situation.currentStep = CoachingStep.NegativeFeeling
			else if (
				situation.currentStep === CoachingStep.NegativeThoughtChoice
			)
				situation.currentStep = CoachingStep.NegativeThoughts
			else if (situation.currentStep === CoachingStep.PositiveFeeling) {
				if (situation.analysis.thoughts.length > 1)
					situation.currentStep = CoachingStep.NegativeThoughtChoice
				else situation.currentStep = CoachingStep.NegativeThoughts
			} else if (situation.currentStep === CoachingStep.PositiveActions)
				situation.currentStep = CoachingStep.PositiveFeeling
			else if (situation.currentStep === CoachingStep.PositiveThoughts)
				situation.currentStep = CoachingStep.PositiveActions
			else if (
				situation.currentStep === CoachingStep.PositiveThoughtChoice
			)
				situation.currentStep = CoachingStep.PositiveThoughts
			else if (situation.currentStep === CoachingStep.PositiveRecap)
				if (situation.transformation.thoughts.length > 1)
					situation.currentStep = CoachingStep.PositiveThoughtChoice
				else situation.currentStep = CoachingStep.PositiveThoughts
			else if (situation.currentStep === CoachingStep.ResultFeeling)
				situation.currentStep = CoachingStep.PositiveRecap
			else if (situation.currentStep === CoachingStep.ResultSuccess)
				situation.currentStep = CoachingStep.ResultFeeling
			else if (situation.currentStep === CoachingStep.ResultRedo)
				situation.currentStep = CoachingStep.ResultFeeling
			else if (
				situation.currentStep === CoachingStep.ResultNoNeutralSituation
			)
				situation.currentStep = CoachingStep.ResultRedo
			else if (
				situation.currentStep ===
				CoachingStep.ResultInvalidPositiveThoughts
			)
				situation.currentStep = CoachingStep.ResultRedo
			else if (
				situation.currentStep === CoachingStep.ResultAdditionalSituation
			)
				situation.currentStep = CoachingStep.ResultRedo
			else if (
				situation.currentStep === CoachingStep.ResultInvalidSituation
			)
				situation.currentStep = CoachingStep.ResultRedo
			else if (
				situation.currentStep === CoachingStep.ResultPersistingSymptoms
			)
				situation.currentStep = CoachingStep.ResultRedo

			return situation
		})

		updateSituation(updatedSituation)
	}, [situation, updateSituation])

	/**
	 * Render
	 */

	const stepForm = useMemo(() => {
		if (isNeutralObservationStep) {
			return (
				<NeutralObservation
					situation={situation}
					situations={situations}
					updateSituation={updateSituation}
				/>
			)
		} else if (isNegativeAnalysisStep) {
			return (
				<NegativeAnalysis
					situation={situation}
					situations={situations}
					updateSituation={updateSituation}
				/>
			)
		} else if (isPositiveTransformationStep) {
			return (
				<PositiveTransformation
					situation={situation}
					situations={situations}
					updateSituation={updateSituation}
				/>
			)
		} else if (isResultStep) {
			return (
				<Result
					situation={situation}
					situations={situations}
					updateSituation={updateSituation}
					startNewSituation={startNewSituation}
					quitCoaching={quitCoaching}
				/>
			)
		} else return null
	}, [
		isNeutralObservationStep,
		isNegativeAnalysisStep,
		isPositiveTransformationStep,
		isResultStep,
		situation,
		situations,
		updateSituation,
		startNewSituation,
		quitCoaching,
	])

	const bigScreenTips = useMemo(() => {
		if (situation.currentStep === CoachingStep.NeutralFreeDescription)
			return FreeDescriptionFormTips
		else if (situation.currentStep === CoachingStep.NeutralSituation)
			return SituationFormTips
		else if (situation.currentStep === CoachingStep.NegativeThoughts)
			return NegativeThoughtsFormTips
		else if (situation.currentStep === CoachingStep.NegativeThoughtChoice)
			return NegativeThoughtChoiceForm
		else if (situation.currentStep === CoachingStep.PositiveFeeling)
			return PositiveFeelingFormTips
		else if (situation.currentStep === CoachingStep.PositiveActions)
			return PositiveActionsFormTips
		else if (situation.currentStep === CoachingStep.PositiveThoughts)
			return PositiveThoughtsFormTips
		else if (situation.currentStep === CoachingStep.PositiveThoughtChoice)
			return PositiveThoughtChoiceForm
		else if (situation.currentStep === CoachingStep.ResultFeeling)
			return ResultFeelingForm

		return null
	}, [situation])

	const situationReminderRender = useMemo(() => {
		if (situation.description.trim() === '') return null

		if (smallScreen) {
			return (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '8px',
						padding: '12px 16px',
					}}
				>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<p
								style={{
									maxWidth: '100%',
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									fontSize: '0.875rem',
								}}
							>
								{situation.description}
							</p>
						</AccordionSummary>
						<AccordionDetails>
							{situation.description}
						</AccordionDetails>
					</Accordion>
				</Box>
			)
		} else {
			return (
				<>
					<Box
						sx={{
							display: 'flex',
							flexDirection: {
								sm: 'row',
								md: 'column',
							},
							gap: {
								sm: '16px',
								md: '0',
							},
						}}
					>
						<h4
							style={{
								fontSize: '1.125rem',
							}}
						>
							Rappel de la situation
						</h4>
						<Box sx={{ fontSize: '1rem' }}>
							{situation.description}
						</Box>
					</Box>
				</>
			)
		}
	}, [smallScreen, situation])

	const smallScreenLayout = useMemo(
		() => (
			<>
				<div className='coaching-situation-navigation-container'>
					<NavigationBar
						isFirstStep={
							situation.currentStep ===
							CoachingStep.NeutralAnalysis
						}
						goBack={goBack}
						quit={quitCoaching}
					/>
				</div>
				{situationReminderRender}
				<div className='coaching-situation-container'>{stepForm}</div>
			</>
		),
		[situation, goBack, quitCoaching, stepForm, situationReminderRender]
	)

	const bigScreenLayout = useMemo(
		() => (
			<>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						height: 'fit-content',
						width: '100%',
						gap: '24px',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							width: '100%',
							height: 'fit-content',
							gap: '32px',
							alignItems: 'center',
							borderRadius: '8px',
							padding: '24px',
							backgroundColor: theme.palette.background.paper,
						}}
					>
						<h2
							style={{
								margin: '0px',
								textAlign: 'left',
								width: 'inherit',
							}}
						>
							Autocoaching
						</h2>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								width: '100%',
								height: 'fit-content',
								gap: '32px',
								justifyContent: 'flex-end',
							}}
						>
							<div
								className='coaching-situation-navigation-container'
								style={{ width: '100%', height: 'fit-content' }}
							>
								<NavigationBar
									isFirstStep={
										situation.currentStep ===
										CoachingStep.NeutralAnalysis
									}
									goBack={goBack}
									quit={quitCoaching}
								/>
							</div>
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: {
							sm: 'column',
							md: 'row',
						},
						height: '100%',
						width: '100%',
						gap: '32px',
					}}
				>
					{bigScreenTips && (
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								flex: '1 0 0',
								height: '100%',
								gap: '16px',
							}}
						>
							<Paper
								sx={{
									display: 'flex',
									flexDirection: 'column',
									backgroundColor:
										theme.palette.background.paper,
									padding: {
										sm: '24px 24px 16px 24px',
										md: '24px',
									},
									borderRadius: '8px',
									height: 'auto',
									minWidth: '33%',
									maxWidth: '768px',
								}}
							>
								{situationReminderRender}
							</Paper>
							<Paper
								sx={{
									display: 'flex',
									flexDirection: 'column',
									backgroundColor:
										theme.palette.background.paper,
									padding: '24px',
									borderRadius: '8px',
									height: '100%',
									minWidth: '33%',
									maxWidth: '768px',
								}}
							>
								<Tips
									tips={bigScreenTips}
									closable={false}
									confirmable={false}
									close={() => {}}
								/>
							</Paper>
						</Box>
					)}

					<Box
						sx={{
							flex: '1 0 0',
							height: '100%',
							minWidth: '66%',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								height: '100%',
								width: '100%',
								maxWidth: '100%',
								boxShadow: 'none',
							}}
						>
							{stepForm}
						</Box>
					</Box>
				</Box>
			</>
		),
		[
			situation,
			goBack,
			quitCoaching,
			stepForm,
			bigScreenTips,
			theme,
			situationReminderRender,
		]
	)

	const contentRender = useMemo(() => {
		if (smallScreen) return smallScreenLayout

		return bigScreenLayout
	}, [smallScreen, smallScreenLayout, bigScreenLayout])

	return (
		<>
			{smallScreen ? (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',

						height: '100%',
						width: '100%',
					}}
				>
					{contentRender}
				</Box>
			) : (
				<>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: {
								sm: '16px',
								md: '32px',
							},
							padding: {
								sm: '48px 48px',
								md: '32px 32px',
								lg: '64px 64px',
							},
							height: '100%',
							width: '100%',
							maxWidth: '1248px',
							margin: '0 auto',
						}}
					>
						{contentRender}
					</Box>
				</>
			)}
		</>
	)
}

export default SituationView

export function formatSentence(sentence: string) {
	sentence = sentence.trim()

	if (sentence.length === 0) return sentence

	const firstLetterToUpperCase = sentence.slice(0, 1).toUpperCase()
	sentence = `${firstLetterToUpperCase}${sentence.slice(1)}`

	return sentence
}

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function HomeIcon(props: SvgIconProps) {
  return (
    <div>
      <SvgIcon {...props}>
        <rect y="4" width="24" height="2" rx="1" />
        <rect y="10" width="16" height="2" rx="1" />
      </SvgIcon>
    </div>
  );
}

import Weight from '../entities/Weight'
import WeightDTO from '../../interfaces/data-transfer-objects/WeightDTO'

class WeightMapper {
	static toDTO(weight: Weight): WeightDTO {
		return {
			code: weight.code,
			value: weight.value,
		}
	}
}

export default WeightMapper

import { Paper, useTheme, useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom'

export default function View() {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	return isMobile ? (
		<Link to='/etoile-de-vie'>
			<Paper
				sx={{
					background: theme.palette.primary.main,
					borderRadius: '8px',
					padding: '20px',
					height: 'fit-content',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					gap: '8px',
				}}
			>
				<h3
					style={{
						color: theme.palette.primary.contrastText,
						marginBottom: '0',
						fontSize: '1.25rem',
					}}
				>
					Mesure ton bien-être
				</h3>
				<p
					style={{
						color: theme.palette.primary.contrastText,
						fontSize: '1rem',
					}}
				>
					Réponds à quelques questions
				</p>
			</Paper>
		</Link>
	) : (
		<Link to='/etoile-de-vie'>
			<Paper
				sx={{
					display: 'flex',
					flexDirection: 'column',
					borderRadius: '8px',
					padding: '24px',
					gap: '16px',
					height: '100%',
					width: '100%',
					justifyContent: 'center',
					alignItems: 'center',
					background: theme.palette.primary.main,
				}}
			>
				<h3
					style={{
						color: theme.palette.primary.contrastText,
						marginBottom: '0px',
						fontSize: '1.5rem',
						textAlign: 'center',
					}}
				>
					Mesure ton bien-être
				</h3>
				<p
					style={{
						color: theme.palette.primary.contrastText,
						fontSize: '1rem',
						textAlign: 'center',
					}}
				>
					Réponds à quelques questions pour mesurer ton bien-être !
				</p>
			</Paper>
		</Link>
	)
}

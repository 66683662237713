import { Button, useTheme, useMediaQuery } from '@mui/material'

type Props = {
	onClick: () => void
}

export default function View({ onClick }: Props) {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	return (
		<Button
			color='primary'
			variant='contained'
			size='small'
			onClick={onClick}
			sx={{ width: isMobile ? '100%' : 'fit-content' }}
		>
			Ajouter une nouvelle note
		</Button>
	)
}

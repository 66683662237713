import { useCallback, useMemo, useRef } from 'react'
import {
	Box,
	ListItem,
	ListItemButton,
	ListItemText,
	Chip,
} from '@mui/material'

import ProblematicDTO from 'domain/Diagnostic/Problematic/interfaces/ProblematicDTO'

import CategoriesMock from 'domain/Psycho/core/data-access/StrategiesCategoriesMock'

const getCategoryStyles = (categoryLabel: string) => {
	switch (categoryLabel) {
		case 'Amitié': {
			return {
				backgroundColor: '#FCF3ED',
				color: '#E17F48',
			}
		}
		case 'Amour': {
			return {
				backgroundColor: '#FAEEEE',
				color: '#C54F4F',
			}
		}
		case 'Santé': {
			return {
				backgroundColor: '#EFF7F5',
				color: '#5FA69B',
			}
		}
		case 'Sport': {
			return {
				backgroundColor: '#EDF6F9',
				color: '#48A0BC',
			}
		}
		case 'Famille': {
			return {
				backgroundColor: '#FAEEF2',
				color: '#C95478',
			}
		}
		case 'Argent': {
			return {
				backgroundColor: '#EEF4FA',
				color: '#4E8BC5',
			}
		}
		case 'Travail': {
			return {
				backgroundColor: '#F2EFFC',
				color: '#785FDB',
			}
		}
		case 'Émotions': {
			return {
				backgroundColor: '#F6EFFA',
				color: '#A05DCA',
			}
		}
		default: {
			return {
				backgroundColor: '#E9E9E9',
				color: '#292827',
			}
		}
	}
}

type Props = {
	problematic: ProblematicDTO
	edit: (problematic: ProblematicDTO) => void
}

export default function View({ problematic, edit }: Props) {
	const description = useMemo(
		() =>
			problematic.description.trim() === ''
				? problematic.freeDescription
				: problematic.description,
		[problematic]
	)

	const widthRef = useRef<HTMLDivElement | null>(null)

	let hiddenCategoriesCount = 0
	const containerWidth = widthRef.current?.offsetWidth || 272 // Default maxWidth if not provided

	const categoriesRender = useMemo(() => {
		let availableWidth = containerWidth - 24

		return problematic.categories.map((categoryId) => {
			const category = CategoriesMock.find(
				(strategyCategory) => strategyCategory.id === categoryId
			)

			let chipWidth = 0

			if (category) {
				const { label } = category
				chipWidth = label.length * 8 + 28 || 0
			}

			if (category === undefined) return null
			else if (availableWidth >= chipWidth) {
				availableWidth -= chipWidth

				return (
					<Chip
						key={category.id}
						label={category.label}
						style={{
							backgroundColor: getCategoryStyles(category.label)
								.backgroundColor,
							color: getCategoryStyles(category.label).color,
							fontSize: '0.75rem',
							height: '24px',
						}}
					/>
				)
			} else {
				hiddenCategoriesCount++
				return null
			}
		})
	}, [problematic, containerWidth, hiddenCategoriesCount])

	const onEdit = useCallback(() => {
		edit(problematic)
	}, [problematic, edit])

	return (
		<ListItem onClick={onEdit}>
			<ListItemButton
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '4px',
					alignItems: 'flex-start',
				}}
				ref={widthRef}
			>
				{problematic.categories.length > 0 && (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							gap: '4px',
						}}
					>
						{categoriesRender}
						{hiddenCategoriesCount > 0 && (
							<Chip
								label={`+${hiddenCategoriesCount}`}
								style={{
									backgroundColor: '#E9E9E9',
									color: '#292827',
									fontSize: '0.75rem',
									height: '24px',
								}}
							/>
						)}
					</Box>
				)}
				<ListItemText primary={description} />
			</ListItemButton>
		</ListItem>
	)
}

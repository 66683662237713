import ProblematicInteractor from '../interfaces/ProblematicInteractor'
import ProblematicRepository from '../interfaces/ProblematicRepository'

/**
 * Coaching
 */

import CoachingRepository from 'domain/Support/PerspectiveSolver/interfaces/CoachingRepository'
import SupportType from 'domain/Support/interfaces/SupportType'

export default class ProblematicInteractorImpl
	implements ProblematicInteractor
{
	constructor(
		private problematicRepository: ProblematicRepository,
		private coachingRepository: CoachingRepository
	) {}

	getProblematics = async () => {
		const problematics = await this.problematicRepository.getProblematics()
		return problematics
	}

	addProblematic = async (description: string, categories: Array<string>) => {
		const problematic = await this.problematicRepository.addProblematic(
			description,
			categories
		)
		return problematic
	}

	updateProblematic = async (
		id: string,
		description: string,
		categories: Array<string>
	) => {
		let problematic = await this.problematicRepository.getProblematic(id)

		problematic.freeDescription = description
		problematic.categories = categories

		await this.problematicRepository.updateProblematic(problematic)
		return problematic
	}

	removeProblematic = async (id: string) => {
		const problematic = await this.problematicRepository.getProblematic(id)

		if (problematic.supports.length > 0) {
			for (let i = 0; i < problematic.supports.length; i++) {
				const support = problematic.supports[i]
				if (support.type === SupportType.PerspectiveSolver)
					await this.coachingRepository.remove(support.id)
			}
		}

		await this.problematicRepository.removeProblematic(id)
	}
}

import { useMemo } from 'react'

import CategoriesQuestionnaire from 'domain/Diagnostic/WellBeingSnapshot/CategoriesQuestionnaire/interfaces/data-transfer-objects/CategoriesQuestionnaireDTO'

import CategoriesSelection from './CategoriesSelection/View'
import Question from './Question/View'
import AnswersSelection from './AnswersSelection/View'

interface Props {
	questionnaire: CategoriesQuestionnaire
	currentQuestionIndex: number | undefined
	toggleCategory: (categoryId: string) => Promise<void>
	toggleAnswerSelection: (questionId: string) => Promise<void>
	setAnswer: (answer: string) => Promise<void>
	confirmAnswer: () => Promise<void>
}

export default function View({
	questionnaire,
	currentQuestionIndex,
	toggleCategory,
	toggleAnswerSelection,
	setAnswer,
	confirmAnswer,
}: Props) {
	const content = useMemo(() => {
		if (questionnaire === null) return null

		if (currentQuestionIndex === undefined) {
			return (
				<CategoriesSelection
					selectedCategories={questionnaire.selectedCategories}
					toggleCategory={toggleCategory}
					confirmAnswer={confirmAnswer}
				/>
			)
		} else if (currentQuestionIndex < questionnaire.questions.length) {
			const question = questionnaire.questions[currentQuestionIndex]
			return (
				<Question
					question={question}
					setAnswer={setAnswer}
					confirmAnswer={confirmAnswer}
				/>
			)
		} else {
			return (
				<AnswersSelection
					questions={questionnaire.questions}
					selectedAnswers={questionnaire.selectedAnswers}
					toggleAnswerSelection={toggleAnswerSelection}
					confirmAnswer={confirmAnswer}
				/>
			)
		}
	}, [
		questionnaire,
		currentQuestionIndex,
		toggleCategory,
		toggleAnswerSelection,
		setAnswer,
		confirmAnswer,
	])

	return content
}

import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTheme, useMediaQuery, IconButton, Button } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import './styles.css'

type NavigationBarProps = {
	isFirstStep?: boolean
	goBack: () => void
	quit: () => void
}

function NavigationBar({ isFirstStep, goBack, quit }: NavigationBarProps) {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	/**
	 * Render
	 */

	const navigationClass = useMemo(
		() => {
			return 'coaching-navigation coaching-navigation-with-objective'
			/*
		if (situationDescriptionEnabled === false) return 'coaching-navigation'
		else return 'coaching-navigation coaching-navigation-with-objective'
		*/
		},
		[
			//situationDescriptionEnabled
		]
	)

	const backButtonRender = useMemo(() => {
		if (isFirstStep === true && isMobile === true)
			return <div style={{ width: 32 }} />
		else if (isFirstStep === true) return null
		else if (isMobile === false)
			return (
				<IconButton
					onClick={goBack}
					style={{
						height: '44px',
						width: '44px',
						backgroundColor: '#FFFFFF',
					}}
				>
					<ArrowBackIcon
						style={{
							color: '#ACABA9',
							fontSize: '1.5rem',
						}}
					/>
				</IconButton>
			)
		return (
			<IconButton
				onClick={goBack}
				style={{
					height: '32px',
					width: '32px',
				}}
				TouchRippleProps={{
					style: {
						height: '40px',
						width: '40px',
						transform: 'translateX(-4px) translateY(-4px)',
					},
				}}
			>
				<ArrowBackIcon
					style={{
						color: '#ACABA9',
						fontSize: '1.375rem',
					}}
				/>
			</IconButton>
		)
	}, [isMobile, isFirstStep, goBack])

	const closeButtonRender = useMemo(() => {
		if (isFirstStep === false && isMobile === true)
			return <div style={{ width: 32 }} />
		else if (isMobile === false)
			return (
				<Button
					color='secondary'
					variant='contained'
					size='small'
					fullWidth={isMobile}
					onClick={quit}
				>
					Sauvegarder et quitter
				</Button>
			)
		return (
			<Link to='/'>
				<IconButton
					size='large'
					onClick={quit}
					style={{
						height: '32px',
						width: '32px',
					}}
					TouchRippleProps={{
						style: {
							height: '40px',
							width: '40px',
							transform: 'translateX(-4px) translateY(-4px)',
						},
					}}
				>
					<CloseIcon
						style={{ color: '#ACABA9', fontSize: '1.125rem' }}
					/>
				</IconButton>
			</Link>
		)
	}, [isMobile, isFirstStep, quit])

	return isMobile ? (
		<div className={navigationClass}>
			<div className='coaching-navigation-actions-container'>
				{backButtonRender}
				<h2 style={{ margin: '0px' }}>Autocoaching</h2>
				{closeButtonRender}
			</div>
		</div>
	) : (
		<div
			className={navigationClass}
			style={{
				gap: '24px',
				paddingBottom: '0px',
				width: '100%',
				flex: 'unset',
			}}
		>
			<div
				className='coaching-navigation-actions-container'
				style={{ gap: '16px' }}
			>
				{backButtonRender}
				{closeButtonRender}
			</div>
		</div>
	)
}

export default NavigationBar

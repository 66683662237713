import Tips from 'domain/Support/PerspectiveSolver/core/tips/Tips'

const tips: Tips = {
	instruction: {
		title: '', //`Comment savoir ce que je souhaiterais ressentir ?`,
		pages: [
			{
				text: [
					`Comment te sens-tu là maintenant ?`,
					`C’est la dernière étape : bravo pour ton parcours !`,
					`Pour savoir si <b>tu as réussi ce coaching</b>, tu vas pouvoir faire le bilan avec cette question :`,
					`Comment <b>te sens-tu maintenant</b> à propos de <b>ta situation</b> ?`,
				],
				confirmLabel: `Je choisis mon sentiment`,
			},
		],
	},
}

export default tips

import { useMemo } from 'react'

import { List, ListItem, ListItemText } from '@mui/material'

import CoachingThought from 'domain/Support/PerspectiveSolver/core/CoachingThought'

type ThoughtsListProps = {
	thoughts: Array<CoachingThought>
}

function ThoughtsList({ thoughts }: ThoughtsListProps) {
	/**
	 * Render
	 */

	const thoughtsRender = useMemo(() => {
		return thoughts.map((thought, index) => (
			<ListItem key={thought.id}>
				<ListItemText primary={thought.text} />
			</ListItem>
		))
	}, [thoughts])

	if (thoughts.length === 0) return null

	return <List dense>{thoughtsRender}</List>
}

export default ThoughtsList

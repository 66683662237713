import { PropsWithoutRef } from 'react'
import { ListItemButton, ListItemText } from '@mui/material'

import ListItemTextWithNumber from 'presentation/views/components/ListItemTextWithNumber/View'

interface AnswerProps {
	label: string
	isSelected: boolean
	index: number
	select: () => void
}

export default function View({
	label,
	isSelected,
	index,
	select,
}: PropsWithoutRef<AnswerProps>) {
	return (
		<ListItemButton selected={isSelected} onClick={select}>
			<ListItemText
				primary={
					<ListItemTextWithNumber
						index={index}
						text={label}
						selected={isSelected}
					/>
				}
			/>
		</ListItemButton>
	)
}

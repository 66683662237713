import { useMemo } from 'react'

import CategoriesQuestionnaire from 'domain/Diagnostic/WellBeingSnapshot/CategoriesQuestionnaire/interfaces/data-transfer-objects/CategoriesQuestionnaireDTO'

import Modal from 'presentation/views/components/Modal/View'

import Questionnaire from './Questionnaire/View'

interface Props {
	questionnaire: CategoriesQuestionnaire | null
	questionnaireStarted: boolean
	currentQuestionIndex: number | undefined
	toggleCategory: (categoryId: string) => Promise<void>
	toggleAnswerSelection: (questionId: string) => Promise<void>
	setAnswer: (answer: string) => Promise<void>
	confirmAnswer: () => Promise<void>
	goToPreviousQuestion: () => void
	quitQuestionnaire: () => void
}

export default function View({
	questionnaire,
	questionnaireStarted,
	currentQuestionIndex,
	toggleCategory,
	toggleAnswerSelection,
	setAnswer,
	confirmAnswer,
	goToPreviousQuestion,
	quitQuestionnaire,
}: Props) {
	const content = useMemo(() => {
		if (questionnaire === null) {
			return <></>
		}

		const categoriesQuestionnaire: CategoriesQuestionnaire = questionnaire

		return (
			<Questionnaire
				questionnaire={categoriesQuestionnaire}
				currentQuestionIndex={currentQuestionIndex}
				toggleCategory={toggleCategory}
				toggleAnswerSelection={toggleAnswerSelection}
				setAnswer={setAnswer}
				confirmAnswer={confirmAnswer}
				goToPreviousQuestion={goToPreviousQuestion}
				quitQuestionnaire={quitQuestionnaire}
			/>
		)
	}, [
		questionnaire,
		confirmAnswer,
		currentQuestionIndex,
		goToPreviousQuestion,
		quitQuestionnaire,
		setAnswer,
		toggleCategory,
		toggleAnswerSelection,
	])

	return (
		<Modal
			isOpen={questionnaireStarted}
			maximized={true}
			close={quitQuestionnaire}
		>
			{content}
		</Modal>
	)
}

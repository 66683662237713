import { useCallback, useMemo, useState } from 'react'
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'

import { formatSentence } from 'presentation/utils/string-formatter'

type ThoughtFormProps = {
	addThought: (thought: string) => void
}

function NewThoughtForm({ addThought }: ThoughtFormProps) {
	/**
	 * States
	 */

	const [thought, setThought] = useState<string>('')

	/**
	 * Memo
	 */

	const isConfirmable = useMemo(() => {
		if (thought.trim().length > 0) return true
		return false
	}, [thought])

	/**
	 * Callbacks
	 */

	const confirmThought = useCallback(() => {
		if (thought.trim().length === 0) return

		addThought(formatSentence(thought))
		setThought('')
	}, [thought, addThought])

	const onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setThought(event.target.value)
		},
		[]
	)

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent) => {
			if (event.key === 'Enter') confirmThought()
		},
		[confirmThought]
	)

	/**
	 * Render
	 */

	const confirmButtonRender = useMemo(() => {
		if (isConfirmable === false) return null

		return (
			<IconButton color='primary' onClick={confirmThought} edge='end'>
				<AddIcon />
			</IconButton>
		)
	}, [isConfirmable, confirmThought])

	return (
		<OutlinedInput
			placeholder={`Une pensée...`}
			value={thought}
			onChange={onChange}
			onKeyDown={onKeyDown}
			endAdornment={
				<InputAdornment position='end'>
					{confirmButtonRender}
				</InputAdornment>
			}
			fullWidth
		/>
	)
}

export default NewThoughtForm

import { useCallback, useMemo, useState } from 'react'
import { produce } from 'immer'
import { List } from '@mui/material'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'
import CoachingStep from 'domain/Support/PerspectiveSolver/core/CoachingStep'

import ConfirmationButton from 'presentation/views/components/ConfirmationButton/ConfirmationButton'

import Option from './Option/Option'

const options = [
	`Ma problématique concerne de l’anxiété, des angoisses, une séparation de moins de 2 ans ou une phobie`,
	`J’ai déjà fait plusieurs sessions de coaching sur ce même sujet`,
	`Ma problématique a évolué pendant le processus`,
	`Ma problématique est floue ou trop large`,
	`Les pensées agréables ne m’ont pas soulagé·e`,
]

type ResultRedoFormProps = {
	situation: CoachingSituation
	updateSituation: (updatedSituation: CoachingSituation) => void
}

function ResultRedoForm({ situation, updateSituation }: ResultRedoFormProps) {
	/**
	 * States
	 */

	const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

	/**
	 * Memo
	 */

	const isConfirmable = useMemo(() => selectedIndex !== null, [selectedIndex])

	/**
	 * Callbacks
	 */

	const selectOption = useCallback(
		(option: CoachingStep) => {
			const updatedSituation = produce(situation, (situation) => {
				situation.currentStep = option
				return situation
			})

			updateSituation(updatedSituation)
		},
		[situation, updateSituation]
	)

	const confirmOption = useCallback(() => {
		if (selectedIndex === null) return

		if (selectedIndex === 0)
			selectOption(CoachingStep.ResultInvalidSituation)
		else if (selectedIndex === 1)
			selectOption(CoachingStep.ResultPersistingSymptoms)
		else if (selectedIndex === 2)
			selectOption(CoachingStep.ResultAdditionalSituation)
		else if (selectedIndex === 3)
			selectOption(CoachingStep.ResultNoNeutralSituation)
		else if (selectedIndex === 4)
			selectOption(CoachingStep.ResultInvalidPositiveThoughts)
	}, [selectedIndex, selectOption])

	/**
	 * Render
	 */

	const optionsRender = useMemo(() => {
		return options.map((option, index) => (
			<Option
				key={index}
				text={option}
				index={index}
				select={setSelectedIndex}
				isSelected={selectedIndex === index}
			/>
		))
	}, [selectedIndex])

	return (
		<div className='coaching-situation-content'>
			<h3 style={{ flex: '0 0 fit-content' }}>
				Une sensation désagréable est encore présente
			</h3>
			<p style={{ flex: '0 0 fit-content' }}>
				Dans quelle situation es-tu ?
			</p>
			<div style={{ flex: '1 0 0', overflowY: 'auto' }}>
				<List>{optionsRender}</List>
			</div>
			<div className='coaching-situation-confirmation'>
				<ConfirmationButton
					label={'Valider'}
					isShown={isConfirmable}
					confirm={confirmOption}
				/>
			</div>
		</div>
	)
}

export default ResultRedoForm

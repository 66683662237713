import Tips from 'domain/Support/PerspectiveSolver/core/tips/Tips'

const tips: Tips = {
	instruction: {
		title: '', //`Comment savoir ce que je souhaiterais ressentir ?`,
		pages: [
			{
				text: [
					`Comment souhaites-tu idéalement te sentir dans cette situation ?`,
					`Pense à comment tu souhaiterais te sentir vis-à-vis de ton objectif dans un <b>futur proche.</b>`,
					`Pour cela, imagine une personne qui vit la <b>même situation que toi</b> et qui pourtant ressent une <b>émotion agréable.</b>`,
					`Laquelle est-elle ?</b>`,
				],
				confirmLabel: `Je choisis un sentiment positif`,
			},
		],
	},
}

export default tips

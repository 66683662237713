import { SvgIcon, SvgIconProps } from '@mui/material'

export default function HomeIconActive(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<svg
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M13.1756 3.85413C12.4746 3.34485 11.5254 3.34485 10.8244 3.85413L4.61607 8.36478C3.9151 8.87407 3.62178 9.7768 3.88953 10.6008L6.26091 17.8992C6.52866 18.7233 7.29657 19.2812 8.16303 19.2812H15.837C16.7034 19.2812 17.4714 18.7233 17.7391 17.8992L20.1105 10.6008C20.3782 9.7768 20.0849 8.87407 19.3839 8.36478L13.1756 3.85413ZM12.4558 8.90849C12.2724 8.53687 11.7425 8.53687 11.5591 8.90849L10.9482 10.1462C10.8754 10.2938 10.7346 10.3961 10.5718 10.4198L9.20583 10.6182C8.79572 10.6778 8.63197 11.1818 8.92872 11.4711L9.91713 12.4346C10.035 12.5494 10.0887 12.7149 10.0609 12.8771L9.8276 14.2375C9.75754 14.646 10.1863 14.9575 10.5531 14.7646L11.7748 14.1223C11.9205 14.0457 12.0945 14.0457 12.2401 14.1223L13.4619 14.7646C13.8287 14.9575 14.2574 14.646 14.1874 14.2375L13.954 12.8771C13.9262 12.7149 13.98 12.5494 14.0978 12.4346L15.0862 11.4711C15.383 11.1818 15.2192 10.6778 14.8091 10.6182L13.4432 10.4198C13.2803 10.3961 13.1395 10.2938 13.0667 10.1462L12.4558 8.90849Z'
					className='iconfill'
				/>
			</svg>
		</SvgIcon>
	)
}

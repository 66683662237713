import { Box, Paper } from '@mui/material'
import { Link } from 'react-router-dom'

import WellcomeTitle from './WellcomeTitle/View'
import WellnessRecap from './WellnessRecap/View'

export default function View() {
	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '32px',
					padding: {
						sm: '136px 48px 48px',
						md: '136px 32px 64px 32px',
						lg: '160px 64px 96px 64px',
					},
					height: '100%',
					width: '100%',
					maxWidth: '1248px',
					margin: '0 auto',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: {
							sm: 'column',
							md: 'row',
						},
						gap: '32px',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							flex: '1 0 0',
							height: '250px',
							alignItems: 'left',
							justifyContent: 'center',
							borderRadius: '8px',
							backgroundImage:
								'url(assets/images/BGEagleDesktop.jpg)',
							backgroundSize: 'cover',
							backgroundPosition: '0% 0%',
						}}
					>
						<WellcomeTitle />
					</Box>
					<Box
						className='hover'
						sx={{
							flex: '1 0 0',
						}}
					>
						<Link to='autocoaching'>
							<Paper
								sx={{
									display: 'flex',
									flexDirection: 'column',
									borderRadius: '8px',
									padding: {
										sm: '64px',
										md: '24px',
									},
									gap: '8px',
									height: '100%',
									width: '100%',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<h3
									style={{
										marginBottom: '0px',
										textAlign: 'center',
									}}
								>
									Session d'autocoaching
								</h3>
								<p
									style={{
										fontSize: '1rem',
										textAlign: 'center',
									}}
								>
									Travaille un sujet qui te préoccupe !
								</p>
							</Paper>
						</Link>
					</Box>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: {
							sm: 'column',
							md: 'row',
						},
						gap: '32px',
						width: '100%',
						height: '288px',
					}}
				>
					<Box
						className='hover'
						sx={{
							width: '100%',
						}}
					>
						<Link to='notes'>
							<Paper
								sx={{
									display: 'flex',
									flexDirection: 'column',
									borderRadius: '8px',
									padding: {
										sm: '64px',
										md: '24px',
									},
									gap: '8px',
									height: '100%',
									width: '100%',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<h3
									style={{
										marginBottom: '0px',
										textAlign: 'center',
									}}
								>
									Gestion des problématiques
								</h3>
								<p
									style={{
										fontSize: '1rem',
										textAlign: 'center',
									}}
								>
									Ajoute, supprime et modifie tes
									problématiques à travailler
								</p>
							</Paper>
						</Link>
					</Box>
					<Box
						className='hover'
						sx={{
							width: '100%',
						}}
					>
						<WellnessRecap />
					</Box>
				</Box>
			</Box>
		</>
	)
}

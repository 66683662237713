import { Box } from '@mui/material'

import CategoriesQuestionnaire from 'domain/Diagnostic/WellBeingSnapshot/CategoriesQuestionnaire/interfaces/data-transfer-objects/CategoriesQuestionnaireDTO'

import Navigation from './Navigation/View'
import Content from './Content/View'

interface Props {
	questionnaire: CategoriesQuestionnaire
	currentQuestionIndex: number | undefined
	toggleCategory: (categoryId: string) => Promise<void>
	toggleAnswerSelection: (questionId: string) => Promise<void>
	setAnswer: (answer: string) => Promise<void>
	confirmAnswer: () => Promise<void>
	goToPreviousQuestion: () => void
	quitQuestionnaire: () => void
}

export default function View({
	questionnaire,
	currentQuestionIndex,
	toggleCategory,
	toggleAnswerSelection,
	setAnswer,
	confirmAnswer,
	goToPreviousQuestion,
	quitQuestionnaire,
}: Props) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				maxHeight: '100%',
				overflow: 'hidden',
			}}
		>
			<Box sx={{ flex: '0 0 fit-content' }}>
				<Navigation
					questionnaire={questionnaire}
					currentQuestionIndex={currentQuestionIndex}
					goToPreviousQuestion={goToPreviousQuestion}
					quitQuestionnaire={quitQuestionnaire}
				/>
			</Box>
			<Box sx={{ flex: '1 0 0' }}>
				<Content
					questionnaire={questionnaire}
					currentQuestionIndex={currentQuestionIndex}
					toggleCategory={toggleCategory}
					toggleAnswerSelection={toggleAnswerSelection}
					setAnswer={setAnswer}
					confirmAnswer={confirmAnswer}
				/>
			</Box>
		</Box>
	)
}

import { useMemo } from 'react'
import { Box, Button } from '@mui/material'

import CategoryDTO from 'domain/Diagnostic/WellBeingSnapshot/CategoriesQuestionnaire/interfaces/data-transfer-objects/CategoryDTO'

import StrategiesCategoriesMock from 'domain/Psycho/core/data-access/StrategiesCategoriesMock'

import Category from './Category'

interface Props {
	selectedCategories: Array<CategoryDTO>
	toggleCategory: (categoryId: string) => Promise<void>
	confirmAnswer: () => Promise<void>
}

export default function View({
	selectedCategories,
	toggleCategory,
	confirmAnswer,
}: Props) {
	const categoriesRender = useMemo(
		() =>
			StrategiesCategoriesMock.map((category) => {
				const selectedCategoryIndex = selectedCategories.findIndex(
					(selectedCategory) => selectedCategory.id === category.id
				)
				const selected = selectedCategoryIndex >= 0

				return (
					<Category
						key={category.id}
						category={category}
						selected={selected}
						toggle={toggleCategory}
					/>
				)
			}),
		[selectedCategories, toggleCategory]
	)

	const confirmButtonRender = useMemo(() => {
		if (selectedCategories.length === 0) return null

		return (
			<Button
				sx={{ marginTop: '8px' }}
				variant='contained'
				color='primary'
				onClick={confirmAnswer}
				fullWidth
			>
				Valider
			</Button>
		)
	}, [selectedCategories, confirmAnswer])

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				overlflow: 'hidden',
				height: '100%',
			}}
		>
			<h3>Quelle catégorie souhaites-tu travailler ?</h3>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignContent: 'flex-start',
					flex: '1 0 0',
					flexWrap: 'wrap',
					gap: '8px',
				}}
			>
				{categoriesRender}
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-end',
					flex: '0 0 fit-content',
				}}
			>
				{confirmButtonRender}
			</Box>
		</Box>
	)
}

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import {
	hasDigits,
	hasSymbol,
	hasUpperCase,
	isMinLength,
} from '../../pages/Auth/lib/password-validator'
import { Box, Typography } from '@mui/material'

export function PasswordRequirements(props: any) {
	const password = props.password
	const requirements = [
		{
			text: '8 caractères mininum',
			fullfilled: isMinLength(password, 8),
		},
		{
			text: 'Une majuscule',
			fullfilled: hasUpperCase(password),
		},
		{
			text: 'Un caractère spécial',
			fullfilled: hasSymbol(password),
		},
		{
			text: 'Un chiffre',
			fullfilled: hasDigits(password),
		},
	]

	const listItems = requirements.map((requirement, index) => (
		<Box
			key={index}
			sx={{
				width: '100%',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'left',
				color: requirement.fullfilled ? '#07966a' : 'grey',
			}}
		>
			<FiberManualRecordIcon
				sx={{
					mr: 2,
				}}
			/>
			<Typography
				sx={{
					width: '100%',
					color: requirement.fullfilled ? '#07966a' : 'grey',
				}}
			>
				{requirement.text}
			</Typography>
		</Box>
	))
	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'left',
			}}
		>
			{listItems}
		</Box>
	)
}

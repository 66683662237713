import { SHA256, AES, enc } from 'crypto-js'

// NEVER CHANGE THE PREFIX
const keyPrefix = 'baume'

function getKey() {
	const encryptedKey = sessionStorage.getItem('navigator-session')
	if (encryptedKey !== null)
		return AES.decrypt(encryptedKey, keyPrefix).toString()
	return 'guest'
}

function hashKey(key: string) {
	return SHA256(`${keyPrefix}-${key}`).toString()
}

function encryptData(key: string, data: any) {
	return AES.encrypt(data, `${keyPrefix}-${key}`).toString()
}

function decryptData(key: string, data: string) {
	var bytes = AES.decrypt(data, `${keyPrefix}-${key}`)
	return bytes.toString(enc.Utf8)
}

function getUserData(forcedStorage: string = 'none') {
	const key = getKey()
	let storage = key === 'guest' ? sessionStorage : localStorage
	if (forcedStorage === 'local') storage = localStorage
	else if (forcedStorage === 'session') storage = sessionStorage

	const encryptedKey = hashKey(key)
	const storageValue = storage.getItem(encryptedKey)
	if (storageValue === null) return {}

	try {
		const decryptedData = decryptData(key, storageValue)
		return JSON.parse(decryptedData)
	} catch (error) {
		console.error('Could not fetch data from local storage')
		return {}
	}
}

function saveUserData(data: any, forcedStorage: string = 'none') {
	const key = getKey()
	let storage = key === 'guest' ? sessionStorage : localStorage
	if (forcedStorage === 'local') storage = localStorage
	else if (forcedStorage === 'session') storage = sessionStorage

	const stringifiedData = JSON.stringify(data)

	const encryptedKey = hashKey(key)
	const encryptedData = encryptData(key, stringifiedData)

	storage.setItem(encryptedKey, encryptedData)
}

export function getCoachings() {
	const userData = getUserData()
	if (userData.coachings) return userData.coachings
	else return null
}

export function setCoachings(coachings: any) {
	const userData = getUserData()
	userData.coachings = coachings
	saveUserData(userData)
}

export function getProblematics() {
	const userData = getUserData()
	if (userData.problematics) return userData.problematics
	else return null
}

export function setProblematics(problematics: any) {
	const userData = getUserData()
	userData.problematics = problematics
	saveUserData(userData)
}

export function getWellBeingSnapshotQuestionnaire() {
	const userData = getUserData()
	if (userData.wellBeingSnapshotQuestionnaire)
		return userData.wellBeingSnapshotQuestionnaire
	else return null
}

export function setWellBeingSnapshotQuestionnaire(questionnaire: any) {
	const userData = getUserData()
	userData.wellBeingSnapshotQuestionnaire = questionnaire
	saveUserData(userData)
}

export function removeWellBeingSnapshotQuestionnaire() {
	const userData = getUserData()
	delete userData.wellBeingSnapshotQuestionnaire
	saveUserData(userData)
}

export function getWellBeingSnapshotCategoriesQuestionnaire() {
	const userData = getUserData()
	if (userData.wellBeingSnapshotCategoriesQuestionnaire)
		return userData.wellBeingSnapshotCategoriesQuestionnaire
	else return null
}

export function setWellBeingSnapshotCategoriesQuestionnaire(
	questionnaire: any
) {
	const userData = getUserData()
	userData.wellBeingSnapshotCategoriesQuestionnaire = questionnaire
	saveUserData(userData)
}

export function removeWellBeingSnapshotCategoriesQuestionnaire() {
	const userData = getUserData()
	delete userData.wellBeingSnapshotCategoriesQuestionnaire
	saveUserData(userData)
}

export function getWellBeingSnapshots() {
	const userData = getUserData()
	if (userData.wellBeingSnapshots) return userData.wellBeingSnapshots
	else return null
}

export function setWellBeingSnapshots(wellBeingSnapshots: any) {
	const userData = getUserData()
	userData.wellBeingSnapshots = wellBeingSnapshots
	saveUserData(userData)
}

export function removeWellBeingSnapshots() {
	const userData = getUserData()
	delete userData.wellBeingSnapshots
	saveUserData(userData)
}

export function getFlagValue(
	flag: string,
	user: any | null,
	forcedStorage: string = 'none'
) {
	const userData = getUserData(forcedStorage)
	if (userData?.flags && userData?.flags[flag] === '1') return true
	return false
}

export function setFlagValue(
	flag: string,
	value: boolean = true,
	user: any | null,
	forcedStorage: string = 'none'
) {
	const userData = getUserData(forcedStorage)
	if (!userData.flags) userData.flags = {}

	userData.flags[flag] = value === true ? '1' : '0'

	saveUserData(userData, forcedStorage)
}

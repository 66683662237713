import { useCallback, useMemo, useEffect } from 'react'
import { Box, Button, List } from '@mui/material'

import QuestionDTO from 'domain/Diagnostic/WellBeingSnapshot/Questionnaire/interfaces/data-transfer-objects/QuestionDTO'

import Answer from './Answer/View'

interface Props {
	question: QuestionDTO
	selectAnswer: (answerIndex: number) => Promise<void>
	confirmAnswer: () => Promise<void>
}

export default function View({ question, selectAnswer, confirmAnswer }: Props) {
	/**
	 * Callbacks
	 */

	const onSelectAnswer = useCallback(
		(answerIndex: number) => async () => {
			selectAnswer(answerIndex)
		},
		[selectAnswer]
	)

	const onKeyPress = useCallback(
		(event: KeyboardEvent) => {
			const number = parseInt(event.key)

			if (event.key === 'Enter') {
				confirmAnswer()
			} else if (Number.isNaN(number) === false) {
				selectAnswer(question.answers.length - number)
			}
		},
		[question.answers.length, selectAnswer, confirmAnswer]
	)

	/**
	 * Effects
	 */

	useEffect(() => {
		window.addEventListener('keypress', onKeyPress)
		return () => {
			window.removeEventListener('keypress', onKeyPress)
		}
	}, [onKeyPress])

	/**
	 * Render
	 */

	const answersRender = useMemo(() => {
		const answersRender = []

		const lastItemIndex = question.answers.length - 1
		for (let i = lastItemIndex; i >= 0; i--) {
			const answer = question.answers[i]

			answersRender.push(
				<Answer
					key={i}
					label={answer}
					isSelected={i === question.selectedAnswer}
					index={lastItemIndex - i}
					select={onSelectAnswer(i)}
				/>
			)
		}

		return answersRender
	}, [question, onSelectAnswer])

	const confirmButtonRender = useMemo(() => {
		if (question.selectedAnswer === undefined) return null

		return (
			<Button
				sx={{ marginTop: '8px' }}
				variant='contained'
				color='primary'
				onClick={confirmAnswer}
				fullWidth
			>
				Valider
			</Button>
		)
	}, [question, confirmAnswer])

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				overlflow: 'hidden',
				height: '100%',
			}}
		>
			<Box sx={{ flex: '0 0 fit-content', padding: '0 8px' }}>
				<h3>{question.wording}</h3>
			</Box>

			<Box sx={{ flex: '1 0 0' }}>
				<List>{answersRender}</List>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flex: '0 0 fit-content',
					justifyContent: 'flex-end',
				}}
			>
				{confirmButtonRender}
			</Box>
		</Box>
	)
}

import { useMemo } from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'

import useAuth from 'presentation/hooks/useAuth'

export default function WellcomeTitle() {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const { authUser } = useAuth()

	const displayName = useMemo(() => {
		if (authUser === null || authUser.email === '') return ''

		const atIndex = authUser.email.indexOf('@')
		const pointIndex = authUser.email.indexOf('.')

		const cutIndex = atIndex > pointIndex ? pointIndex : atIndex

		if (cutIndex < 0) return ''

		const slicedEmail = authUser.email.slice(0, cutIndex)
		const displayName = slicedEmail[0].toUpperCase() + slicedEmail.slice(1)

		return displayName
	}, [authUser])

	return (
		<Box
			sx={{
				padding: {
					xs: '0px',
					sm: '96px 48px',
					md: '48px',
				},
			}}
		>
			<h2
				style={{
					margin: '0 0 2px 0',
					fontSize: isMobile ? '1rem' : '1.25rem',
					fontWeight: '400',
					fontStyle: 'normal',
					lineHeight: '100%',
					color: 'rgba(18,18,18,0.90)',
				}}
			>
				BONJOUR
			</h2>
			<h1
				style={{
					margin: '0px',
					fontSize: isMobile ? '2.25rem' : '3rem',
					fontWeight: '400',
					fontStyle: 'normal',
					lineHeight: '100%',
					color: 'rgba(18,18,18,0.90)',
				}}
			>
				{displayName}
			</h1>
		</Box>
	)
}

import { ReactElement, useMemo } from 'react'
import { Box, Button } from '@mui/material'

import SnapshotDTO from 'domain/Diagnostic/WellBeingSnapshot/Snapshot/interfaces/SnapshotDTO'
import QuestionnaireDTO from 'domain/Diagnostic/WellBeingSnapshot/Questionnaire/interfaces/data-transfer-objects/QuestionnaireDTO'

import CategoriesQuestionnaire from 'domain/Diagnostic/WellBeingSnapshot/CategoriesQuestionnaire/interfaces/data-transfer-objects/CategoriesQuestionnaireDTO'

import Header from './Header/View'
import Snapshot from './Snapshot/View'

type Props = {
	loading: boolean
	mostRecentSnapshot: SnapshotDTO | null
	snapshotQuestionnaire: QuestionnaireDTO | null
	showQuestionnaireStartInstructions: () => void
	categoriesQuestionnaire: CategoriesQuestionnaire | null
	startCategoriesQuestionnaire: () => Promise<void>
	modals: ReactElement
}

export default function View({
	loading,
	mostRecentSnapshot,
	snapshotQuestionnaire,
	showQuestionnaireStartInstructions,
	categoriesQuestionnaire,
	startCategoriesQuestionnaire,
	modals,
}: Props) {
	const contentRender = useMemo(
		() =>
			loading ? (
				'Chargement ...'
			) : (
				<Snapshot snapshot={mostRecentSnapshot} />
			),
		[loading, mostRecentSnapshot]
	)

	const startCategoriesQuestionnaireButtonLabel = useMemo(() => {
		if (categoriesQuestionnaire === null) return `Identifier mes objectifs`
		else return `Identifier mes objectifs (suite)`
	}, [categoriesQuestionnaire])

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					width: '100%',
				}}
			>
				<Box
					sx={{
						flex: '0 0 auto',
						width: '100%',
					}}
				>
					<Header
						snapshot={mostRecentSnapshot}
						questionnaire={snapshotQuestionnaire}
						showQuestionnaireStartInstructions={
							showQuestionnaireStartInstructions
						}
					/>
				</Box>

				<Box
					sx={{
						flex: '1 0 0',
						padding: '8px 8px 60px 16px',
						width: '100%',
						height: '100%',
						overflow: 'scroll',
					}}
				>
					{contentRender}
				</Box>
				<Box
					sx={{
						flex: '0 0 auto',
						padding: '32px 16px 16px',
						width: '100%',
						position: 'absolute',
						bottom: '76px',
						background:
							'linear-gradient(180deg, rgba(252, 243, 239, 0.00) 0%, #FCF3EF 52%, #FCF3EF 100%)',
					}}
				>
					<Button
						color='primary'
						variant='contained'
						size='small'
						onClick={startCategoriesQuestionnaire}
					>
						{startCategoriesQuestionnaireButtonLabel}
					</Button>
				</Box>
			</Box>
			{modals}
		</>
	)
}

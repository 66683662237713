import { useMemo } from 'react'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'
import CoachingStep from 'domain/Support/PerspectiveSolver/core/CoachingStep'

import SituationTypeForm from './SituationTypeForm/SituationTypeForm'
import FreeDescriptionForm from './FreeDescriptionForm/FreeDescriptionForm'
import SituationForm from './SituationForm/SituationForm'

type NeutralObservationProps = {
	situation: CoachingSituation
	situations: Array<CoachingSituation>
	updateSituation: (updatedSituation: CoachingSituation) => void
}

function NeutralObservation({
	situation,
	situations,
	updateSituation,
}: NeutralObservationProps) {
	/**
	 * Render
	 */

	const stepForm = useMemo(() => {
		if (situation.currentStep === CoachingStep.NeutralAnalysis) {
			return (
				<SituationTypeForm
					situation={situation}
					situations={situations}
					updateSituation={updateSituation}
				/>
			)
		} else if (
			situation.currentStep === CoachingStep.NeutralFreeDescription
		) {
			return (
				<FreeDescriptionForm
					situation={situation}
					situations={situations}
					updateSituation={updateSituation}
				/>
			)
		} else if (situation.currentStep === CoachingStep.NeutralSituation) {
			return (
				<SituationForm
					situation={situation}
					situations={situations}
					updateSituation={updateSituation}
				/>
			)
		} else return null
	}, [situation, situations, updateSituation])

	return stepForm
}

export default NeutralObservation

import SnapshotInteractor from '../interfaces/SnapshotInteractor'
import SnapshotRepository from '../interfaces/SnapshotRepository'
import SnapshotDTO from '../interfaces/SnapshotDTO'

export default class SnapshotInteractorImpl implements SnapshotInteractor {
	constructor(private snapshotRepository: SnapshotRepository) {}

	save = async (snapshotDTO: SnapshotDTO) => {
		await this.snapshotRepository.saveSnapshot(snapshotDTO)
	}

	getMostRecent = async () => {
		return await this.snapshotRepository.getMostRecent()
	}

	getFromDateRange = async (startDate: Date, endDate: Date) => {
		// TODO: implement this function when needed
	}
}

import AuthInteractor from '../interfaces/AuthInteractor'
import AuthRepository from '../interfaces/AuthRepository'

export default class AuthInteractorImpl implements AuthInteractor {
	constructor(private authRepository: AuthRepository) {}

	login = async (email: string, password: string) => {
		return await this.authRepository.login(email, password)
	}

	logout = async () => {
		await this.authRepository.logout()
	}

	getUser = async () => {
		return await this.authRepository.getUser()
	}

	updatePassword = async (password: string) => {
		await this.authRepository.updatePassword(password)
	}

	resetPassword = async (email: string) => {
		await this.authRepository.resetPassword(email)
	}
}

import { useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import { Box, useMediaQuery, useTheme } from '@mui/material'

import BottomNavigation from 'presentation/views/components/BottomNavigation/BottomNavigation'

export default function View() {
	const theme = useTheme()
	const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

	const navigationRender = useMemo(() => <BottomNavigation />, [])

	const topSectionRender = useMemo(() => {
		if (smallScreen === false) return navigationRender

		return null
	}, [smallScreen, navigationRender])

	const bottomSectionRender = useMemo(() => {
		if (smallScreen === true) return navigationRender

		return null
	}, [smallScreen, navigationRender])

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				paddingBottom: '76px',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flex: '0 0 auto',
					width: '100%',
				}}
			>
				{topSectionRender}
			</Box>
			<Box
				sx={{
					flex: '1 0 0',
					width: '100%',
				}}
			>
				<Outlet />
			</Box>
			<Box
				sx={{
					position: 'absolute',
					bottom: '0',
					flex: '0 0 auto',
					width: '100%',
					zIndex: '999',
				}}
			>
				{bottomSectionRender}
			</Box>
		</Box>
	)
}

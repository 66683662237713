import { useMemo } from 'react'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'
import CoachingStep from 'domain/Support/PerspectiveSolver/core/CoachingStep'

import ResultFeelingForm from './ResultFeelingForm/ResultFeelingForm'
import ResultSuccess from './ResultSuccess/ResultSuccess'
import ResultRedoForm from './ResultRedoForm/ResultRedoForm'
import ResultNoNeutralSituationForm from './ResultNoNeutralSituationForm/ResultNoNeutralSituationForm'
import ResultInvalidPositiveThoughtsForm from './ResultInvalidPositiveThoughtsForm/ResultInvalidPositiveThoughtsForm'
import ResultAdditionalSituationForm from './ResultAdditionalSituationForm/ResultAdditionalSituationForm'
import ResultInvalidSituationForm from './ResultInvalidSituationForm/ResultInvalidSituationForm'
import ResultPersistingSymptomsForm from './ResultPersistingSymptomsForm/ResultPersistingSymptomsForm'

type ResultProps = {
	situation: CoachingSituation
	situations: Array<CoachingSituation>
	updateSituation: (updatedSituation: CoachingSituation) => void
	startNewSituation: () => void
	quitCoaching: () => void
}

function Result({
	situation,
	situations,
	updateSituation,
	startNewSituation,
	quitCoaching,
}: ResultProps) {
	/**
	 * Render
	 */

	const stepForm = useMemo(() => {
		if (situation.currentStep === CoachingStep.ResultFeeling) {
			return (
				<ResultFeelingForm
					situation={situation}
					updateSituation={updateSituation}
				/>
			)
		} else if (situation.currentStep === CoachingStep.ResultSuccess) {
			return (
				<ResultSuccess
					situation={situation}
					updateSituation={updateSituation}
					quitCoaching={quitCoaching}
				/>
			)
		} else if (situation.currentStep === CoachingStep.ResultRedo) {
			return (
				<ResultRedoForm
					situation={situation}
					updateSituation={updateSituation}
				/>
			)
		} else if (
			situation.currentStep === CoachingStep.ResultInvalidSituation
		) {
			return (
				<ResultInvalidSituationForm
					situation={situation}
					updateSituation={updateSituation}
					quitCoaching={quitCoaching}
				/>
			)
		} else if (
			situation.currentStep === CoachingStep.ResultPersistingSymptoms
		) {
			return (
				<ResultPersistingSymptomsForm
					situation={situation}
					updateSituation={updateSituation}
					quitCoaching={quitCoaching}
				/>
			)
		} else if (
			situation.currentStep === CoachingStep.ResultAdditionalSituation
		) {
			return (
				<ResultAdditionalSituationForm
					situation={situation}
					updateSituation={updateSituation}
					startNewSituation={startNewSituation}
					quitCoaching={quitCoaching}
				/>
			)
		} else if (
			situation.currentStep === CoachingStep.ResultNoNeutralSituation
		) {
			return (
				<ResultNoNeutralSituationForm
					situation={situation}
					updateSituation={updateSituation}
					startNewSituation={startNewSituation}
					quitCoaching={quitCoaching}
				/>
			)
		} else if (
			situation.currentStep === CoachingStep.ResultInvalidPositiveThoughts
		) {
			return (
				<ResultInvalidPositiveThoughtsForm
					situation={situation}
					updateSituation={updateSituation}
					quitCoaching={quitCoaching}
				/>
			)
		} else return null
	}, [situation, updateSituation, startNewSituation, quitCoaching])

	return stepForm
}

export default Result

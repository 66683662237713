import { ReactElement, useState } from 'react'
import {
	Box,
	Button,
	LinearProgress,
	SvgIconProps,
	useTheme,
	useMediaQuery,
} from '@mui/material'

import Modal from 'presentation/views/components/Modal/View'

const getCategoryColor = (category: string): string => {
	const colors: { [key: string]: string } = {
		Amour: '#C54F4F',
		Amitié: '#E17F48',
		Santé: '#5FA69B',
		Sport: '#48A0BC',
		Famille: '#C95478',
		Argent: '#4E8BC5',
		Travail: '#785FDB',
		Émotions: '#A05DCA',
	}

	return colors[category] || '#292827'
}

type Props = {
	label: string
	score: number | null
	icon: ReactElement<SvgIconProps>
}

export default function View({ label, score, icon }: Props) {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const [, /*categoryOpened*/ setCategoryOpened] = useState<boolean>(false)

	return (
		<>
			<Box
				sx={{
					width: '100%',
					height: isMobile ? '44px' : '56px',
					display: 'flex',
					flexDirection: 'row',
					gap: 'auto',
					backgroundColor: theme.palette.background.paper,
					alignItems: 'center',
					borderRadius: '4px',
					cursor: 'pointer',
				}}
				onClick={() => {
					setCategoryOpened(true)
				}}
			>
				<Box
					sx={{
						position: 'relative',
						display: 'flex',
						flexDirection: 'row',
						width: '112px',
						minWidth: '112px',
						height: '100%',
						gap: '8px',
						paddingLeft: '12px',
						borderRadius: '4px 0px 0px 4px',
						backgroundColor: getCategoryColor(label),
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flex: '0 0 fit-content',
							height: '100%',
						}}
					>
						{icon}
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignSelf: 'center',
							fontSize: isMobile ? '0.875rem' : '1rem',
							color: theme.palette.primary.contrastText,
						}}
					>
						{label}
					</Box>
				</Box>
				<Box
					sx={{
						position: 'relative',
						width: '100%',
						height: '100%',
					}}
				>
					<LinearProgress
						sx={{
							position: 'relative',
							height: '100%',
							backgroundColor: theme.palette.background.paper,
							borderRadius: '0px 4px 4px 0px !important',
							'& .MuiLinearProgress-bar': {
								backgroundColor: getCategoryColor(label),
							},
						}}
						variant='determinate'
						value={typeof score === 'number' ? score : 0}
					/>
					<Box
						sx={{
							position: 'absolute',
							right: '6px',
							top: isMobile ? '9px' : '14px',
							color: getCategoryColor(label),
							fontSize: isMobile ? '0.875rem' : '1rem',
							lineHeight: '100%',
							padding: '6px 10px',
							width: 'max-content',
							borderRadius: '56px',
							backgroundColor: theme.palette.background.paper,
						}}
					>
						{typeof score === 'number' ? `${score}%` : '?'}
					</Box>
				</Box>
			</Box>

			<Modal isOpen={/*categoryOpened*/ false} maximized={isMobile}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
					}}
				>
					<Box sx={{ flex: '0 0 fit-content' }}>
						<h3>En cours de construction</h3>
					</Box>
					<Box
						sx={{
							flex: '1 0 0',
							overflow: { xs: 'hidden', sm: 'visible' },
						}}
					>
						<p>
							En sélectionnant une catégorie tu pourras bientôt
							avoir un accompagnement dédié pour celle-ci.
						</p>
						<p>Cela te permettra de progresser rapidement !</p>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flex: '0 0 fit-content',
							width: '100%',
							height: 'auto',
							marginTop: '16px',
						}}
					>
						<Button
							variant='contained'
							color='primary'
							onClick={() => {
								setCategoryOpened(false)
							}}
							sx={{
								flex: '1 0 0',
								height: 'fit-content',
							}}
						>
							Compris
						</Button>
					</Box>
				</Box>
			</Modal>
		</>
	)
}

import { useCallback, useMemo, useState } from 'react'
import { produce } from 'immer'
import { List } from '@mui/material'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'
import CoachingStep from 'domain/Support/PerspectiveSolver/core/CoachingStep'

import ConfirmationButton from 'presentation/views/components/ConfirmationButton/ConfirmationButton'

import Option from './Option/Option'

const options = [`Mon sujet est clair et précis`, `Mon sujet est encore flou`]

type SituationFormProps = {
	situation: CoachingSituation
	situations: Array<CoachingSituation>
	updateSituation: (updatedSituation: CoachingSituation) => void
}

function SituationForm({
	situation,
	situations,
	updateSituation,
}: SituationFormProps) {
	/**
	 * States
	 */

	const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

	/**
	 * Memo
	 */

	const isConfirmable = useMemo(() => selectedIndex !== null, [selectedIndex])

	/**
	 * Callbacks
	 */

	const confirmOption = useCallback(() => {
		if (selectedIndex === null) return

		const updatedSituation = produce(situation, (situation) => {
			if (selectedIndex === 0) {
				situation.hasFreeDescription = false
				situation.currentStep = CoachingStep.NeutralSituation
			} else {
				situation.hasFreeDescription = true
				situation.currentStep = CoachingStep.NeutralFreeDescription
			}
			return situation
		})

		updateSituation(updatedSituation)
	}, [selectedIndex, situation, updateSituation])

	/**
	 * Render
	 */

	const optionsRender = useMemo(() => {
		return options.map((option, index) => (
			<Option
				key={index}
				thought={option}
				index={index}
				select={setSelectedIndex}
				isSelected={selectedIndex === index}
			/>
		))
	}, [selectedIndex])

	return (
		<>
			<div className='coaching-situation-confirmation'>
				<h3>Dans quel cas te trouves-tu ?</h3>
			</div>
			<div
				style={{
					flex: '1 0 0',
					maxHeight: '100%',
					overflow: 'auto',
				}}
			>
				<List>{optionsRender}</List>
			</div>
			<div className='coaching-situation-confirmation'>
				<ConfirmationButton
					label={'Valider'}
					isShown={isConfirmable}
					confirm={confirmOption}
				/>
			</div>
		</>
	)
}

export default SituationForm

import StrategyCategory from 'domain/Psycho/core/StrategyCategory'

const raw = [
	{
		id: '1',
		label: 'Santé',
	},
	{
		id: '2',
		label: 'Argent',
	},
	{
		id: '3',
		label: 'Amour',
	},
	{
		id: '4',
		label: 'Famille',
	},
	{
		id: '5',
		label: 'Amitié',
	},
	{
		id: '6',
		label: 'Travail',
	},
	{
		id: '7',
		label: 'Sport',
	},
	{
		id: '8',
		label: 'Émotions',
	},
]

const mock: Array<StrategyCategory> = []

for (let i = 0; i < raw.length; i++) {
	const rawItem = raw[i]
	const item: StrategyCategory = {
		id: rawItem.id,
		label: rawItem.label,
	}

	mock.push(item)
}

export default mock

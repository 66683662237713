import { Box } from '@mui/material'

export default function View() {
	return (
		<Box sx={{ height: '100%', maxHeight: '100%', overflow: 'auto' }}>
			<p>
				Ton niveau de bien-être général indique que tu as besoin de
				mettre en priorité le développement de ton épanouissement. Nous
				te rassurons, tu as la possibilité d’améliorer grandement ton
				niveau de bien-être.
			</p>
			<br />
			<p>
				Pour cela, tu peux commencer par développer ta capacité à
				prendre conscience de tes schémas inconscients (pensées,
				émotions, croyances…). En allant répondre aux différentes
				questions des catégories, tu peux découvrir des pensées ou
				croyances désagréables. Celles-ci sont en grande partie à
				l’origine de ton niveau de bien-être actuel.
			</p>
			<br />
			<p>
				Chacune des pensées désagréables feront l’objet d’une session
				d’auto-coaching. Avec une session quotidienne d’environ 10
				minutes, tu peux grandement augmenter ton niveau de bien-être.
			</p>
			<br />
			<p>
				Nous te conseillons également d’être accompagné par un
				thérapeute ou un coach en parallèle de l’utilisation de
				l’application.
			</p>
		</Box>
	)
}

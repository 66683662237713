import { PropsWithChildren } from 'react'
import { Modal, Paper, useTheme, useMediaQuery } from '@mui/material'

interface ModalProps {
	isOpen: boolean
	maximized?: boolean
	close?: () => void
}

export default function View({
	isOpen,
	maximized = false,
	close,
	children,
}: PropsWithChildren<ModalProps>) {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	return (
		<Modal open={isOpen} onClose={close}>
			<Paper
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					height: maximized ? '90vh' : 'fit-content',
					maxHeight: '90vh',
					width: '90vw',
					maxWidth: '768px',
					transform: 'translate(-50%,-50%)',
					display: 'flex',
					flexDirection: 'column',
					padding: isMobile ? '16px' : '24px',
				}}
			>
				{children}
			</Paper>
		</Modal>
	)
}

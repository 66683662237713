import { useMemo } from 'react'

import { Box, List } from '@mui/material'

import ProblematicDTO from 'domain/Diagnostic/Problematic/interfaces/ProblematicDTO'

import Problematic from './Problematic/View'

type Props = {
	title: string
	problematics: Array<ProblematicDTO>
	selectProblematic: (problematic: ProblematicDTO) => void
}

export default function View({
	title,
	problematics,
	selectProblematic,
}: Props) {
	const problematicsRender = useMemo(
		() =>
			problematics.map((problematic) => (
				<Problematic
					key={problematic.id}
					problematic={problematic}
					select={selectProblematic}
				/>
			)),
		[problematics, selectProblematic]
	)

	return (
		<Box
			sx={{
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				height: 'auto',
				width: '100%',
			}}
		>
			<h3>{title}</h3>
			<List dense>{problematicsRender}</List>
		</Box>
	)
}

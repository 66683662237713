import { useCallback, useMemo, useState } from 'react'
import { produce } from 'immer'
import {
	IconButton,
	InputAdornment,
	OutlinedInput,
	useTheme,
	useMediaQuery,
} from '@mui/material'

import SendIcon from '@mui/icons-material/Send'

import { formatSentence } from 'presentation/utils/string-formatter'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'
import CoachingStep from 'domain/Support/PerspectiveSolver/core/CoachingStep'

import Slider from 'presentation/views/components/Slider/Slider'
import Tips from 'presentation/views/components/Tips/Tips'
import ConfirmationButton from 'presentation/views/components/ConfirmationButton/ConfirmationButton'
import useAuth from 'presentation/hooks/useAuth'

import TipsMock from 'domain/Support/PerspectiveSolver/core/data-access/tips/SituationFormTips'
import ExamplesMock from './ExamplesMock'

import { getFlagValue, setFlagValue } from 'common/storage-interactor'

type SituationFormProps = {
	situation: CoachingSituation
	situations: Array<CoachingSituation>
	updateSituation: (updatedSituation: CoachingSituation) => void
}

function SituationForm({
	situation,
	situations,
	updateSituation,
}: SituationFormProps) {
	const theme = useTheme()
	const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const { authUser } = useAuth()

	/**
	 * States
	 */

	const [description, setDescription] = useState<string>(
		situation.description
	)

	/**
	 * Memo
	 */

	const isConfirmable = useMemo(() => {
		if (description.trim().length > 0) return true
		return false
	}, [description])

	const showTipsOnMount: boolean = useMemo(() => {
		if (getFlagValue('situation-tips-seen', authUser) === false) return true
		return false
	}, [authUser])

	/**
	 * Callbacks
	 */

	const confirmDescription = useCallback(() => {
		if (description.trim().length === 0) return

		const updatedSituation = produce(situation, (situation) => {
			situation.description = formatSentence(description)
			situation.currentStep = CoachingStep.NegativeFeeling
			return situation
		})

		updateSituation(updatedSituation)
	}, [description, situation, updateSituation])

	const onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setDescription(event.target.value)
		},
		[]
	)

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent) => {
			if (event.key === 'Enter') confirmDescription()
		},
		[confirmDescription]
	)

	/**
	 * Render
	 */

	const confirmButtonRender = useMemo(() => {
		if (isConfirmable === false) return null

		return (
			<IconButton color='primary' onClick={confirmDescription} edge='end'>
				<SendIcon />
			</IconButton>
		)
	}, [isConfirmable, confirmDescription])

	return (
		<>
			<div className='coaching-situation-content'>
				<h3>Synthétise ta problématique</h3>
				<p style={{ marginBottom: '16px' }}>
					Décris ta situation de façon <b>courte</b>, <b>neutre</b> et{' '}
					<b>factuelle</b>.
				</p>
				<OutlinedInput
					placeholder={`Ma situation...`}
					value={description}
					onChange={onChange}
					onKeyDown={onKeyDown}
					endAdornment={
						<InputAdornment position='end'>
							{confirmButtonRender}
						</InputAdornment>
					}
					style={{ padding: '12px' }}
					multiline
					maxRows={2}
					fullWidth
				/>
				<div style={{ height: '8px' }} />
				<Slider title='Exemples' content={ExamplesMock} />
			</div>

			{smallScreen && (
				<div className='coaching-situation-tips'>
					<Tips
						instruction='Comment décrire ma situation de manière neutre ?'
						tips={TipsMock}
						openOnMount={showTipsOnMount}
						onClose={() => {
							setFlagValue('situation-tips-seen', true, authUser)
						}}
					/>
				</div>
			)}

			<div
				style={{ marginTop: '16px' }}
				className='coaching-situation-confirmation'
			>
				<ConfirmationButton
					label={'Valider'}
					isShown={isConfirmable}
					confirm={confirmDescription}
				/>
			</div>
		</>
	)
}

export default SituationForm

import { SvgIcon, SvgIconProps } from '@mui/material'

export default function HomeIconInactive(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<svg
				width='24px'
				height='24px'
				viewBox='0 0 24 24'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M19.4998 11.9096V18.0021C19.4998 18.8305 18.8282 19.5021 17.9998 19.5021H14.5V17.0026C14.5 16.1742 13.8284 15.5026 13 15.5026H11C10.1716 15.5026 9.5 16.1742 9.5 17.0026V19.5021H6C5.17157 19.5021 4.5 18.8305 4.5 18.0021V11.9097C4.5 11.4771 4.68672 11.0656 5.01224 10.7808L11.0122 5.5308C11.5778 5.03595 12.4222 5.03596 12.9878 5.53082L18.9876 10.7808C19.3131 11.0656 19.4998 11.4771 19.4998 11.9096Z'
					className='iconstrokefill'
				/>
			</svg>
		</SvgIcon>
	)
}

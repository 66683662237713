import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import Diversity3Icon from '@mui/icons-material/Diversity3'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import WorkIcon from '@mui/icons-material/Work'

const style = {
	color: '#FFFFFF',
	alignSelf: 'center',
	alignContent: 'center',
	height: '100%',
}

const shownCategories = [
	{
		label: 'Émotions',
		icon: <EmojiEmotionsIcon style={style} />,
		score: null,
	},
	{
		label: 'Famille',
		icon: <FamilyRestroomIcon style={style} />,
		score: null,
	},
	{
		label: 'Amour',
		icon: <FavoriteIcon style={style} />,
		score: null,
	},
	{
		label: 'Sport',
		icon: <FitnessCenterIcon style={style} />,
		score: null,
	},
	{
		label: 'Amitié',
		icon: <Diversity3Icon style={style} />,
		score: null,
	},
	{
		label: 'Argent',
		icon: <AccountBalanceIcon style={style} />,
		score: null,
	},
	{
		label: 'Santé',
		icon: <LocalHospitalIcon style={style} />,
		score: null,
	},
	{
		label: 'Travail',
		icon: <WorkIcon style={style} />,
		score: null,
	},
]

export default shownCategories

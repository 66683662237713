import ProblematicInteractor from 'domain/Diagnostic/Problematic/interfaces/ProblematicInteractor'

import CategoriesQuestionnaireInteractor from '../interfaces/CategoriesQuestionnaireInteractor'
import CategoriesQuestionnaireRepository from '../interfaces/CategoriesQuestionnaireRepository'

import CategoriesQuestionnaireEntity from './CategoriesQuestionnaireEntity'
import QuestionnaireMapper from './QuestionnaireMapper'

export default class CategoriesQuestionnaireInteractorImpl
	implements CategoriesQuestionnaireInteractor
{
	constructor(
		private questionnaireRepository: CategoriesQuestionnaireRepository,
		private problematicInteractor: ProblematicInteractor
	) {}

	getCurrent = async () => {
		return await this.questionnaireRepository.getCurrent()
	}

	create = async () => {
		let questionnaireDTO = await this.questionnaireRepository.getCurrent()

		if (questionnaireDTO === null) {
			questionnaireDTO = await this.questionnaireRepository.create()
		}

		return questionnaireDTO
	}

	toggleCategory = async (categoryId: string) => {
		let questionnaireDTO = await this.questionnaireRepository.getCurrent()

		if (questionnaireDTO === null) {
			throw Error(
				'Tried to toggle category but questionnaire does not exist'
			)
		}

		const questionnaireEntity = new CategoriesQuestionnaireEntity(
			questionnaireDTO
		)

		await questionnaireEntity.toggleCategory(categoryId)

		questionnaireDTO = QuestionnaireMapper.toDTO(questionnaireEntity)
		await this.questionnaireRepository.save(questionnaireDTO)

		return questionnaireDTO
	}

	setAnswer = async (questionIndex: number, answer: string) => {
		let questionnaireDTO = await this.questionnaireRepository.getCurrent()

		if (questionnaireDTO === null)
			throw Error('Tried to answer to questionnaire but none exists')

		const questionnaire = new CategoriesQuestionnaireEntity(
			questionnaireDTO
		)
		await questionnaire.answerQuestion(questionIndex, answer)

		questionnaireDTO = QuestionnaireMapper.toDTO(questionnaire)
		await this.questionnaireRepository.save(questionnaireDTO)

		return questionnaireDTO
	}

	toggleAnswerSelection = async (questionId: string) => {
		let questionnaireDTO = await this.questionnaireRepository.getCurrent()

		if (questionnaireDTO === null)
			throw Error(
				'Tried to toggle category but questionnaire does not exist'
			)

		const questionnaire = new CategoriesQuestionnaireEntity(
			questionnaireDTO
		)
		await questionnaire.toggleAnswerSelection(questionId)

		questionnaireDTO = QuestionnaireMapper.toDTO(questionnaire)
		await this.questionnaireRepository.save(questionnaireDTO)

		return questionnaireDTO
	}

	confirmQuestionnaire = async () => {
		const questionnaireDTO = await this.questionnaireRepository.getCurrent()

		if (questionnaireDTO === null)
			throw Error('Tried to confirm questionnaire but there is none')

		const questionnaire = new CategoriesQuestionnaireEntity(
			questionnaireDTO
		)

		const answers = await questionnaire.confirm()
		for (let i = 0; i < answers.length; i++) {
			const answer = answers[i]
			await this.problematicInteractor.addProblematic(answer.answer, [
				answer.categoryId,
			])
		}

		await this.questionnaireRepository.clear()
	}
}

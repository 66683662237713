import { useMemo } from 'react'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'
import CoachingStep from 'domain/Support/PerspectiveSolver/core/CoachingStep'

import PositiveFeelingForm from './PositiveFeelingForm/PositiveFeelingForm'
import PositiveActionsForm from './PositiveActionsForm/PositiveActionsForm'
import PositiveThoughtsForm from './PositiveThoughtsForm/PositiveThoughtsForm'
import PositiveThoughtChoiceForm from './PositiveThoughtChoiceForm/PositiveThoughtChoiceForm'
import PositiveRecap from './PositiveRecap/PositiveRecap'

type PositiveTransformationProps = {
	situation: CoachingSituation
	situations: Array<CoachingSituation>
	updateSituation: (updatedSituation: CoachingSituation) => void
}

function PositiveTransformation({
	situation,
	situations,
	updateSituation,
}: PositiveTransformationProps) {
	/**
	 * Render
	 */

	const stepForm = useMemo(() => {
		if (situation.currentStep === CoachingStep.PositiveFeeling) {
			return (
				<PositiveFeelingForm
					situation={situation}
					updateSituation={updateSituation}
				/>
			)
		} else if (situation.currentStep === CoachingStep.PositiveActions) {
			return (
				<PositiveActionsForm
					situation={situation}
					updateSituation={updateSituation}
				/>
			)
		} else if (situation.currentStep === CoachingStep.PositiveThoughts) {
			return (
				<PositiveThoughtsForm
					situation={situation}
					updateSituation={updateSituation}
				/>
			)
		} else if (
			situation.currentStep === CoachingStep.PositiveThoughtChoice
		) {
			return (
				<PositiveThoughtChoiceForm
					situation={situation}
					updateSituation={updateSituation}
				/>
			)
		} else if (situation.currentStep === CoachingStep.PositiveRecap) {
			return (
				<PositiveRecap
					situation={situation}
					updateSituation={updateSituation}
				/>
			)
		} else return null
	}, [situation, updateSituation])

	return stepForm
}

export default PositiveTransformation

import { Grid } from '@mui/material'

import BurgerMenuIcon from 'presentation/views/components/icons/BurgerMenuIcon'

interface Props {
	openMenu: () => void
}

export default function Header({ openMenu }: Props) {
	return (
		<Grid container>
			<BurgerMenuIcon onClick={openMenu} />
		</Grid>
	)
}

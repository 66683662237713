import { RouterProvider } from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline'

import AuthProvider from '../../providers/AuthProvider/AuthProvider'
import InstallProvider from '../../providers/InstallProvider/InstallProvider'
import ThemeProvider from '../../providers/ThemeProvider/ThemeProvider'

import LoadingScreen from './LoadingScreen/LoadingScreen'

import './styles.css'

import router from '../pages/Router'

export default function Root() {
	return (
		<InstallProvider>
			<ThemeProvider>
				<AuthProvider>
					<CssBaseline />
					<LoadingScreen>
						<RouterProvider router={router} />
					</LoadingScreen>
				</AuthProvider>
			</ThemeProvider>
		</InstallProvider>
	)
}

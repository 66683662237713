import { Drawer, Box } from '@mui/material'

import { AuthStatus } from '../../../components/Auth/AuthStatus'

interface Props {
	opened: boolean
	close: () => void
}

export default function Header({ opened, close }: Props) {
	return (
		<Drawer anchor='left' open={opened} onClose={close}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-end',
					height: '100%',
					minWidth: 246,
					padding: '32px 16px',
				}}
			>
				<AuthStatus />
			</Box>
		</Drawer>
	)
}

import { useCallback, useMemo } from 'react'
import { Divider, IconButton, Modal } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import Item from './Item/Item'

import './styles.css'

type Content = {
	id: string
	text: string
}

type DetailsPanelProps = {
	isOpen: boolean
	close: () => void
	title: string
	content: Array<Content>
	editable: boolean
	editContent?: (content: string, index: number) => void
	removeContent?: (index: number) => void
}

function DetailsPanel({
	isOpen,
	close,
	title,
	content,
	editable = false,
	editContent = undefined,
	removeContent = undefined,
}: DetailsPanelProps) {
	/**
	 * Callbacks
	 */

	const stopPropagation = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation()
		},
		[]
	)

	/**
	 * Render
	 */

	const contentRender = useMemo(() => {
		const contentRender = []
		for (let i = 0; i < content.length; i++) {
			contentRender.push(
				<Item
					key={content[i].id}
					index={i}
					text={content[i].text}
					editable={editable}
					editContent={editContent}
					removeContent={removeContent}
				/>
			)

			if (i + 1 < content.length)
				contentRender.push(<Divider key={`${content[i].id}.5`} />)
		}

		return contentRender
	}, [content, editable, editContent, removeContent])

	return (
		<Modal open={isOpen} onClose={close}>
			<div className='slider-editor-modal' onClick={close}>
				<div className='slider-editor-layout' onClick={close}>
					<div
						className='slider-editor-title'
						onClick={stopPropagation}
					>
						<div>{title}</div>
						<IconButton onClick={close}>
							<CloseIcon />
						</IconButton>
					</div>
					<div
						className='slider-editor-content'
						onClick={stopPropagation}
					>
						<div className='slider-editor-content-list'>
							{contentRender}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default DetailsPanel

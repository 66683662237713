import Questionnaire from '../entities/Questionnaire'
import QuestionnaireDTO from '../../interfaces/data-transfer-objects/QuestionnaireDTO'

import QuestionnaireStatusMapper from './QuestionnaireStatusMapper'
import QuestionMapper from './QuestionMapper'
import CategoryMapper from './CategoryMapper'
import WeightMapper from './WeightMapper'

class QuestionnaireMapper {
	static toDTO(questionnaire: Questionnaire): QuestionnaireDTO {
		return {
			status: QuestionnaireStatusMapper.toDTO(questionnaire.status),
			questions: questionnaire.questions.map((question) =>
				QuestionMapper.toDTO(question)
			),
			answersCategories: questionnaire.answersCategories.map((category) =>
				CategoryMapper.toDTO(category)
			),
			weightScale: questionnaire.weightScale.map((weight) =>
				WeightMapper.toDTO(weight)
			),
		}
	}
}

export default QuestionnaireMapper

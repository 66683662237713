import { useMemo } from 'react'
import { IconButton, Grow, Paper } from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'

import './styles.css'

type TileProps = {
	text: string
	editable: boolean
}

function Tile({ text, editable }: TileProps) {
	/**
	 * Render
	 */

	const editionIconRender = useMemo(() => {
		if (editable === false) return null

		return (
			<div className='slider-edition-icon-anchor'>
				<div className='slider-edition-icon'>
					<IconButton>
						<EditIcon fontSize='small' />
					</IconButton>
				</div>
			</div>
		)
	}, [editable])

	return (
		<Grow in>
			<Paper
				className='slider-tile'
				style={{ backgroundColor: '#ffffff' }}
			>
				<div className='slider-text'>{text}</div>
				{editionIconRender}
			</Paper>
		</Grow>
	)
}

export default Tile

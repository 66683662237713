import { useCallback, useMemo, useState } from 'react'
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'

import { formatSentence } from 'presentation/utils/string-formatter'

type NewActionFormProps = {
	addAction: (action: string) => void
}

function NewActionForm({ addAction }: NewActionFormProps) {
	/**
	 * States
	 */

	const [action, setAction] = useState<string>('')

	/**
	 * Memo
	 */

	const isConfirmable = useMemo(() => {
		if (action.trim().length > 0) return true
		return false
	}, [action])

	/**
	 * Callbacks
	 */

	const confirmAction = useCallback(() => {
		if (action.trim().length === 0) return

		addAction(formatSentence(action))
		setAction('')
	}, [action, addAction])

	const onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setAction(event.target.value)
		},
		[]
	)

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent) => {
			if (event.key === 'Enter') confirmAction()
		},
		[confirmAction]
	)

	/**
	 * Render
	 */

	const confirmButtonRender = useMemo(() => {
		if (isConfirmable === false) return null

		return (
			<IconButton color='primary' onClick={confirmAction} edge='end'>
				<AddIcon />
			</IconButton>
		)
	}, [isConfirmable, confirmAction])

	return (
		<OutlinedInput
			placeholder={`Je...`}
			value={action}
			onChange={onChange}
			onKeyDown={onKeyDown}
			endAdornment={
				<InputAdornment position='end'>
					{confirmButtonRender}
				</InputAdornment>
			}
			fullWidth
		/>
	)
}

export default NewActionForm

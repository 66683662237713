import Tips from 'domain/Support/PerspectiveSolver/core/tips/Tips'

const tips: Tips = {
	instruction: {
		title: '', //`Commençons par formuler ta problématique pour qu’elle soit factuelle.`,
		pages: [
			{
				text: [
					`Comment préciser ta problématique ?`,
					`Le but est de trouver une seule thématique. Plus tu choisiras un sujet précis, plus le coaching sera efficace.`,
					`Ces deux questions t'aideront à préciser le sujet :`,
					`- Qu’est ce qui te <b>dérange le plus</b> dans ta problématique ?
- Et quelle est la <b>pire chose</b> que tu t’imagines vivre dans cette situation ?`,
					`Prenons le cas de Marie. Elle commence un nouveau travail et a plein de peurs.`,
					`Elle répond aux deux questions :`,
					`Qu’est ce qui te <b>dérange le plus</b> dans ta problématique ?
- <i>J'ai peur de ne pas être assez présente pour son couple</i>
- <i>J'ai peur de ne pas réussir à m'organiser dans mon travail</i>`,
					`Ce sont des sujets différents ! Elle devra choisir un seul sujet par session. Passons à la deuxième question`,
					`Quelle est la <b>pire chose</b> que tu t’imagines vivre à propos du nouveau travail ?
- <i>J'ai peur que mon couple se brise</i> 
- <i>J'ai peur d’échouer ma vie professionnelle et de me sentir incapable</i>`,
					`Grâce aux deux questions, Marie a préciser ses sujets de coaching. Elle va choisir une de ses réponses pour commencer.`,
				],
				confirmLabel: `Je décris ma problématique`,
			},
		],
	},
}

export default tips

import Questionnaire from 'domain/Diagnostic/WellBeingSnapshot/Questionnaire/core/entities/Questionnaire'
import Category from 'domain/Diagnostic/WellBeingSnapshot/Questionnaire/core/entities/Category'
import Weight from 'domain/Diagnostic/WellBeingSnapshot/Questionnaire/core/entities/Weight'
import Question from 'domain/Diagnostic/WellBeingSnapshot/Questionnaire/core/entities/Question'
import CategoryWeight from 'domain/Diagnostic/WellBeingSnapshot/Questionnaire/core/entities/CategoryWeight'

import StrategyCategory from 'domain/Psycho/core/StrategyCategory'
import StrategyCategoriesMock from 'domain/Psycho/core/data-access/StrategiesCategoriesMock'

const rawQuestionnaire = {
	id: '1',
	weights: [
		{
			code: `VERY_LOW`,
			value: 2,
		},
		{
			code: `LOW`,
			value: 6,
		},
		{
			code: `MEDIUM`,
			value: 14,
		},
		{
			code: `HIGH`,
			value: 28,
		},
		{
			code: `VERY_HIGH`,
			value: 40,
		},
	],
	questions: [
		{
			id: '1',
			wording: `Comment te sens-tu par rapport à ta santé ?`,
			revertScoreRatio: false,
			answers: [`Très mal`, `Mal`, `Assez bien`, `Bien`, `Très bien`],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `VERY_HIGH`,
				},
				{
					categoryId: '3',
					weightCode: `LOW`,
				},
				{
					categoryId: '4',
					weightCode: `LOW`,
				},
				{
					categoryId: '5',
					weightCode: `LOW`,
				},
				{
					categoryId: '6',
					weightCode: `LOW`,
				},
				{
					categoryId: '7',
					weightCode: `LOW`,
				},
				{
					categoryId: '8',
					weightCode: `MEDIUM`,
				},
			],
		},
		{
			id: '2',
			wording: `Es-tu satisfait·e de ta régularité à prendre soin de ta santé ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout satisfait·e`,
				`Peu satisfait·e`,
				`Plutôt satisfait·e`,
				`Satisfait·e`,
				`Très satisfait·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `HIGH`,
				},
				{
					categoryId: '3',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '4',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '5',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '6',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '7',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '8',
					weightCode: `VERY_LOW`,
				},
			],
		},
		{
			id: '3',
			wording: `Te sens-tu actuellement financièrement en sécurité ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout satisfait·e`,
				`Peu satisfait·e`,
				`Plutôt satisfait·e`,
				`Satisfait·e`,
				`Très satisfait·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '2',
					weightCode: `VERY_HIGH`,
				},
				{
					categoryId: '3',
					weightCode: `LOW`,
				},
				{
					categoryId: '4',
					weightCode: `LOW`,
				},
				{
					categoryId: '5',
					weightCode: `LOW`,
				},
				{
					categoryId: '6',
					weightCode: `LOW`,
				},
				{
					categoryId: '7',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '8',
					weightCode: `HIGH`,
				},
			],
		},
		{
			id: '4',
			wording: `Es-tu satisfait·e de ta capacité à gérer tes finances ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout satisfait·e`,
				`Peu satisfait·e`,
				`Plutôt satisfait·e`,
				`Satisfait·e`,
				`Très satisfait·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '2',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '3',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '4',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '5',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '6',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '7',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '8',
					weightCode: `LOW`,
				},
			],
		},
		{
			id: '5',
			wording: `À quel point les problèmes financiers te provoquent du stress ?`,
			revertScoreRatio: true,
			answers: [
				`Pas du tout`,
				`Très peu`,
				`Peu`,
				`Beaucoup`,
				`Énormément`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `VERY_HIGH`,
				},
				{
					categoryId: '2',
					weightCode: `VERY_HIGH`,
				},
				{
					categoryId: '3',
					weightCode: `LOW`,
				},
				{
					categoryId: '4',
					weightCode: `LOW`,
				},
				{
					categoryId: '5',
					weightCode: `LOW`,
				},
				{
					categoryId: '6',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '7',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '8',
					weightCode: `VERY_HIGH`,
				},
			],
		},
		{
			id: '6',
			wording: `Es-tu confiant·e sur ta capacité à gérer tes finances personnelles ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout confiant·e`,
				`Peu confiant·e`,
				`Plutôt confiant·e`,
				`Confiant·e`,
				`Très confiant·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '2',
					weightCode: `LOW`,
				},
				{
					categoryId: '3',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '4',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '5',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '6',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '7',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '8',
					weightCode: `LOW`,
				},
			],
		},
		{
			id: '7',
			wording: `Y a-t-il des éléments externes qui t’empêchent d’avoir une satisfaction financière ?`,
			revertScoreRatio: true,
			answers: [
				`Pas du tout`,
				`Très peu`,
				`Peu`,
				`Beaucoup`,
				`Énormément`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '2',
					weightCode: `LOW`,
				},
				{
					categoryId: '3',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '4',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '5',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '6',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '7',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '8',
					weightCode: `LOW`,
				},
			],
		},
		{
			id: '8',
			wording: `Es-tu satisfait·e de ta relation amoureuse ou situation romantique ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout satisfait·e`,
				`Peu satisfait·e`,
				`Plutôt satisfait·e`,
				`Satisfait·e`,
				`Très satisfait·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '3',
					weightCode: `VERY_HIGH`,
				},
				{
					categoryId: '4',
					weightCode: `LOW`,
				},
				{
					categoryId: '5',
					weightCode: `LOW`,
				},
				{
					categoryId: '6',
					weightCode: `LOW`,
				},
				{
					categoryId: '7',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '8',
					weightCode: `HIGH`,
				},
			],
		},
		{
			id: '9',
			wording: `Es-tu satisfait·e de la façon dont tu communiques tes émotions et besoins à ton partenaire ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout satisfait·e`,
				`Peu satisfait·e`,
				`Plutôt satisfait·e`,
				`Satisfait·e`,
				`Très satisfait·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '2',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '3',
					weightCode: `HIGH`,
				},
				{
					categoryId: '4',
					weightCode: `LOW`,
				},
				{
					categoryId: '5',
					weightCode: `LOW`,
				},
				{
					categoryId: '6',
					weightCode: `LOW`,
				},
				{
					categoryId: '7',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '8',
					weightCode: `MEDIUM`,
				},
			],
		},
		{
			id: '10',
			wording: `Es-tu satisfait·e de la communication de ton partenaire dans la relation ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout satisfait·e`,
				`Peu satisfait·e`,
				`Plutôt satisfait·e`,
				`Satisfait·e`,
				`Très satisfait·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `LOW`,
				},
				{
					categoryId: '2',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '3',
					weightCode: `HIGH`,
				},
				{
					categoryId: '4',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '5',
					weightCode: `LOW`,
				},
				{
					categoryId: '6',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '8',
					weightCode: `LOW`,
				},
			],
		},
		{
			id: '11',
			wording: `À quel point es-tu confiant·e dans ta relation amoureuse ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout confiant·e`,
				`Peu confiant·e`,
				`Plutôt confiant·e`,
				`Confiant·e`,
				`Très confiant·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '2',
					weightCode: `LOW`,
				},
				{
					categoryId: '3',
					weightCode: `VERY_HIGH`,
				},
				{
					categoryId: '4',
					weightCode: `LOW`,
				},
				{
					categoryId: '5',
					weightCode: `LOW`,
				},
				{
					categoryId: '6',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '8',
					weightCode: `MEDIUM`,
				},
			],
		},
		{
			id: '12',
			wording: `À quel point accordes-tu une importance à ce que ton couple corresponde à ton idéal de relation ?`,
			revertScoreRatio: true,
			answers: [
				`Pas du tout important`,
				`Très peu important`,
				`Peu important`,
				`Important`,
				`Très important`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '2',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '3',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '4',
					weightCode: `LOW`,
				},
				{
					categoryId: '5',
					weightCode: `LOW`,
				},
				{
					categoryId: '6',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '7',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '8',
					weightCode: `MEDIUM`,
				},
			],
		},
		{
			id: '13',
			wording: `Es-tu satisfait·e de la relation avec les membres de ta famille ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout satisfait·e`,
				`Peu satisfait·e`,
				`Plutôt satisfait·e`,
				`Satisfait·e`,
				`Très satisfait·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '2',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '3',
					weightCode: `LOW`,
				},
				{
					categoryId: '4',
					weightCode: `VERY_HIGH`,
				},
				{
					categoryId: '5',
					weightCode: `LOW`,
				},
				{
					categoryId: '6',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '8',
					weightCode: `MEDIUM`,
				},
			],
		},
		{
			id: '14',
			wording: `Te sens-tu soutenu par ta famille ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout`,
				`Très peu`,
				`Peu`,
				`Beaucoup`,
				`Énormément`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `LOW`,
				},
				{
					categoryId: '2',
					weightCode: `LOW`,
				},
				{
					categoryId: '3',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '4',
					weightCode: `HIGH`,
				},
				{
					categoryId: '5',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '6',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '7',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '8',
					weightCode: `LOW`,
				},
			],
		},
		{
			id: '15',
			wording: `Est-ce que ton bien-être dépend du comportement de ta famille ?`,
			revertScoreRatio: true,
			answers: [
				`Pas du tout`,
				`Très peu`,
				`Peu`,
				`Beaucoup`,
				`Énormément`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `LOW`,
				},
				{
					categoryId: '3',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '4',
					weightCode: `VERY_HIGH`,
				},
				{
					categoryId: '5',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '6',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '7',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '8',
					weightCode: `MEDIUM`,
				},
			],
		},
		{
			id: '16',
			wording: `Es-tu satisfait·e de la façon dont tu communiques tes émotions et besoins à ta famille ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout satisfait·e`,
				`Peu satisfait·e`,
				`Plutôt satisfait·e`,
				`Satisfait·e`,
				`Très satisfait·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `LOW`,
				},
				{
					categoryId: '2',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '3',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '4',
					weightCode: `HIGH`,
				},
				{
					categoryId: '5',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '6',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '8',
					weightCode: `MEDIUM`,
				},
			],
		},
		{
			id: '17',
			wording: `Es-tu satisfait·e de tes relations amicales ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout satisfait·e`,
				`Peu satisfait·e`,
				`Plutôt satisfait·e`,
				`Satisfait·e`,
				`Très satisfait·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `LOW`,
				},
				{
					categoryId: '3',
					weightCode: `LOW`,
				},
				{
					categoryId: '4',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '5',
					weightCode: `VERY_HIGH`,
				},
				{
					categoryId: '6',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '7',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '8',
					weightCode: `HIGH`,
				},
			],
		},
		{
			id: '18',
			wording: `Penses-tu que tu peux compter sur tes amis pendant une période difficile ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout`,
				`Très peu`,
				`Peu`,
				`Beaucoup`,
				`Énormément`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `LOW`,
				},
				{
					categoryId: '3',
					weightCode: `LOW`,
				},
				{
					categoryId: '4',
					weightCode: `LOW`,
				},
				{
					categoryId: '5',
					weightCode: `VERY_HIGH`,
				},
				{
					categoryId: '6',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '7',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '8',
					weightCode: `LOW`,
				},
			],
		},
		{
			id: '19',
			wording: `Es-tu satisfait·e du temps passé avec tes amis ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout satisfait·e`,
				`Peu satisfait·e`,
				`Plutôt satisfait·e`,
				`Satisfait·e`,
				`Très satisfait·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '3',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '5',
					weightCode: `VERY_HIGH`,
				},
				{
					categoryId: '8',
					weightCode: `LOW`,
				},
			],
		},
		{
			id: '20',
			wording: `Es-tu satisfait·e de la fréquence à laquelle tu vois tes amis ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout satisfait·e`,
				`Peu satisfait·e`,
				`Plutôt satisfait·e`,
				`Satisfait·e`,
				`Très satisfait·e`,
			],
			categoriesWeights: [
				{
					categoryId: '3',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '5',
					weightCode: `VERY_HIGH`,
				},
				{
					categoryId: '6',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '7',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '8',
					weightCode: `LOW`,
				},
			],
		},
		{
			id: '21',
			wording: `Es-tu satisait·e de ton travail actuel ou de ta carrière ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout satisfait·e`,
				`Peu satisfait·e`,
				`Plutôt satisfait·e`,
				`Satisfait·e`,
				`Très satisfait·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '2',
					weightCode: `LOW`,
				},
				{
					categoryId: '3',
					weightCode: `LOW`,
				},
				{
					categoryId: '4',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '5',
					weightCode: `LOW`,
				},
				{
					categoryId: '6',
					weightCode: `VERY_HIGH`,
				},
				{
					categoryId: '8',
					weightCode: `MEDIUM`,
				},
			],
		},
		{
			id: '22',
			wording: `À quel point trouves-tu que tes compétences et talents sont utilisés dans ton travail actuel ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout utilisés`,
				`Très peu utilisés`,
				`Peu utilisés`,
				`Assez utilisés`,
				`Très utilisés`,
			],
			categoriesWeights: [
				{
					categoryId: '2',
					weightCode: `LOW`,
				},
				{
					categoryId: '3',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '4',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '5',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '6',
					weightCode: `HIGH`,
				},
				{
					categoryId: '8',
					weightCode: `LOW`,
				},
			],
		},
		{
			id: '23',
			wording: `Es-tu satisfait·e de ton équilibre travail-vie ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout satisfait·e`,
				`Peu satisfait·e`,
				`Plutôt satisfait·e`,
				`Satisfait·e`,
				`Très satisfait·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `HIGH`,
				},
				{
					categoryId: '3',
					weightCode: `LOW`,
				},
				{
					categoryId: '4',
					weightCode: `LOW`,
				},
				{
					categoryId: '5',
					weightCode: `LOW`,
				},
				{
					categoryId: '6',
					weightCode: `VERY_HIGH`,
				},
				{
					categoryId: '8',
					weightCode: `MEDIUM`,
				},
			],
		},
		{
			id: '24',
			wording: `Es-tu satisfait·e des efforts que tu fournis dans ton travail et ta carrière ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout satisfait·e`,
				`Peu satisfait·e`,
				`Plutôt satisfait·e`,
				`Satisfait·e`,
				`Très satisfait·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `LOW`,
				},
				{
					categoryId: '2',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '3',
					weightCode: `LOW`,
				},
				{
					categoryId: '4',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '5',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '6',
					weightCode: `HIGH`,
				},
				{
					categoryId: '8',
					weightCode: `LOW`,
				},
			],
		},
		{
			id: '25',
			wording: `Es-tu satisfait·e de ton organisation professionnelle ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout satisfait·e`,
				`Peu satisfait·e`,
				`Plutôt satisfait·e`,
				`Satisfait·e`,
				`Très satisfait·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `LOW`,
				},
				{
					categoryId: '6',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '8',
					weightCode: `LOW`,
				},
			],
		},
		{
			id: '26',
			wording: `Y a-t-il des éléments externes qui t’empêchent d’être satisfait de ta vie professionnelle ?`,
			revertScoreRatio: true,
			answers: [
				`Pas du tout`,
				`Très peu`,
				`Peu`,
				`Beaucoup`,
				`Énormément`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '2',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '3',
					weightCode: `LOW`,
				},
				{
					categoryId: '4',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '5',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '6',
					weightCode: `HIGH`,
				},
				{
					categoryId: '7',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '8',
					weightCode: `LOW`,
				},
			],
		},
		{
			id: '27',
			wording: `Comment évalues-tu ta santé physique générale ?`,
			revertScoreRatio: false,
			answers: [
				`Très mauvaise`,
				`Mauvaise`,
				`Moyenne`,
				`Bonne`,
				`Très bonne`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `VERY_HIGH`,
				},
				{
					categoryId: '2',
					weightCode: `LOW`,
				},
				{
					categoryId: '3',
					weightCode: `LOW`,
				},
				{
					categoryId: '4',
					weightCode: `LOW`,
				},
				{
					categoryId: '5',
					weightCode: `LOW`,
				},
				{
					categoryId: '6',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '7',
					weightCode: `HIGH`,
				},
				{
					categoryId: '8',
					weightCode: `MEDIUM`,
				},
			],
		},
		{
			id: '28',
			wording: `Es-tu satisfait·e de ton niveau actuel de forme physique ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout satisfait·e`,
				`Peu satisfait·e`,
				`Plutôt satisfait·e`,
				`Satisfait·e`,
				`Très satisfait·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '3',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '4',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '5',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '6',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '7',
					weightCode: `VERY_HIGH`,
				},
				{
					categoryId: '8',
					weightCode: `MEDIUM`,
				},
			],
		},
		{
			id: '29',
			wording: `Y a-t-il des éléments externes qui t’empêchent d’avoir ou maintenir une activité physique ?`,
			revertScoreRatio: true,
			answers: [
				`Pas du tout`,
				`Très peu`,
				`Peu`,
				`Beaucoup`,
				`Énormément`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `LOW`,
				},
				{
					categoryId: '7',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '8',
					weightCode: `LOW`,
				},
			],
		},
		{
			id: '30',
			wording: `Es-tu satisfait·e de ton niveau actuel d'activité sportive ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout satisfait·e`,
				`Peu satisfait·e`,
				`Plutôt satisfait·e`,
				`Satisfait·e`,
				`Très satisfait·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '7',
					weightCode: `HIGH`,
				},
				{
					categoryId: '8',
					weightCode: `LOW`,
				},
			],
		},
		{
			id: '31',
			wording: `Es-tu satisfait·e de tes efforts à faire des activités sportives ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout satisfait·e`,
				`Peu satisfait·e`,
				`Plutôt satisfait·e`,
				`Satisfait·e`,
				`Très satisfait·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `LOW`,
				},
				{
					categoryId: '7',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '8',
					weightCode: `LOW`,
				},
			],
		},
		{
			id: '32',
			wording: `Comment évalues-tu ton bien-être émotionnel ?`,
			revertScoreRatio: false,
			answers: [`Très mauvais`, `Mauvais`, `Moyen`, `Bon`, `Très bon`],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `HIGH`,
				},
				{
					categoryId: '2',
					weightCode: `LOW`,
				},
				{
					categoryId: '3',
					weightCode: `HIGH`,
				},
				{
					categoryId: '4',
					weightCode: `HIGH`,
				},
				{
					categoryId: '5',
					weightCode: `HIGH`,
				},
				{
					categoryId: '6',
					weightCode: `HIGH`,
				},
				{
					categoryId: '7',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '8',
					weightCode: `VERY_HIGH`,
				},
			],
		},
		{
			id: '33',
			wording: `Comment gères-tu tes émotions dans des situations difficiles ?`,
			revertScoreRatio: false,
			answers: [
				`Très mal`,
				`Plutôt mal`,
				`Moyennement`,
				`Plutôt bien`,
				`Très bien`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `HIGH`,
				},
				{
					categoryId: '2',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '3',
					weightCode: `HIGH`,
				},
				{
					categoryId: '4',
					weightCode: `HIGH`,
				},
				{
					categoryId: '5',
					weightCode: `HIGH`,
				},
				{
					categoryId: '6',
					weightCode: `HIGH`,
				},
				{
					categoryId: '7',
					weightCode: `LOW`,
				},
				{
					categoryId: '8',
					weightCode: `VERY_HIGH`,
				},
			],
		},
		{
			id: '34',
			wording: `Exerces-tu souvent des activités qui t'aident à maintenir ou améliorer ton bien-être émotionnel ?`,
			revertScoreRatio: false,
			answers: [
				`Très rarement`,
				`Rarement`,
				`Occasionnellement`,
				`Souvent`,
				`Très souvent`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `HIGH`,
				},
				{
					categoryId: '2',
					weightCode: `LOW`,
				},
				{
					categoryId: '3',
					weightCode: `HIGH`,
				},
				{
					categoryId: '4',
					weightCode: `HIGH`,
				},
				{
					categoryId: '5',
					weightCode: `HIGH`,
				},
				{
					categoryId: '6',
					weightCode: `HIGH`,
				},
				{
					categoryId: '7',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '8',
					weightCode: `VERY_HIGH`,
				},
			],
		},
		{
			id: '35',
			wording: `À quel point es-tu confiant·e d’accomplir ce qui te tient à cœur ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout confiant·e`,
				`Peu confiant·e`,
				`Plutôt confiant·e`,
				`Confiant·e`,
				`Très confiant·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `LOW`,
				},
				{
					categoryId: '2',
					weightCode: `HIGH`,
				},
				{
					categoryId: '3',
					weightCode: `HIGH`,
				},
				{
					categoryId: '4',
					weightCode: `HIGH`,
				},
				{
					categoryId: '5',
					weightCode: `HIGH`,
				},
				{
					categoryId: '6',
					weightCode: `HIGH`,
				},
				{
					categoryId: '7',
					weightCode: `LOW`,
				},
				{
					categoryId: '8',
					weightCode: `VERY_HIGH`,
				},
			],
		},
		{
			id: '36',
			wording: `À quel point es-tu satisfait·e de la personne que tu es ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout satisfait·e`,
				`Peu satisfait·e`,
				`Plutôt satisfait·e`,
				`Satisfait·e`,
				`Très satisfait·e`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '2',
					weightCode: `LOW`,
				},
				{
					categoryId: '3',
					weightCode: `HIGH`,
				},
				{
					categoryId: '4',
					weightCode: `HIGH`,
				},
				{
					categoryId: '5',
					weightCode: `HIGH`,
				},
				{
					categoryId: '6',
					weightCode: `HIGH`,
				},
				{
					categoryId: '7',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '8',
					weightCode: `VERY_HIGH`,
				},
			],
		},
		{
			id: '37',
			wording: `À quel point es-tu à l’aise de passer du temps seul·e ?`,
			revertScoreRatio: false,
			answers: [
				`Pas du tout à l'aise`,
				`Peu à l'aise`,
				`Plutôt à l'aise`,
				`À l'aise`,
				`Très à l'aise`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `LOW`,
				},
				{
					categoryId: '3',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '4',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '5',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '7',
					weightCode: `VERY_LOW`,
				},
				{
					categoryId: '8',
					weightCode: `VERY_HIGH`,
				},
			],
		},
		{
			id: '38',
			wording: `Quand tu sens que tu traverses une difficulté, te fais-tu accompagner ?`,
			revertScoreRatio: false,
			answers: [
				`Très rarement`,
				`Rarement`,
				`Occasionnellement`,
				`Souvent`,
				`Très souvent`,
			],
			categoriesWeights: [
				{
					categoryId: '1',
					weightCode: `LOW`,
				},
				{
					categoryId: '3',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '4',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '5',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '6',
					weightCode: `MEDIUM`,
				},
				{
					categoryId: '8',
					weightCode: `HIGH`,
				},
			],
		},
	],
}

// Categories
const categoriesMock: Array<Category> = []
for (let i = 0; i < StrategyCategoriesMock.length; i++) {
	const rawCategory: StrategyCategory = StrategyCategoriesMock[i]
	const category: Category = {
		id: rawCategory.id,
		label: rawCategory.label,
	}

	categoriesMock.push(category)
}

// Weights
const weightsMock: Array<Weight> = []
for (let i = 0; i < rawQuestionnaire.weights.length; i++) {
	const rawWeight = rawQuestionnaire.weights[i]
	const weight: Weight = {
		code: rawWeight.code,
		value: rawWeight.value,
	}

	weightsMock.push(weight)
}

// Questions
const questionsMock: Array<Question> = []
for (let i = 0; i < rawQuestionnaire.questions.length; i++) {
	const rawQuestion = rawQuestionnaire.questions[i]

	const categoriesWeightsMock: Array<CategoryWeight> = []
	for (let y = 0; y < rawQuestion.categoriesWeights.length; y++) {
		const rawCategoryWeight = rawQuestion.categoriesWeights[y]

		const categoryWeight: CategoryWeight = {
			categoryId: rawCategoryWeight.categoryId,
			weightCode: rawCategoryWeight.weightCode,
		}

		categoriesWeightsMock.push(categoryWeight)
	}

	const question: Question = {
		id: rawQuestion.id,
		wording: rawQuestion.wording,
		revertScoreRatio: rawQuestion.revertScoreRatio,
		answers: rawQuestion.answers.slice(),
		categoriesWeights: categoriesWeightsMock,
		selectedAnswer: undefined,
	}

	questionsMock.push(question)
}

// Questionnaire
function createQuestionnaireFromMock() {
	return new Questionnaire(questionsMock, categoriesMock, weightsMock)
}

export default createQuestionnaireFromMock

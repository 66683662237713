import {
	createContext,
	PropsWithChildren,
	useEffect,
	useMemo,
	useState,
} from 'react'

import { produce } from 'immer'

/**
 * Interfaces
 */

interface InstallState {
	installable: boolean
	promptInstall: () => Promise<boolean>
}

interface InstallContextState extends InstallState {}

/**
 * Default Values
 */

async function defaultPromptInstall() {
	return false
}

/**
 * Context
 */

export const InstallContext = createContext<InstallContextState>({
	installable: false,
	promptInstall: defaultPromptInstall,
})

/**
 * Provider
 */

export default function InstallProvider({ children }: PropsWithChildren) {
	/**
	 * State
	 */

	const [installState, setInstallState] = useState<InstallState>({
		installable: false,
		promptInstall: defaultPromptInstall,
	})

	/**
	 * Effects
	 */

	useEffect(() => {
		function onBeforeInstallPrompt(event: any) {
			event.preventDefault()

			async function promptInstall() {
				const result = await event.prompt.call(event)

				setInstallState((state) => {
					return produce(state, (state) => {
						state.installable =
							result?.outcome === 'accepted' ? true : false
						return state
					})
				})

				if (result?.outcome === 'accepted') return true
				else return false
			}

			setInstallState({
				installable: true,
				promptInstall,
			})
		}

		window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt)

		return () => {
			window.removeEventListener(
				'beforeinstallprompt',
				onBeforeInstallPrompt
			)
		}
	}, [])

	/**
	 * Render
	 */

	const installContextValue: InstallContextState = useMemo(
		() => ({
			installable: installState.installable,
			promptInstall: installState.promptInstall,
		}),
		[installState]
	)

	return (
		<InstallContext.Provider value={installContextValue}>
			{children}
		</InstallContext.Provider>
	)
}

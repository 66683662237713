import { useMemo } from 'react'

import { Button } from '@mui/material'

import LogoutIcon from '@mui/icons-material/Logout'
import DownloadIcon from '@mui/icons-material/Download'

import useAuth from 'presentation/hooks/useAuth'
import useInstall from 'presentation/hooks/useInstall'

export function AuthStatus() {
	/**
	 * App State
	 */

	const { authUser, logout } = useAuth()
	const { installable, promptInstall } = useInstall()

	/**
	 * Render
	 */

	const installButtonRender = useMemo(() => {
		if (installable === false) return null

		return (
			<Button
				variant='contained'
				sx={{ mt: 1, mb: 1, pt: 1, pb: 1, pl: 2, pr: 2 }}
				startIcon={<DownloadIcon />}
				onClick={async () => {
					await promptInstall()
				}}
			>
				Installer Baume
			</Button>
		)
	}, [installable, promptInstall])

	if (!authUser) return null

	return (
		<>
			{installButtonRender}
			<Button
				variant='contained'
				sx={{ mt: 1, mb: 1, pt: 1, pb: 1, pl: 2, pr: 2 }}
				startIcon={<LogoutIcon />}
				onClick={async () => {
					await logout()
				}}
			>
				Déconnexion
			</Button>
		</>
	)
}

import { useMemo } from 'react'
import { Box, IconButton, LinearProgress } from '@mui/material'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'

import QuestionnaireDTO from 'domain/Diagnostic/WellBeingSnapshot/Questionnaire/interfaces/data-transfer-objects/QuestionnaireDTO'

interface Props {
	questionnaire: QuestionnaireDTO
	currentQuestionIndex: number | undefined
	goToPreviousQuestion: () => void
	quitQuestionnaire: () => void
}

export default function View({
	questionnaire,
	currentQuestionIndex,
	goToPreviousQuestion,
	quitQuestionnaire,
}: Props) {
	/**
	 * Memos
	 */

	const progress = useMemo(() => {
		if (currentQuestionIndex === undefined || questionnaire === null)
			return 0

		return (currentQuestionIndex / questionnaire.questions.length) * 100
	}, [currentQuestionIndex, questionnaire])

	/**
	 * Render
	 */

	const goBackButtonRender = useMemo(() => {
		if (currentQuestionIndex === undefined || currentQuestionIndex === 0)
			return null

		return (
			<IconButton onClick={goToPreviousQuestion}>
				<ArrowBackIcon />
			</IconButton>
		)
	}, [currentQuestionIndex, goToPreviousQuestion])

	return (
		<Box sx={{ display: 'flex', gap: '0.5rem' }}>
			<Box
				sx={{
					flex: '0 0 40px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				{goBackButtonRender}
			</Box>
			<Box
				sx={{
					flex: '1 0 0',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<LinearProgress
					sx={{ width: '100%', height: 8, borderRadius: 8 }}
					variant='determinate'
					value={progress}
				/>
			</Box>
			<Box
				sx={{
					flex: '0 0 40px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<IconButton onClick={quitQuestionnaire}>
					<CloseIcon />
				</IconButton>
			</Box>
		</Box>
	)
}

import { useCallback, useMemo } from 'react'

import InstructionPage from 'domain/Support/PerspectiveSolver/core/tips/InstructionPage'

import './styles.css'

type InstructionPageProps = {
	instructionPage: InstructionPage
}

function InstructionPageComponent({ instructionPage }: InstructionPageProps) {
	/**
	 * Utils
	 */

	const renderMessage = useCallback((message: string) => {
		const lines = message.split('\n')

		const linesRender = []
		for (let i = 0; i < lines.length; i++)
			linesRender.push(
				<div key={i} dangerouslySetInnerHTML={{ __html: lines[i] }} />
			)

		return (
			<div className='tips-instruction-page-message'> {linesRender} </div>
		)
	}, [])

	/**
	 * Render
	 */

	const messagesRender = useMemo(() => {
		return instructionPage.text.map((text, index) => {
			let className = 'tips-instruction-page-message-container'
			if (index === 0) className += ' main'

			return (
				<div key={index} className={className}>
					{renderMessage(text)}
				</div>
			)
		})
	}, [instructionPage, renderMessage])

	return (
		<>
			<div className='tips-instruction-page'>{messagesRender}</div>
		</>
	)
}

export default InstructionPageComponent

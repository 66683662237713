import { useCallback, useMemo, useState } from 'react'
import { produce } from 'immer'
import {
	MenuItem,
	Select,
	SelectChangeEvent,
	useMediaQuery,
	useTheme,
} from '@mui/material'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'
import CoachingStep from 'domain/Support/PerspectiveSolver/core/CoachingStep'
import FeelingType from 'domain/Psycho/core/FeelingType'

import FeelingsMock from 'domain/Psycho/core/data-access/FeelingsMock'
import TipsMock from 'domain/Support/PerspectiveSolver/core/data-access/tips/ResultFeelingForm'

import ConfirmationButton from 'presentation/views/components/ConfirmationButton/ConfirmationButton'
import Tips from 'presentation/views/components/Tips/Tips'
import useAuth from 'presentation/hooks/useAuth'

import { getFlagValue, setFlagValue } from 'common/storage-interactor'

type ResultFeelingFormProps = {
	situation: CoachingSituation
	updateSituation: (updatedSituation: CoachingSituation) => void
}

function ResultFeelingForm({
	situation,
	updateSituation,
}: ResultFeelingFormProps) {
	const theme = useTheme()
	const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const { authUser } = useAuth()

	/**
	 * States
	 */

	const [feeling, setFeeling] = useState<string>(situation.result.feeling)

	/**
	 * Memo
	 */

	const feelings = useMemo(() => {
		const feelings = []
		for (let i = 0; i < FeelingsMock.length; i++) {
			feelings.push(FeelingsMock[i].label)
		}
		return feelings
	}, [])

	const isConfirmable = useMemo(() => feeling !== '', [feeling])

	const showTipsOnMount: boolean = useMemo(() => {
		if (getFlagValue('result-feeling-tips-seen', authUser) === false)
			return true
		return false
	}, [authUser])

	/**
	 * Callbacks
	 */

	const confirmFeeling = useCallback(() => {
		if (feeling.trim().length === 0) return

		const completeFeeling = FeelingsMock.find(
			(completeFeeling) => feeling === completeFeeling.label
		)
		if (completeFeeling === undefined) return

		const updatedSituation = produce(situation, (situation) => {
			situation.result.feeling = completeFeeling.label

			if (completeFeeling.type === FeelingType.Positive)
				situation.currentStep = CoachingStep.ResultSuccess
			else situation.currentStep = CoachingStep.ResultRedo

			return situation
		})

		updateSituation(updatedSituation)
	}, [feeling, situation, updateSituation])

	const onChange = useCallback((event: SelectChangeEvent) => {
		setFeeling(event.target.value as string)
	}, [])

	/**
	 * Render
	 */

	const optionsRender = useMemo(() => {
		const optionsRender = []

		for (let i = 0; i < feelings.length; i++) {
			const feeling = feelings[i]
			optionsRender.push(
				<MenuItem key={i} value={feeling}>
					{feeling}
				</MenuItem>
			)
		}

		return optionsRender
	}, [feelings])

	return (
		<>
			<div className='coaching-situation-content'>
				<h3>Comment te sens-tu maintenant ?</h3>
				<p style={{ marginBottom: '16px' }}>
					Choisis le sentiment qui correspond le mieux.
				</p>
				<Select
					value={feeling}
					onChange={onChange}
					style={{ marginBottom: '8px' }}
					displayEmpty
					fullWidth
				>
					<MenuItem value=''>
						<em>Choisir</em>
					</MenuItem>
					{optionsRender}
				</Select>
			</div>
			{smallScreen && (
				<div className='coaching-situation-tips'>
					<Tips
						instruction='Comment choisir mon sentiment ?'
						tips={TipsMock}
						openOnMount={showTipsOnMount}
						onClose={() => {
							setFlagValue(
								'result-feeling-tips-seen',
								true,
								authUser
							)
						}}
					/>
				</div>
			)}
			<div
				style={{ marginTop: '16px' }}
				className='coaching-situation-confirm'
			>
				<ConfirmationButton
					label={`Je me sens ${feeling.toLowerCase()}`}
					isShown={isConfirmable}
					confirm={confirmFeeling}
				/>
			</div>
		</>
	)
}

export default ResultFeelingForm

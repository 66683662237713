import { useCallback, useMemo } from 'react'
import { produce } from 'immer'

import { Box } from '@mui/material'

import StrategyCategory from 'domain/Psycho/core/StrategyCategory'
import CategoriesMock from 'domain/Psycho/core/data-access/StrategiesCategoriesMock'

import Category from './Category/View'

const sortedCategories = CategoriesMock.sort((a, b) =>
	a.label.localeCompare(b.label, 'fr-FR')
)

type Props = {
	categories: Array<string>
	setCategories: (categories: Array<string>) => void
}

export default function View({ categories, setCategories }: Props) {
	/**
	 *
	 */

	const isSelected = useCallback(
		(category: StrategyCategory) => {
			for (let i = 0; i < categories.length; i++) {
				if (categories[i] === category.id) return true
			}

			return false
		},
		[categories]
	)

	const addCategory = useCallback(
		(id: string) => {
			const newCategories = produce(categories, (draft) => {
				draft.push(id)
			})

			setCategories(newCategories)
		},
		[categories, setCategories]
	)

	const removeCategory = useCallback(
		(id: string) => {
			const newCategories = produce(categories, (draft) => {
				return draft.filter((category) => category !== id)
			})

			setCategories(newCategories)
		},
		[categories, setCategories]
	)

	/**
	 * Render
	 */

	const categoriesRender = useMemo(
		() =>
			sortedCategories.map((category) => {
				const selected = isSelected(category)

				return (
					<Category
						key={category.id}
						id={category.id}
						label={category.label}
						selected={selected}
						add={addCategory}
						remove={removeCategory}
					/>
				)
			}),
		[isSelected, addCategory, removeCategory]
	)

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignContent: 'flex-start',
				flex: '1 0 0',
				flexWrap: 'wrap',
				gap: '8px',
			}}
		>
			{categoriesRender}
		</Box>
	)
}

import { useCallback } from 'react'
import { ListItemButton, ListItemText } from '@mui/material'

import ListItemTextWithNumber from 'presentation/views/components/ListItemTextWithNumber/View'

type OptionProps = {
	text: string
	index: number
	select: (thoughtIndex: number) => void
	isSelected: boolean
}

function Option({ text, index, select, isSelected }: OptionProps) {
	/**
	 * Callbacks
	 */

	const onClick = useCallback(() => {
		select(index)
	}, [index, select])

	/**
	 * Render
	 */

	return (
		<ListItemButton onClick={onClick} selected={isSelected}>
			<ListItemText
				primary={
					<ListItemTextWithNumber
						index={index}
						text={text}
						selected={isSelected}
					/>
				}
			/>
		</ListItemButton>
	)
}

export default Option

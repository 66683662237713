import { Box, Button } from '@mui/material'

interface Props {
	startQuestionnaire: () => Promise<void>
}

export default function View({ startQuestionnaire }: Props) {
	return (
		<>
			<h3>Réponds aux questions selon ton ressenti.</h3>
			<p>Le test prendra entre 5 et 10 minutes.</p>
			<Box
				sx={{
					display: 'flex',
					width: '100%',
					marginTop: '16px',
				}}
			>
				<Button
					variant='contained'
					color='primary'
					onClick={startQuestionnaire}
					fullWidth
				>
					C'est parti !
				</Button>
			</Box>
		</>
	)
}

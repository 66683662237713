import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { useNavigate } from 'react-router-dom'

import useAuth from 'presentation/hooks/useAuth'

import { Copyright } from '../../components/Auth/Copyright'
import { CircularProgress, Grid } from '@mui/material'
import { useState } from 'react'

export default function PasswordReset() {
	const navigate = useNavigate()

	const { resetPassword } = useAuth()

	const [message /*, setMessage*/] = useState<{
		text: string
		type: 'error' | 'success'
	}>({ text: '', type: 'error' })
	const [loader, setLoader] = useState(false)

	async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault()
		setLoader(true)

		let formData = new FormData(event.currentTarget)
		let email = formData.get('email') as string

		await resetPassword(email)
		// TODO: Check how to handle it
		setLoader(false)
		/*
		console.log(data, error?.status)
		if (error?.status === 429)
			setMessage({
				text: "Pour des mesures de sécurité, vous ne pouvez effectuer qu'une demande toutes les 60 secondes.",
				type: 'error',
			})
		if (data)
			setMessage({
				text: 'Le mail de réinitilisation a été envoyé. Pensez à verifier vos spams.',
				type: 'success',
			})
			*/
	}

	return (
		<Container component='main' maxWidth='xs'>
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component='h1' variant='h5'>
					Réinitialiser mon mot de passe
				</Typography>
				<Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
					<TextField
						margin='normal'
						required
						fullWidth
						id='email'
						label='Adresse email'
						name='email'
						autoComplete='email'
						autoFocus
					/>
					<Grid
						container
						color={message.type === 'error' ? 'red' : 'green'}
					>
						{loader ? <CircularProgress /> : message.text}
					</Grid>
					<Button
						type='submit'
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2 }}
					>
						Envoyer un mail de réinitialisation
					</Button>
					<Link
						href='/login'
						variant='body2'
						onClick={() => {
							navigate('/login')
						}}
					>
						Se connecter
					</Link>
				</Box>
			</Box>
			<Copyright sx={{ mt: 8, mb: 4 }} />
		</Container>
	)
}

import {
	createContext,
	PropsWithChildren,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { produce } from 'immer'

// TODO: Make this line unecessary and remove it
import { AES } from 'crypto-js'

import AuthUserDTO from 'domain/Auth/interfaces/AuthUserDTO'

import useInteractors from 'presentation/hooks/useInteractors'

import parseRequestFromUrl from 'presentation/utils/urlParser'

/**
 * Interfaces
 */

interface AuthState {
	initialized: boolean
	authUser: AuthUserDTO | null
	request: any
}

interface AuthContextState extends AuthState {
	login: (email: string, password: string) => Promise<AuthUserDTO>
	logout: () => Promise<void>
	getUser: () => Promise<AuthUserDTO | null>
	updatePassword: (password: string) => Promise<void>
	resetPassword: (email: string) => Promise<void>
}

/**
 * Context
 */

export const AuthContext = createContext<AuthContextState>({
	initialized: false,
	authUser: null,
	request: '',
	login: async (email: string, password: string) => {
		throw Error('Function not initialized')
	},
	logout: async () => {
		throw Error('Function not initialized')
	},
	getUser: async () => {
		throw Error('Function not initialized')
	},
	updatePassword: async (password: string) => {
		throw Error('Function not initialized')
	},
	resetPassword: async (email: string) => {
		throw Error('Function not initialized')
	},
})

/**
 * Provider
 */

export default function AuthProvider({ children }: PropsWithChildren) {
	/**
	 * Base
	 */

	const { authInteractor } = useInteractors()

	/**
	 * State
	 */

	const [authState, setAuthState] = useState<AuthState>({
		initialized: false,
		authUser: null,
		request: parseRequestFromUrl(window.location.hash),
	})

	/**
	 * Callbacks
	 */

	const login = useCallback(
		async (email: string, password: string) => {
			const result = await authInteractor.login(email, password)

			const newAuthState = produce(authState, (authState) => {
				authState.authUser = result
			})

			setAuthState(newAuthState)

			return result
		},
		[authInteractor, authState]
	)

	const logout = useCallback(async () => {
		await authInteractor.logout()

		const newAuthState = produce(authState, (authState) => {
			authState.authUser = null
		})

		setAuthState(newAuthState)
	}, [authInteractor, authState])

	const getUser = useCallback(async () => {
		const result = await authInteractor.getUser()

		if (authState.initialized === true && authState.authUser === result)
			return result

		const newAuthState = produce(authState, (authState) => {
			authState.initialized = true
			authState.authUser = result
		})

		setAuthState(newAuthState)

		return result
	}, [authInteractor, authState])

	const updatePassword = useCallback(
		async (password: string) => {
			await authInteractor.updatePassword(password)

			const newAuthState = produce(authState, (authState) => {
				authState.request = parseRequestFromUrl('')
			})

			setAuthState(newAuthState)
		},
		[authInteractor, authState]
	)

	const resetPassword = useCallback(
		async (email: string) => {
			await authInteractor.resetPassword(email)
		},
		[authInteractor]
	)

	/**
	 * Effects
	 */

	useEffect(() => {
		if (authState.initialized === false) {
			getUser()
		}
	}, [authState.initialized, getUser])

	// TODO: Make this effect unecessary and remove it
	useEffect(() => {
		if (authState.authUser === null)
			sessionStorage.removeItem('navigator-session')
		else
			sessionStorage.setItem(
				'navigator-session',
				AES.encrypt(authState.authUser.id, 'baume').toString()
			)
	}, [authState.authUser])

	/**
	 * Render
	 */

	const authContextValue: AuthContextState = useMemo(
		() => ({
			initialized: authState.initialized,
			authUser: authState.authUser,
			request: authState.request,
			login,
			logout,
			getUser,
			updatePassword,
			resetPassword,
		}),
		[authState, login, logout, getUser, updatePassword, resetPassword]
	)

	return (
		<AuthContext.Provider value={authContextValue}>
			{children}
		</AuthContext.Provider>
	)
}

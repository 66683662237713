import Questionnaire from 'domain/Diagnostic/WellBeingSnapshot/Questionnaire/core/entities/Questionnaire'
import QuestionnaireRepository from 'domain/Diagnostic/WellBeingSnapshot/Questionnaire/interfaces/QuestionnaireRepository'

import {
	getWellBeingSnapshotQuestionnaire,
	setWellBeingSnapshotQuestionnaire,
	removeWellBeingSnapshotQuestionnaire,
} from 'common/storage-interactor'

import createQuestionnaireFromMock from './QuestionnaireMock'

class LocalStorageQuestionnaireRepository implements QuestionnaireRepository {
	createQuestionnaire = async () => {
		const questionnaire = createQuestionnaireFromMock()
		await this.saveQuestionnaire(questionnaire)
		return questionnaire
	}

	getCurrentQuestionnaire = async () => {
		const rawQuestionnaire: any | null = getWellBeingSnapshotQuestionnaire()

		if (rawQuestionnaire === null) return null

		try {
			const questionnaire: Questionnaire = new Questionnaire(
				rawQuestionnaire.questions,
				rawQuestionnaire.answersCategories,
				rawQuestionnaire.weightScale
			)
			return questionnaire
		} catch (error: any) {
			console.error(
				`LocalStorageQuestionnaireRepository ${error?.message}`
			)
			removeWellBeingSnapshotQuestionnaire()
			return null
		}
	}

	saveQuestionnaire = async (questionnaire: Questionnaire) => {
		setWellBeingSnapshotQuestionnaire(questionnaire)
	}

	clearQuestionnaire = async () => {
		removeWellBeingSnapshotQuestionnaire()
	}
}

export default LocalStorageQuestionnaireRepository

import { createTheme } from '@mui/material/styles'

const darkTheme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: '#4D4DD6',
		},
		secondary: {
			main: '#292929',
			light: '#ACACAC',
		},
	},
	typography: {
		fontFamily: ["Quattrocento Sans", 'sans-serif'].join(','),
	},
})

export default darkTheme

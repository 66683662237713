import { useCallback, useEffect, useMemo, useState } from 'react'
import {
	useTheme,
	useMediaQuery,
	Box,
	Button,
	OutlinedInput,
} from '@mui/material'

import ProblematicDTO from 'domain/Diagnostic/Problematic/interfaces/ProblematicDTO'

import Modal from '../Modal/View'
import SwipeableDrawer from '../SwipeableDrawer/View'
import CategoriesSelector from './CategoriesSelector/View'

type Props = {
	problematic: ProblematicDTO | null
	type: 'modal' | 'swipeable-drawer'
	opened: boolean
	confirm: (
		id: string | null,
		description: string,
		categories: Array<string>
	) => void
	cancel: () => void
}

export default function View({
	problematic,
	type,
	opened,
	confirm,
	cancel,
}: Props) {
	/**
	 * Dependencies
	 */

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	/**
	 * State
	 */

	const [description, setDescription] = useState<string>('')
	const [categories, setCategories] = useState<Array<string>>([])

	/**
	 * Memos
	 */

	const isBeingSupported = useMemo(() => {
		if (problematic === null) return false
		if (problematic.supports.length > 0) return true
		return false
	}, [problematic])

	/**
	 * Callbacks
	 */

	const onConfirm = useCallback(() => {
		const id: string | null = problematic ? problematic.id : null

		confirm(id, description.trim(), categories)
		setDescription('')
		setCategories([])
	}, [problematic, description, categories, confirm])

	const onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setDescription(event.target.value)
		},
		[]
	)

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent) => {
			if (event.key === 'Enter') onConfirm()
		},
		[onConfirm]
	)

	/**
	 * Effects
	 */

	useEffect(() => {
		if (problematic === null) {
			setDescription('')
			setCategories([])
		} else {
			setDescription(problematic.freeDescription.slice())
			setCategories(problematic.categories.slice())
		}
	}, [problematic])

	/**
	 * Render
	 */

	const titleRender = useMemo(
		() => (problematic ? 'Éditer le sujet' : 'Ajouter un nouveau sujet'),
		[problematic]
	)

	const instructionRender = useMemo(
		() =>
			isBeingSupported
				? `L'intitulé de ton sujet n'est plus modifiable hors de l'autocoaching`
				: `Écris l'intitulé de ton sujet à travailler !`,
		[isBeingSupported]
	)

	const confirmButtonRender = useMemo(
		() => (problematic ? 'Valider mon sujet' : 'Ajouter mon sujet'),
		[problematic]
	)

	const contentRender = useMemo(
		() => (
			<Box
				sx={{
					display: 'flex',
					flex: '1 0 0',
					flexDirection: 'column',
					justifyContent: 'space-between',
					height: '100%',
					width: '100%',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						flex: '0 0 fit-content',
						width: '100%',
					}}
				>
					{isMobile ? (
						<>
							<Box
								sx={{
									position: 'absolute',
									top: '20px',
									left: '50%',
									transform: 'translateX(-40px)',
									width: '80px',
									height: '4px',
									borderRadius: '4px',
									background: '#e8e8e8',
								}}
							></Box>
						</>
					) : (
						<></>
					)}
					<h3>{titleRender}</h3>
					<p>{instructionRender}</p>
					<Box style={{ padding: '24px 0px' }}>
						<OutlinedInput
							placeholder={`Mon sujet...`}
							value={description}
							onChange={onChange}
							onKeyDown={onKeyDown}
							style={{ padding: '12px' }}
							multiline
							maxRows={2}
							fullWidth
							disabled={isBeingSupported}
						/>
					</Box>
					<p style={{ paddingBottom: '8px' }}>
						Dans quelles catégories s’inscrit-il ?
					</p>
					<CategoriesSelector
						categories={categories}
						setCategories={setCategories}
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: isMobile ? 'column' : 'row',
						flex: '0 0 fit-content',
						width: '100%',
						gap: '8px',
					}}
				>
					{isMobile ? (
						<>
							<Button onClick={onConfirm} variant='contained'>
								{confirmButtonRender}
							</Button>
							<Button
								onClick={cancel}
								variant='contained'
								color='secondary'
							>
								Annuler
							</Button>
						</>
					) : (
						<>
							<Button
								onClick={cancel}
								variant='contained'
								color='secondary'
							>
								Annuler
							</Button>
							<Button onClick={onConfirm} variant='contained'>
								{confirmButtonRender}
							</Button>
						</>
					)}
				</Box>
			</Box>
		),
		[
			isMobile,
			description,
			isBeingSupported,
			titleRender,
			instructionRender,
			categories,
			confirmButtonRender,
			cancel,
			onConfirm,
			onChange,
			onKeyDown,
		]
	)

	if (type === 'modal') {
		return (
			<Modal isOpen={opened} close={cancel} maximized>
				{contentRender}
			</Modal>
		)
	} else {
		return (
			<SwipeableDrawer
				maximized
				isOpen={opened}
				close={cancel}
				anchor={'bottom'}
			>
				{contentRender}
			</SwipeableDrawer>
		)
	}
}

import { useMemo } from 'react'

import QuestionnaireDTO from 'domain/Diagnostic/WellBeingSnapshot/Questionnaire/interfaces/data-transfer-objects/QuestionnaireDTO'

import Modal from 'presentation/views/components/Modal/View'

import Instructions from './Instructions/View'
import Questionnaire from './Questionnaire/View'

interface Props {
	questionnaire: QuestionnaireDTO | null
	questionnaireStarted: boolean
	currentQuestionIndex: number | undefined
	startQuestionnaire: () => Promise<void>
	selectAnswer: (answerIndex: number) => Promise<void>
	confirmAnswer: () => Promise<void>
	goToPreviousQuestion: () => void
	quitQuestionnaire: () => void
}

export default function View({
	questionnaire,
	questionnaireStarted,
	currentQuestionIndex,
	startQuestionnaire,
	selectAnswer,
	confirmAnswer,
	goToPreviousQuestion,
	quitQuestionnaire,
}: Props) {
	const content = useMemo(() => {
		if (questionnaire === null)
			return <Instructions startQuestionnaire={startQuestionnaire} />
		else
			return (
				<Questionnaire
					questionnaire={questionnaire}
					currentQuestionIndex={currentQuestionIndex}
					selectAnswer={selectAnswer}
					confirmAnswer={confirmAnswer}
					goToPreviousQuestion={goToPreviousQuestion}
					quitQuestionnaire={quitQuestionnaire}
				/>
			)
	}, [
		questionnaire,
		currentQuestionIndex,
		startQuestionnaire,
		selectAnswer,
		confirmAnswer,
		goToPreviousQuestion,
		quitQuestionnaire,
	])

	return (
		<Modal
			isOpen={questionnaireStarted}
			maximized={!!questionnaire}
			close={quitQuestionnaire}
		>
			{content}
		</Modal>
	)
}

import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Box, Paper, useTheme, useMediaQuery } from '@mui/material'

import SnapshotDTO from 'domain/Diagnostic/WellBeingSnapshot/Snapshot/interfaces/SnapshotDTO'

interface Props {
	snapshot: SnapshotDTO | null
}

export default function View({ snapshot }: Props) {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const timeSinceLastRefresh = useMemo(() => {
		if (snapshot === null) return null

		const now = new Date()
		const diffInMilliseconds =
			now.getTime() - snapshot.creationDate.getTime()

		return {
			seconds: Math.floor(diffInMilliseconds / 1000),
			minutes: Math.floor(diffInMilliseconds / 1000 / 60),
			hours: Math.floor(diffInMilliseconds / 1000 / 60 / 60),
			days: Math.floor(diffInMilliseconds / 1000 / 60 / 60 / 24),
			months: Math.floor(diffInMilliseconds / 1000 / 60 / 60 / 24 / 30),
			years: Math.floor(
				diffInMilliseconds / 1000 / 60 / 60 / 24 / 365.25
			),
		}
	}, [snapshot])

	const lastUpdateLabel = useMemo(() => {
		const time = timeSinceLastRefresh
		if (time === null) return 'Mis à jour non-datée'

		if (time.years >= 1) {
			if (time.years === 1) return `Mis à jour il y a 1an`
			else return `Mis à jour il y a ${time.years}an`
		} else if (time.months >= 1) {
			if (time.months === 1) return `Mis à jour il y a 1 mois`
			else return `Mis à jour il y a ${time.months} mois`
		} else if (time.days >= 1) {
			if (time.days === 1) return `Mis à jour il y a 1 jour`
			else return `Mis à jour il y a ${time.days} jours`
		} else if (time.hours >= 1) {
			if (time.hours === 1) return `Mis à jour il y a 1 heure`
			else return `Mis à jour il y a ${time.hours} heures`
		} else if (time.minutes >= 1) {
			if (time.minutes === 1) return `Mis à jour il y a 1 minute`
			else return `Mis à jour il y a ${time.minutes} minutes`
		} else return `Mis à jour à l'instant`
	}, [timeSinceLastRefresh])

	if (snapshot === null) return null

	return isMobile ? (
		<Link to='/etoile-de-vie'>
			<Paper
				sx={{
					background: theme.palette.primary.main,
					padding: '16px 16px 16px 20px',
					height: 'fit-content',
					width: '100%',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						width: '100%',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							flex: '1 0 0',
							justifyContent: 'center',
							gap: '4px',
						}}
					>
						<h3
							style={{
								color: theme.palette.primary.contrastText,
								marginBottom: '0',
							}}
						>
							Niveau de bien-être
						</h3>
						<p
							style={{
								color: '#ffffff',
							}}
						>
							{lastUpdateLabel}
						</p>
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flex: '0 0 fit-content',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: 68,
								width: 68,
								borderRadius: '50%',
								backgroundColor: 'rgba(255, 255, 255, 0.15);',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									height: 58,
									width: 58,
									borderRadius: '50%',
									backgroundColor:
										'rgba(255, 255, 255, 0.25);',
								}}
							>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										height: 48,
										width: 48,
										borderRadius: '50%',
										backgroundColor:
											'rgba(255, 255, 255, 0.9);',
										fontWeight: '700',
										fontSize: '1rem',
										color: '#292827',
									}}
								>
									{snapshot.totalScore}%
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Paper>
		</Link>
	) : (
		<Link to='/etoile-de-vie'>
			<Paper
				sx={{
					display: 'flex',
					flexDirection: 'column',
					borderRadius: '8px',
					padding: '24px',
					gap: '16px',
					height: '100%',
					width: '100%',
					justifyContent: 'center',
					alignItems: 'center',
					background: theme.palette.primary.main,
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flex: '0 0 fit-content',
						height: '100%',
						width: '100%',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '132px',
							width: '132px',
							borderRadius: '50%',
							backgroundColor: 'rgba(255, 255, 255, 0.25);',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '116px',
								width: '116px',
								borderRadius: '50%',
								backgroundColor: 'rgba(255, 255, 255, 0.50);',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									height: '96px',
									width: '96px',
									borderRadius: '50%',
									backgroundColor: 'rgba(255, 255, 255, 1);',
									fontWeight: '700',
									fontSize: '2rem',
								}}
							>
								{snapshot.totalScore}%
							</Box>
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						gap: '4px',
						height: 'fit-content',
						width: '100%',
					}}
				>
					<h3
						style={{
							color: theme.palette.primary.contrastText,
							margin: '0px',
							textAlign: 'center',
						}}
					>
						Niveau de bien-être
					</h3>
					<p
						style={{
							color: '#f0f0f0',
							fontSize: '1rem',
							textAlign: 'center',
						}}
					>
						{lastUpdateLabel}
					</p>
				</Box>
			</Paper>
		</Link>
	)
}

interface IResults {
	access_token: string;
	refresh_token: string;
	expires_in: string;
	token_type: string;
	type: string;
    error: string;
}


const parseRequestFromUrl = (url:string):IResults => {
    let query = url.slice(1);
    let result: IResults = {
        access_token: "",
        refresh_token: "",
        expires_in: "",
        token_type: "",
        type: "",
        error:"",
      };
      
      query.split("&").forEach((part) => {
          const item = part.split("=");
          result[item[0] as keyof IResults] = decodeURIComponent(item[1]);
      });

      return result
}

export default parseRequestFromUrl
import { SvgIcon, SvgIconProps } from '@mui/material'

export default function HomeIconActive(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<svg
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M19.9998 11.9072V17.9997C19.9998 19.1042 19.1044 19.9997 17.9998 19.9997H14V16.9997C14 16.4474 13.5523 15.9997 13 15.9997H11C10.4477 15.9997 10 16.4474 10 16.9997V19.9997H6C4.89543 19.9997 4 19.1042 4 17.9997V11.9072C4 11.3305 4.24896 10.7818 4.68299 10.4021L10.683 5.15207C11.437 4.49227 12.563 4.49228 13.317 5.15209L19.3168 10.4021C19.7508 10.7818 19.9998 11.3305 19.9998 11.9072Z'
					className='iconfill'
				/>
			</svg>
		</SvgIcon>
	)
}

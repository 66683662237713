import AuthInteractor from 'domain/Auth/core/AuthInteractor'
import CoachingInteractor from 'domain/Support/PerspectiveSolver/core/CoachingInteractor'
import SnapshotInteractor from 'domain/Diagnostic/WellBeingSnapshot/Snapshot/core/SnapshotInteractor'
import SnapshotQuestionnaireInteractor from 'domain/Diagnostic/WellBeingSnapshot/Questionnaire/core/interactors/QuestionnaireInteractor'
import SnapshotCategoriesQuestionnaireInteractor from 'domain/Diagnostic/WellBeingSnapshot/CategoriesQuestionnaire/core/CategoriesQuestionnaireInteractor'
import ProblematicInteractor from 'domain/Diagnostic/Problematic/core/ProblematicInteractor'

import LocalStorageCoachingRepository from 'domain/Support/PerspectiveSolver/core/LocalStorageCoachingRepository'
import SupabaseAuthRepository from 'domain/Auth/core/SupabaseAuthRepository'
import LocalStorageSnapshotRepository from 'domain/Diagnostic/WellBeingSnapshot/Snapshot/core/LocalStorageSnapshotRepository'
import LocalStorageSnapshotQuestionnaireRepository from 'domain/Diagnostic/WellBeingSnapshot/Questionnaire/core/data-access/LocalStorageQuestionnaireRepository'
import LocalStorageSnapshotCategoriesQuestionnaireRepository from 'domain/Diagnostic/WellBeingSnapshot/CategoriesQuestionnaire/core/data-access/LocalStorageCategoriesQuestionnaireRepository'
import LocalStorageProblematicRepository from 'domain/Diagnostic/Problematic/core/LocalStorageProblematicRepository'

/**
 * Repositories
 */

const authRepository = new SupabaseAuthRepository()
const coachingRepository = new LocalStorageCoachingRepository()
const snapshotRepository = new LocalStorageSnapshotRepository()
const snapshotQuestionnaireRepository =
	new LocalStorageSnapshotQuestionnaireRepository()
const snapshotCategoriesQuestionnaireRepository =
	new LocalStorageSnapshotCategoriesQuestionnaireRepository()
const problematicRepository = new LocalStorageProblematicRepository()

/**
 * Interactors
 */

const authInteractor = new AuthInteractor(authRepository)
const coachingInteractor = new CoachingInteractor(coachingRepository)

const snapshotInteractor = new SnapshotInteractor(snapshotRepository)

const snapshotQuestionnaireInteractor = new SnapshotQuestionnaireInteractor(
	snapshotQuestionnaireRepository,
	snapshotInteractor
)

const problematicInteractor = new ProblematicInteractor(
	problematicRepository,
	coachingRepository
)

const snapshotCategoriesQuestionnaireInteractor =
	new SnapshotCategoriesQuestionnaireInteractor(
		snapshotCategoriesQuestionnaireRepository,
		problematicInteractor
	)

/**
 * Accumulator
 */

const interactors = {
	authInteractor,
	coachingInteractor,
	diagnostic: {
		snapshotInteractor,
		snapshotQuestionnaireInteractor,
		snapshotCategoriesQuestionnaireInteractor,
		problematicInteractor,
	},
}

export default function useInteractors() {
	return interactors
}

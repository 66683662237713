import { nanoid } from 'nanoid'

import SnapshotDTO from 'domain/Diagnostic/WellBeingSnapshot/Snapshot/interfaces/SnapshotDTO'

import QuestionnaireStatus from './QuestionnaireStatus'
import Question from './Question'
import Category from './Category'
import Weight from './Weight'
import CategoryWithScore from '../../../Snapshot/core/entities/CategoryWithScore'

class Questionnaire {
	status: QuestionnaireStatus
	questions: Array<Question>
	answersCategories: Array<Category>
	weightScale: Array<Weight>

	constructor(
		questions: Array<Question>,
		answersCategories: Array<Category>,
		weightScale: Array<Weight>
	) {
		this.status = QuestionnaireStatus.HasNoAnswers
		this.questions = questions
		this.answersCategories = answersCategories
		this.weightScale = weightScale
		this.refreshStatus()
	}

	refreshStatus = () => {
		const questionsNumber = this.questions.length
		let questionsNotAnsweredCounter = 0

		for (let i = 0; i < questionsNumber; i++) {
			const question = this.questions[i]

			if (question.selectedAnswer === undefined)
				questionsNotAnsweredCounter++
		}

		if (questionsNotAnsweredCounter === questionsNumber)
			this.status = QuestionnaireStatus.HasNoAnswers
		else if (questionsNotAnsweredCounter === 0)
			this.status = QuestionnaireStatus.IsCompleted
		else this.status = QuestionnaireStatus.IsPartiallyCompleted
	}

	isConfirmable = () => {
		return this.status === QuestionnaireStatus.IsCompleted
	}

	answerQuestion = async (questionIndex: number, answerIndex: number) => {
		// TODO: better checks
		this.questions[questionIndex].selectedAnswer = answerIndex
		this.refreshStatus()
	}

	confirm = async () => {
		if (this.status !== QuestionnaireStatus.IsCompleted)
			throw Error('questionnaire cannot be confirmed when not completed')

		const tmpCategoriesWithScores: Array<CategoryWithScore> = []
		const maxScoresResult: Array<CategoryWithScore> = []

		// Create list of categories that will be scored
		for (let i = 0; i < this.answersCategories.length; i++) {
			const category = this.answersCategories[i]

			tmpCategoriesWithScores.push({
				id: category.id,
				label: category.label,
				score: 0,
			})

			maxScoresResult.push({
				id: category.id,
				label: category.label,
				score: 0,
			})
		}

		for (let i = 0; i < this.questions.length; i++) {
			const question = this.questions[i]

			if (question.selectedAnswer === undefined) continue

			for (let y = 0; y < question.categoriesWeights.length; y++) {
				const categoryWeight = question.categoriesWeights[y]
				const categoryWithScore = tmpCategoriesWithScores.find(
					(categoryWithScore) =>
						categoryWeight.categoryId === categoryWithScore.id
				)

				if (categoryWithScore === undefined) continue

				const categoryWithMaximumScore = maxScoresResult.find(
					(categoryWithMaximumScore) =>
						categoryWeight.categoryId ===
						categoryWithMaximumScore.id
				)

				if (categoryWithMaximumScore === undefined) continue

				const weight = this.weightScale.find(
					(weight) => weight.code === categoryWeight.weightCode
				)
				if (weight === undefined) continue

				let scoreRatio = 0
				if (question.selectedAnswer === 0)
					scoreRatio = question.revertScoreRatio ? 1 : 0
				else if (question.selectedAnswer === 1)
					scoreRatio = question.revertScoreRatio ? 0.9 : 0.22
				else if (question.selectedAnswer === 2) scoreRatio = 0.5
				else if (question.selectedAnswer === 3)
					scoreRatio = question.revertScoreRatio ? 0.22 : 0.9
				else scoreRatio = question.revertScoreRatio ? 0 : 1

				categoryWithScore.score += weight.value * scoreRatio
				categoryWithMaximumScore.score += weight.value
			}
		}

		// Give a note on 100 according to score and maximum score
		for (let i = 0; i < tmpCategoriesWithScores.length; i++) {
			const categoryWithScore = tmpCategoriesWithScores[i]
			const categoryWithMaximumScore = maxScoresResult[i]

			if (categoryWithMaximumScore.score <= 0) {
				categoryWithScore.score = 100
			} else {
				categoryWithScore.score = Math.floor(
					(categoryWithScore.score / categoryWithMaximumScore.score) *
						100
				)
			}
		}

		let totalScore = 100
		if (tmpCategoriesWithScores.length > 0) {
			let tmpScoreSum = 0
			for (let i = 0; i < tmpCategoriesWithScores.length; i++) {
				const categoryWithScore: CategoryWithScore =
					tmpCategoriesWithScores[i]
				tmpScoreSum += categoryWithScore.score
			}

			totalScore = Math.floor(
				tmpScoreSum / tmpCategoriesWithScores.length
			)
		}

		const snapshotDTO: SnapshotDTO = {
			id: nanoid(),
			categoriesWithScores: tmpCategoriesWithScores,
			totalScore: totalScore,
			creationDate: new Date(),
		}

		return snapshotDTO
	}
}

export default Questionnaire

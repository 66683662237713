import Tips from 'domain/Support/PerspectiveSolver/core/tips/Tips'

const tips: Tips = {
	instruction: {
		title: '', //`Comment trouver la pensée la plus agréable ?`,
		pages: [
			{
				text: [
					`Quelle est ma pensée la plus agréable ?`,
					`Connectes-toi à tes <b>ressentis corporels</b> :`,
					`- Laquelle de ces pensées t’attire le plus ?
- Laquelle te permet de te sentir mieux ?`,
					`Si le choix n’est pas facile car plusieurs pensées te font du bien, prends l'une d'entre elles sans trop réfléchir.`,
					`Le but est simplement d'en choisir une pour <b>renforcer la transformation</b>. La positivité des autres pensées continuera de t'accompagner !`,
				],
				confirmLabel: `Je choisis ma pensée la plus agréable`,
			},
		],
	},
}

export default tips

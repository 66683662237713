import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

import useAuth from 'presentation/hooks/useAuth'

import { Copyright } from '../../components/Auth/Copyright'
import { PasswordRequirements } from '../../components/Auth/PasswordRequirements'
import { schema } from './lib/password-validator'

export default function PasswordRecovery() {
	const navigate = useNavigate()
	const [password, setPassword] = useState('')

	const { updatePassword } = useAuth()

	async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault()

		let formData = new FormData(event.currentTarget)
		let password = formData.get('password') as string
		if (!schema.validate(password)) return

		/*const { data } = */ await updatePassword(password)
		// TODO: Check how to do that
		/*
		if (data) {
			navigate('/login', { replace: true })
		}
		*/
	}

	return (
		<Container component='main' maxWidth='xs'>
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component='h1' variant='h5'>
					Saisissez votre nouveau mot de passe
				</Typography>
				<Box
					component='form'
					onSubmit={handleSubmit}
					noValidate
					sx={{ mt: 1 }}
				>
					<TextField
						margin='normal'
						required
						fullWidth
						name='password'
						onChange={(event) => {
							setPassword(event.target.value)
						}}
						label='Nouveau mot de passe'
						type='password'
						id='password'
						autoComplete='current-password'
					/>
					<PasswordRequirements password={password} />
					<Button
						type='submit'
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2 }}
					>
						Modifier mon mot de passe
					</Button>
					<Link
						href='#'
						variant='body2'
						onClick={() => {
							navigate('/login')
						}}
					>
						Se connecter
					</Link>
				</Box>
			</Box>
			<Copyright sx={{ mt: 8, mb: 4 }} />
		</Container>
	)
}

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { produce } from 'immer'

import SnapshotDTO from 'domain/Diagnostic/WellBeingSnapshot/Snapshot/interfaces/SnapshotDTO'
import QuestionnaireDTO from 'domain/Diagnostic/WellBeingSnapshot/Questionnaire/interfaces/data-transfer-objects/QuestionnaireDTO'
import CategoriesQuestionnaire from 'domain/Diagnostic/WellBeingSnapshot/CategoriesQuestionnaire/interfaces/data-transfer-objects/CategoriesQuestionnaireDTO'

import useInteractors from 'presentation/hooks/useInteractors'

import CompactLayout from './CompactLayout'
import StandardLayout from './StandardLayout'

import NoSnapshotModal from './NoSnapshotModal/View'
import SnapshotRecapModal from './SnapshotRecapModal/View'
import SnapshotQuestionnaireModal from './SnapshotQuestionnaireModal/View'
import CategoriesQuestionnaireModal from './CategoriesQuestionnaireModal/View'

interface State {
	initializedMostRecentSnapshot: boolean
	mostRecentSnapshot: SnapshotDTO | null

	initializedSnapshotQuestionnaire: boolean
	snapshotQuestionnaire: QuestionnaireDTO | null

	initializedSnapshotCategoriesQuestionnaire: boolean
	snapshotCategoriesQuestionnaire: CategoriesQuestionnaire | null

	questionnaireStarted: boolean
	currentQuestionIndex: number | undefined
	snapshotRecapShown: boolean

	categoriesQuestionnaireStarted: boolean
	currentCategoriesQuestionIndex: number | undefined
}

export default function View() {
	/**
	 * Base
	 */

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const {
		diagnostic: {
			snapshotInteractor,
			snapshotQuestionnaireInteractor,
			snapshotCategoriesQuestionnaireInteractor,
		},
	} = useInteractors()

	/**
	 * States
	 */

	const [state, setState] = useState<State>({
		initializedMostRecentSnapshot: false,
		mostRecentSnapshot: null,

		initializedSnapshotQuestionnaire: false,
		snapshotQuestionnaire: null,

		initializedSnapshotCategoriesQuestionnaire: false,
		snapshotCategoriesQuestionnaire: null,

		questionnaireStarted: false,
		currentQuestionIndex: undefined,
		snapshotRecapShown: false,

		categoriesQuestionnaireStarted: false,
		currentCategoriesQuestionIndex: undefined,
	})

	/**
	 * Refs
	 */

	const previousQuestionnaireState = useRef<QuestionnaireDTO | null>(
		state.snapshotQuestionnaire
	)

	const previousCategoriesQuestionnaireState =
		useRef<CategoriesQuestionnaire | null>(
			state.snapshotCategoriesQuestionnaire
		)

	/**
	 * Callback
	 */

	// Initialization

	const initializeMostRecentSnapshot = useCallback(async () => {
		const snapshot = await snapshotInteractor.getMostRecent()

		setState((state) => {
			return produce(state, (state) => {
				state.initializedMostRecentSnapshot = true
				state.mostRecentSnapshot = snapshot
			})
		})
	}, [snapshotInteractor])

	const initializeSnapshotQuestionnaire = useCallback(async () => {
		const snapshotQuestionnaire =
			await snapshotQuestionnaireInteractor.getCurrent()

		setState((state) => {
			return produce(state, (state) => {
				state.initializedSnapshotQuestionnaire = true
				state.snapshotQuestionnaire = snapshotQuestionnaire
			})
		})
	}, [snapshotQuestionnaireInteractor])

	const initializeSnapshotCategoriesQuestionnaire = useCallback(async () => {
		const categoriesQuestionnaire =
			await snapshotCategoriesQuestionnaireInteractor.getCurrent()

		setState((state) => {
			return produce(state, (state) => {
				state.initializedSnapshotCategoriesQuestionnaire = true
				state.snapshotCategoriesQuestionnaire = categoriesQuestionnaire
			})
		})
	}, [snapshotCategoriesQuestionnaireInteractor])

	// Effects Callbacks

	const onQuestionnaireChange = useCallback(() => {
		if (
			previousQuestionnaireState.current === null &&
			state.snapshotQuestionnaire !== null
		) {
			let currentQuestionIndex = 0
			for (
				let i = 0;
				i < state.snapshotQuestionnaire.questions.length;
				i++
			) {
				const question = state.snapshotQuestionnaire.questions[i]

				if (question.selectedAnswer === undefined) break

				currentQuestionIndex = i
			}

			setState((state) => {
				return produce(state, (state) => {
					state.currentQuestionIndex = currentQuestionIndex
				})
			})
		}

		previousQuestionnaireState.current = state.snapshotQuestionnaire
	}, [state])

	const onCategoriesQuestionnaireChange = useCallback(() => {
		if (
			previousCategoriesQuestionnaireState.current === null &&
			state.snapshotCategoriesQuestionnaire !== null &&
			state.snapshotCategoriesQuestionnaire.questions.length > 0
		) {
			let currentQuestionIndex = 0
			for (
				let i = 0;
				i < state.snapshotCategoriesQuestionnaire.questions.length;
				i++
			) {
				const question =
					state.snapshotCategoriesQuestionnaire.questions[i]

				if (question.answer === undefined) break

				currentQuestionIndex = i
			}

			setState((state) => {
				return produce(state, (state) => {
					state.currentCategoriesQuestionIndex = currentQuestionIndex
				})
			})
		}

		previousCategoriesQuestionnaireState.current =
			state.snapshotCategoriesQuestionnaire
	}, [state])

	// Questionnaire Callbacks

	const showQuestionnaireStartInstructions = useCallback(() => {
		setState((state) => {
			return produce(state, (state) => {
				state.questionnaireStarted = true
			})
		})
	}, [])

	const startQuestionnaire = useCallback(async () => {
		const questionnaire = await snapshotQuestionnaireInteractor.create()

		setState((state) => {
			return produce(state, (state) => {
				state.snapshotQuestionnaire = questionnaire
			})
		})
	}, [snapshotQuestionnaireInteractor])

	const selectAnswer = useCallback(
		async (answerIndex: number) => {
			if (
				state.snapshotQuestionnaire === null ||
				state.currentQuestionIndex === undefined
			) {
				return
			}

			if (
				answerIndex < 0 ||
				answerIndex >=
					state.snapshotQuestionnaire.questions[
						state.currentQuestionIndex
					].answers.length
			)
				return

			const questionnaire =
				await snapshotQuestionnaireInteractor.selectAnswer(
					state.currentQuestionIndex,
					answerIndex
				)

			setState((state) => {
				return produce(state, (state) => {
					state.snapshotQuestionnaire = questionnaire
				})
			})
		},
		[snapshotQuestionnaireInteractor, state]
	)

	const goToPreviousQuestion = useCallback(() => {
		if (
			state.currentQuestionIndex === undefined ||
			state.currentQuestionIndex === 0
		)
			return

		setState((state) => {
			return produce(state, (state) => {
				if (
					state.currentQuestionIndex === undefined ||
					state.currentQuestionIndex === 0
				)
					return

				state.currentQuestionIndex = state.currentQuestionIndex - 1
			})
		})
	}, [state])

	const goToNextQuestion = useCallback(() => {
		if (
			state.snapshotQuestionnaire === null ||
			state.currentQuestionIndex === undefined ||
			state.snapshotQuestionnaire.questions.length <=
				state.currentQuestionIndex + 1 ||
			state.snapshotQuestionnaire.questions[state.currentQuestionIndex]
				.selectedAnswer === undefined
		) {
			return
		}

		setState((state) => {
			return produce(state, (state) => {
				if (
					state.snapshotQuestionnaire === null ||
					state.currentQuestionIndex === undefined ||
					state.snapshotQuestionnaire.questions.length <=
						state.currentQuestionIndex + 1
				) {
					return
				}

				state.currentQuestionIndex = state.currentQuestionIndex + 1
			})
		})
	}, [state])

	const confirmAnswer = useCallback(async () => {
		if (
			state.currentQuestionIndex === undefined ||
			state.snapshotQuestionnaire === null
		)
			return

		if (
			state.currentQuestionIndex <
			state.snapshotQuestionnaire.questions.length - 1
		) {
			goToNextQuestion()
		} else {
			const snapshot =
				await snapshotQuestionnaireInteractor.confirmQuestionnaire()

			setState((state) => {
				return produce(state, (state) => {
					state.mostRecentSnapshot = snapshot
					state.snapshotQuestionnaire = null
					state.questionnaireStarted = false
					state.currentQuestionIndex = undefined
					state.snapshotRecapShown = true
				})
			})
		}
	}, [state, goToNextQuestion, snapshotQuestionnaireInteractor])

	const quitQuestionnaire = useCallback(async () => {
		setState((state) => {
			return produce(state, (state) => {
				state.questionnaireStarted = false
			})
		})
	}, [])

	const closeSnapshotRecap = useCallback(() => {
		setState((state) => {
			return produce(state, (state) => {
				state.snapshotRecapShown = false
			})
		})
	}, [])

	// Categories Questionnaire Callbacks
	const startCategoriesQuestionnaire = useCallback(async () => {
		if (state.snapshotCategoriesQuestionnaire === null) {
			const questionnaire =
				await snapshotCategoriesQuestionnaireInteractor.create()

			setState((state) => {
				return produce(state, (state) => {
					state.snapshotCategoriesQuestionnaire = questionnaire
					state.categoriesQuestionnaireStarted = true
				})
			})
		} else {
			setState((state) => {
				return produce(state, (state) => {
					state.categoriesQuestionnaireStarted = true
				})
			})
		}
	}, [
		state.snapshotCategoriesQuestionnaire,
		snapshotCategoriesQuestionnaireInteractor,
	])

	const toggleCategoriesQuestionnaireCategory = useCallback(
		async (categoryId: string) => {
			const questionnaire =
				await snapshotCategoriesQuestionnaireInteractor.toggleCategory(
					categoryId
				)

			setState((state) => {
				return produce(state, (state) => {
					state.snapshotCategoriesQuestionnaire = questionnaire
				})
			})
		},
		[snapshotCategoriesQuestionnaireInteractor]
	)

	const toggleCategoriesQuestionnaireAnswerSelection = useCallback(
		async (questionId: string) => {
			const questionnaire =
				await snapshotCategoriesQuestionnaireInteractor.toggleAnswerSelection(
					questionId
				)

			setState((state) => {
				return produce(state, (state) => {
					state.snapshotCategoriesQuestionnaire = questionnaire
				})
			})
		},
		[snapshotCategoriesQuestionnaireInteractor]
	)

	const setCategoriesQuestionnaireAnswer = useCallback(
		async (answer: string) => {
			if (state.currentCategoriesQuestionIndex === undefined) return

			const questionnaire =
				await snapshotCategoriesQuestionnaireInteractor.setAnswer(
					state.currentCategoriesQuestionIndex,
					answer
				)

			setState((state) => {
				return produce(state, (state) => {
					state.snapshotCategoriesQuestionnaire = questionnaire
				})
			})
		},
		[
			snapshotCategoriesQuestionnaireInteractor,
			state.currentCategoriesQuestionIndex,
		]
	)

	const goToPreviousCategoriesQuestion = useCallback(() => {
		if (state.currentCategoriesQuestionIndex === undefined) return

		setState((state) => {
			return produce(state, (state) => {
				if (state.currentCategoriesQuestionIndex === undefined) return
				if (state.currentCategoriesQuestionIndex === 0)
					state.currentCategoriesQuestionIndex = undefined
				else
					state.currentCategoriesQuestionIndex =
						state.currentCategoriesQuestionIndex - 1
			})
		})
	}, [state])

	const goToNextCategoriesQuestion = useCallback(() => {
		if (
			state.snapshotCategoriesQuestionnaire !== null &&
			state.currentCategoriesQuestionIndex !== undefined &&
			state.currentCategoriesQuestionIndex + 1 >=
				state.snapshotCategoriesQuestionnaire.questions.length
		) {
			return
		}

		setState((state) => {
			return produce(state, (state) => {
				if (state.currentCategoriesQuestionIndex !== undefined)
					state.currentCategoriesQuestionIndex += 1
			})
		})
	}, [state])

	const confirmCategoriesQuestionAnswer = useCallback(async () => {
		if (state.snapshotCategoriesQuestionnaire === null) return

		if (
			state.currentCategoriesQuestionIndex === undefined &&
			state.snapshotCategoriesQuestionnaire.selectedCategories.length > 0
		) {
			setState((state) => {
				return produce(state, (state) => {
					state.currentCategoriesQuestionIndex = 0
				})
			})
		} else if (
			state.currentCategoriesQuestionIndex !== undefined &&
			state.currentCategoriesQuestionIndex + 1 <
				state.snapshotCategoriesQuestionnaire.questions.length
		) {
			goToNextCategoriesQuestion()
		} else if (
			state.currentCategoriesQuestionIndex !== undefined &&
			state.currentCategoriesQuestionIndex + 1 ===
				state.snapshotCategoriesQuestionnaire.questions.length
		) {
			setState((state) => {
				return produce(state, (state) => {
					if (
						state.currentCategoriesQuestionIndex !== undefined &&
						state.snapshotCategoriesQuestionnaire !== null
					) {
						state.currentCategoriesQuestionIndex =
							state.snapshotCategoriesQuestionnaire.questions.length
					}
				})
			})
		} else {
			await snapshotCategoriesQuestionnaireInteractor.confirmQuestionnaire()

			setState((state) => {
				return produce(state, (state) => {
					state.snapshotCategoriesQuestionnaire = null
					state.categoriesQuestionnaireStarted = false
					state.currentCategoriesQuestionIndex = undefined
				})
			})
		}
	}, [
		state,
		goToNextCategoriesQuestion,
		snapshotCategoriesQuestionnaireInteractor,
	])

	const quitCategoriesQuestionnaire = useCallback(async () => {
		setState((state) => {
			return produce(state, (state) => {
				state.categoriesQuestionnaireStarted = false
			})
		})
	}, [])

	/**
	 * Effects
	 */

	useEffect(() => {
		initializeMostRecentSnapshot()
	}, [initializeMostRecentSnapshot])

	useEffect(() => {
		initializeSnapshotQuestionnaire()
	}, [initializeSnapshotQuestionnaire])

	useEffect(() => {
		initializeSnapshotCategoriesQuestionnaire()
	}, [initializeSnapshotCategoriesQuestionnaire])

	useEffect(() => {
		onQuestionnaireChange()
	}, [onQuestionnaireChange])

	useEffect(() => {
		onCategoriesQuestionnaireChange()
	}, [onCategoriesQuestionnaireChange])

	/**
	 * Render
	 */

	const loading = useMemo(
		() =>
			state.initializedMostRecentSnapshot === false ||
			state.initializedSnapshotQuestionnaire === false ||
			state.initializedSnapshotCategoriesQuestionnaire === false,
		[state]
	)

	const modalsRender = useMemo(
		() => (
			<>
				<SnapshotQuestionnaireModal
					questionnaire={state.snapshotQuestionnaire}
					questionnaireStarted={state.questionnaireStarted}
					currentQuestionIndex={state.currentQuestionIndex}
					startQuestionnaire={startQuestionnaire}
					selectAnswer={selectAnswer}
					confirmAnswer={confirmAnswer}
					goToPreviousQuestion={goToPreviousQuestion}
					quitQuestionnaire={quitQuestionnaire}
				/>
				<CategoriesQuestionnaireModal
					questionnaire={state.snapshotCategoriesQuestionnaire}
					questionnaireStarted={state.categoriesQuestionnaireStarted}
					currentQuestionIndex={state.currentCategoriesQuestionIndex}
					toggleCategory={toggleCategoriesQuestionnaireCategory}
					toggleAnswerSelection={
						toggleCategoriesQuestionnaireAnswerSelection
					}
					setAnswer={setCategoriesQuestionnaireAnswer}
					confirmAnswer={confirmCategoriesQuestionAnswer}
					goToPreviousQuestion={goToPreviousCategoriesQuestion}
					quitQuestionnaire={quitCategoriesQuestionnaire}
				/>
				<NoSnapshotModal
					snapshot={state.mostRecentSnapshot}
					questionnaire={state.snapshotQuestionnaire}
					questionnaireStarted={state.questionnaireStarted}
					showQuestionnaireStartInstructions={
						showQuestionnaireStartInstructions
					}
				/>
				<SnapshotRecapModal
					opened={state.snapshotRecapShown}
					snapshot={state.mostRecentSnapshot}
					close={closeSnapshotRecap}
				/>
			</>
		),
		[
			state,
			startQuestionnaire,
			selectAnswer,
			confirmAnswer,
			goToPreviousQuestion,
			quitQuestionnaire,
			showQuestionnaireStartInstructions,
			closeSnapshotRecap,
			toggleCategoriesQuestionnaireCategory,
			toggleCategoriesQuestionnaireAnswerSelection,
			goToPreviousCategoriesQuestion,
			quitCategoriesQuestionnaire,
			setCategoriesQuestionnaireAnswer,
			confirmCategoriesQuestionAnswer,
		]
	)

	const layoutRender = useMemo(
		() =>
			isMobile ? (
				<CompactLayout
					loading={loading}
					mostRecentSnapshot={state.mostRecentSnapshot}
					snapshotQuestionnaire={state.snapshotQuestionnaire}
					showQuestionnaireStartInstructions={
						showQuestionnaireStartInstructions
					}
					categoriesQuestionnaire={
						state.snapshotCategoriesQuestionnaire
					}
					startCategoriesQuestionnaire={startCategoriesQuestionnaire}
					modals={modalsRender}
				/>
			) : (
				<StandardLayout
					loading={loading}
					mostRecentSnapshot={state.mostRecentSnapshot}
					snapshotQuestionnaire={state.snapshotQuestionnaire}
					showQuestionnaireStartInstructions={
						showQuestionnaireStartInstructions
					}
					categoriesQuestionnaire={
						state.snapshotCategoriesQuestionnaire
					}
					startCategoriesQuestionnaire={startCategoriesQuestionnaire}
					modals={modalsRender}
				/>
			),
		[
			isMobile,
			loading,
			state,
			showQuestionnaireStartInstructions,
			startCategoriesQuestionnaire,
			modalsRender,
		]
	)

	return layoutRender
}

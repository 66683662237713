import { useCallback } from 'react'
import { produce } from 'immer'
import { Button } from '@mui/material'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'
import CoachingStep from 'domain/Support/PerspectiveSolver/core/CoachingStep'

type ResultInvalidPositiveThoughtsFormProps = {
	situation: CoachingSituation
	updateSituation: (updatedSituation: CoachingSituation) => void
	quitCoaching: () => void
}

function ResultInvalidPositiveThoughtsForm({
	situation,
	updateSituation,
	quitCoaching,
}: ResultInvalidPositiveThoughtsFormProps) {
	/**
	 * Callbacks
	 */

	const confirm = useCallback(() => {
		const updatedSituation = produce(situation, (situation) => {
			situation.currentStep = CoachingStep.PositiveActions
			return situation
		})

		updateSituation(updatedSituation)
	}, [situation, updateSituation])

	/**
	 * Render
	 */

	return (
		<div className='coaching-situation-content'>
			<h3 style={{ flex: '0 0 fit-content' }}>
				Les pensées agréables ne m’ont pas soulagé
			</h3>
			<div style={{ flex: '1 0 0', overflowY: 'auto' }}>
				<p>L'objectif est d’obtenir des pensées qui te font du bien.</p>
				<p>
					<b>Suggestion</b> : pense à une personne qui vit la même
					situation que toi, mais qui ressent des sentiments
					agréables.
				</p>
				<p>
					Pour t’aider à trouver de nouvelles pensées, voici des
					réflexions que tu peux utiliser :
				</p>
				<ul>
					<li>
						Sur quels <b>éléments positifs</b> peux-tu te focaliser
						?
					</li>
					<li>
						Quelles sont les éléments à ta disposition qui peuvent
						te prouver qu’un <b>changement est déjà en cours</b> ?
					</li>
					<li>
						Utilise des pensées telles que “<b>et si</b> c’était
						faux ?” “et si ça se passait bien ?” “et si je faisais
						autrement ?”
					</li>
					<li>
						Poses-toi les questions suivantes : une personne qui
						réussit déjà ce que je souhaite, comment{' '}
						<b>agit-elle</b> ? Que fait-elle de <b>différent</b> ?
						Quelles pensées a-t-elle dans ma situation ?{' '}
						<b>Que me dirait-elle</b> de faire ?
					</li>
				</ul>
				<p>
					En refaisant le coaching avec de nouvelles actions et
					pensées, tu auras de très bon résultats :)
				</p>
				<p>Est-ce que tu souhaites reprendre ta session ?</p>
			</div>

			<div style={{ flex: '0 0 fit-content' }}>
				<Button
					onClick={confirm}
					variant='contained'
					style={{ color: 'white', margin: '8px 0px' }}
					fullWidth
				>
					Oui, c’est parti !
				</Button>
				<Button
					onClick={quitCoaching}
					variant='contained'
					style={{ color: 'white', margin: '8px 0px' }}
					fullWidth
				>
					Non, quitter
				</Button>
			</div>
		</div>
	)
}

export default ResultInvalidPositiveThoughtsForm

import { useMemo } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'

import './styles.css'

type TipsButtonProps = {
	message: string
	onClick: () => void
}

function TipsButton({ message, onClick }: TipsButtonProps) {
	/**
	 * Base
	 */

	const theme = useTheme()
	const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

	/**
	 * Render
	 */

	const buttonClass = useMemo(() => {
		if (smallScreen === false) return 'tips-button'
		else return 'tips-button tips-button-minimized'
	}, [smallScreen])

	return (
		<div className={buttonClass} onClick={onClick}>
			<div className='tips-content'>
				<div className='tips-content-title'>Coach</div>
				<div className='tips-content-text'>{message}</div>
			</div>
			<div className='tips-title-alternative'>
				<div className='tips-title-alternative-text'>
					{message /*Revoir le guide de l'étape*/}
				</div>
			</div>
			<div className='tips-coach'>
				<div className='tips-button-coach-image-container'>
					<img
						className='tips-coach-image'
						src={
							process.env.PUBLIC_URL + '/assets/images/Katia.png'
						}
						alt='Coach'
					/>
				</div>
			</div>
		</div>
	)
}

export default TipsButton

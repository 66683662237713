import Coaching from 'domain/Support/PerspectiveSolver/core/Coaching'

import CoachingRepository from 'domain/Support/PerspectiveSolver/interfaces/CoachingRepository'

import { getCoachings, setCoachings } from 'common/storage-interactor'

class LocalStorageCoachingRepository implements CoachingRepository {
	getCoachings = async () => {
		const coachings: Array<Coaching> = []
		const rawCoachings: any = await getCoachings()

		if (rawCoachings === null) return coachings

		if (Array.isArray(rawCoachings) === false)
			throw Error('Stored coachings format seems corrupted')

		for (let i = 0; i < rawCoachings.length; i++) {
			const rawCoaching = rawCoachings[i]

			const coaching: Coaching = {
				id: rawCoaching.id,
				problematicId: rawCoaching.problematicId,
				situations: rawCoaching.situations,
			}

			coachings.push(coaching)
		}

		return coachings
	}

	get = async (coachingId: string) => {
		const coachings: Array<Coaching> = await this.getCoachings()

		for (let i = 0; i < coachings.length; i++) {
			if (coachings[i].id === coachingId) return coachings[i]
		}

		throw Error(`Failed to get coaching with id ${coachingId}`)
	}

	save = async (coaching: Coaching) => {
		const coachings: Array<Coaching> = await this.getCoachings()

		for (let i = 0; i < coachings.length; i++) {
			if (coachings[i].id === coaching.id) {
				coachings[i] = coaching
				setCoachings(coachings)
				return
			}
		}

		coachings.unshift(coaching)
		setCoachings(coachings)
	}

	remove = async (coachingId: string) => {
		let coachings: Array<Coaching> = await this.getCoachings()

		coachings = coachings.filter((coaching) => coaching.id !== coachingId)

		setCoachings(coachings)
	}
}

export default LocalStorageCoachingRepository

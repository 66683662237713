import CategoriesQuestionnaire from '../../interfaces/data-transfer-objects/CategoriesQuestionnaireDTO'

import CategoriesQuestionnaireRepository from '../../interfaces/CategoriesQuestionnaireRepository'

import {
	getWellBeingSnapshotCategoriesQuestionnaire,
	setWellBeingSnapshotCategoriesQuestionnaire,
	removeWellBeingSnapshotCategoriesQuestionnaire,
} from 'common/storage-interactor'

class LocalStorageCategoriesQuestionnaireRepository
	implements CategoriesQuestionnaireRepository
{
	create = async () => {
		const questionnaire = createQuestionnaireFromMock()
		await this.save(questionnaire)
		return questionnaire
	}

	getCurrent = async () => {
		const rawQuestionnaire: any | null =
			getWellBeingSnapshotCategoriesQuestionnaire()

		if (rawQuestionnaire === null) return null

		try {
			const questionnaire: CategoriesQuestionnaire = {
				selectedCategories: rawQuestionnaire.selectedCategories,
				questions: rawQuestionnaire.questions,
				selectedAnswers: rawQuestionnaire.selectedAnswers,
			}
			return questionnaire
		} catch (error: any) {
			console.error(
				`LocalStorageQuestionnaireRepository ${error?.message}`
			)
			removeWellBeingSnapshotCategoriesQuestionnaire()
			return null
		}
	}

	save = async (questionnaire: CategoriesQuestionnaire) => {
		setWellBeingSnapshotCategoriesQuestionnaire(questionnaire)
	}

	clear = async () => {
		removeWellBeingSnapshotCategoriesQuestionnaire()
	}
}

function createQuestionnaireFromMock() {
	const questionnaire: CategoriesQuestionnaire = {
		selectedCategories: [],
		questions: [],
		selectedAnswers: [],
	}

	return questionnaire
}

export default LocalStorageCategoriesQuestionnaireRepository

import { useCallback, useMemo } from 'react'
import { Button } from '@mui/material'

import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import Diversity3Icon from '@mui/icons-material/Diversity3'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import WorkIcon from '@mui/icons-material/Work'

import CategoryDTO from 'domain/Diagnostic/WellBeingSnapshot/CategoriesQuestionnaire/interfaces/data-transfer-objects/CategoryDTO'

interface Props {
	category: CategoryDTO
	selected: boolean
	toggle: (categoryId: string) => Promise<void>
}

export default function View({ category, selected, toggle }: Props) {
	/**
	 * Callbacks
	 */

	const onToggleCategory = useCallback(async () => {
		await toggle(category.id)
	}, [category.id, toggle])

	/**
	 * Render
	 */

	const backgroundColor = useMemo(() => {
		switch (category.label) {
			case 'Amitié':
				return '#E17F48'
			case 'Amour':
				return '#C54F4F'
			case 'Argent':
				return '#4E8BC5'
			case 'Émotions':
				return '#A05DCA'
			case 'Famille':
				return '#C95478'
			case 'Santé':
				return '#5FA69B'
			case 'Sport':
				return '#48A0BC'
			case 'Travail':
				return '#785FDB'
		}
	}, [category.label])

	const iconRender = useMemo(() => {
		switch (category.label) {
			case 'Amitié':
				return <Diversity3Icon />
			case 'Amour':
				return <FavoriteIcon />
			case 'Argent':
				return <AccountBalanceIcon />
			case 'Émotions':
				return <EmojiEmotionsIcon />
			case 'Famille':
				return <FamilyRestroomIcon />
			case 'Santé':
				return <LocalHospitalIcon />
			case 'Sport':
				return <FitnessCenterIcon />
			case 'Travail':
				return <WorkIcon />
		}
	}, [category.label])

	return (
		<Button
			onClick={onToggleCategory}
			size='small'
			variant='contained'
			color={selected ? 'primary' : 'secondary'}
			startIcon={iconRender}
			style={{
				color: selected ? undefined : '#777777',
				backgroundColor: selected ? backgroundColor : '#F7FAFA',
				boxShadow: 'none',
				borderRadius: '4px',
				padding: '12px 16px',
			}}
		>
			{category.label}
		</Button>
	)
}

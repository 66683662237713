import { useCallback, useMemo } from 'react'
import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import SnapshotDTO from 'domain/Diagnostic/WellBeingSnapshot/Snapshot/interfaces/SnapshotDTO'
import QuestionnaireDTO from 'domain/Diagnostic/WellBeingSnapshot/Questionnaire/interfaces/data-transfer-objects/QuestionnaireDTO'

import Modal from 'presentation/views/components/Modal/View'

interface Props {
	snapshot: SnapshotDTO | null
	questionnaire: QuestionnaireDTO | null

	questionnaireStarted: boolean
	showQuestionnaireStartInstructions: () => void
}

export default function View({
	snapshot,
	questionnaire,

	questionnaireStarted,
	showQuestionnaireStartInstructions,
}: Props) {
	const navigate = useNavigate()

	const onBackToMenu = useCallback(() => {
		navigate('/')
	}, [navigate])

	const isNoSnapshotModalShown = useMemo(() => {
		if (snapshot !== null) return false
		else if (questionnaireStarted) return false

		return true
	}, [snapshot, questionnaireStarted])

	const titleLabel = useMemo(() => {
		if (questionnaire === null)
			return `Tu n'as pas encore fait le test pour afficher l'étoile de vie !`
		else
			return `Tu n'as pas encore fini le test permettant l'affichage de ton étoile de vie !`
	}, [questionnaire])

	const doTestButtonlabel = useMemo(() => {
		if (questionnaire === null) return `Faire le test`
		else return `Reprendre le test`
	}, [questionnaire])

	return (
		<Modal isOpen={isNoSnapshotModalShown}>
			<h3>{titleLabel}</h3>
			<Box
				sx={{
					display: 'flex',
					flexDirection: { xs: 'column', sm: 'row' },
					width: '100%',
					height: 'auto',
					gap: '8px',
					marginTop: '16px',
				}}
			>
				<Button
					variant='contained'
					color='primary'
					onClick={showQuestionnaireStartInstructions}
					sx={{
						flex: '1 0 0',
						height: 'fit-content',
					}}
				>
					{doTestButtonlabel}
				</Button>

				<Button
					variant='contained'
					color='secondary'
					sx={{
						flex: '1 0 0',
						height: 'fit-content',
					}}
					onClick={onBackToMenu}
				>
					Revenir à l'accueil
				</Button>
			</Box>
		</Modal>
	)
}

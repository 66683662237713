import SnapshotDTO from 'domain/Diagnostic/WellBeingSnapshot/Snapshot/interfaces/SnapshotDTO'

import SnapshotRepository from 'domain/Diagnostic/WellBeingSnapshot/Snapshot/interfaces/SnapshotRepository'

import {
	getWellBeingSnapshots,
	setWellBeingSnapshots,
	removeWellBeingSnapshots,
} from 'common/storage-interactor'

class LocalStorageSnapshotRepository implements SnapshotRepository {
	getMostRecent = async () => {
		const snapshots: Array<SnapshotDTO> = await this.getAllSnapshots()
		if (snapshots.length === 0) return null

		const lastSnapshotIndex: number = snapshots.length - 1
		return snapshots[lastSnapshotIndex]
	}

	saveSnapshot = async (snapshot: SnapshotDTO) => {
		const snapshots: Array<SnapshotDTO> = await this.getAllSnapshots()

		let isNew = true
		for (let i = 0; i < snapshots.length; i++) {
			if (snapshot.id === snapshots[i].id) {
				isNew = false
				snapshots[i] = snapshot
			}
		}

		if (isNew === true) snapshots.push(snapshot)

		setWellBeingSnapshots(snapshots)
	}

	private getAllSnapshots = async () => {
		const rawSnapshots: any | null = getWellBeingSnapshots()

		if (rawSnapshots === null) return new Array<SnapshotDTO>()

		try {
			const snapshots: Array<SnapshotDTO> = []

			if (Array.isArray(rawSnapshots) === false) throw Error('bad format')

			for (let i = 0; i < rawSnapshots.length; i++) {
				const rawSnapshot = rawSnapshots[i]

				const snapshot: SnapshotDTO = {
					id: rawSnapshot.id,
					categoriesWithScores: rawSnapshot.categoriesWithScores,
					totalScore: rawSnapshot.totalScore,
					creationDate: new Date(rawSnapshot.creationDate),
				}

				snapshots.push(snapshot)
			}

			return snapshots
		} catch (error: any) {
			console.error(`LocalStorageSnapshotRepository ${error?.message}`)
			removeWellBeingSnapshots()
			return new Array<SnapshotDTO>()
		}
	}
}

export default LocalStorageSnapshotRepository

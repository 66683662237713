import { MobileStepper } from '@mui/material'

type StepperProps = {
	stepsNumber: number
	activeStep: number
}

function Stepper({ stepsNumber, activeStep }: StepperProps) {
	return (
		<MobileStepper
			variant='dots'
			position='static'
			steps={stepsNumber}
			activeStep={activeStep}
			nextButton={<div />}
			backButton={<div />}
			sx={{
				backgroundColor: 'rgba(0, 0, 0, 0)',
				maxWidth: 400,
				flexGrow: 1,
			}}
		/>
	)
}

export default Stepper

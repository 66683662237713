import Tips from 'domain/Support/PerspectiveSolver/core/tips/Tips'

const tips: Tips = {
	instruction: {
		title: '', //`Comment identifier la pensée la plus désagréable ?`,
		pages: [
			{
				text: [
					`Ici, tu vas choisir la pensée qui te semble la plus désagréable sur l’instant.`,
					`Si ce choix te semble impossible, commence par l’une des plus fortes.`,
					`Si les autres pensées étaient aussi à transformer, nous te le proposerons dans les étapes suivantes.`,
				],
				confirmLabel: `J'identifie la pensée la plus négative`,
			},
		],
	},
}

export default tips

import Tips from 'domain/Support/PerspectiveSolver/core/tips/Tips'

const tips: Tips = {
	instruction: {
		title: '', //`Commençons par formuler ta problématique pour qu’elle soit factuelle.`,
		pages: [
			{
				text: [
					`Comment décrire ma situation de manière neutre ?`,
					`Une situation est factuelle lorsque tout le monde peut se mettre d’accord, car elle est <b>neutre</b> et <b>sans aucune émotion.</b>`,
					`Par exemple :`,
					`✅ J’ai une décision à prendre
✅ Je commence mon nouveau travail dans une semaine
✅ Mon employeur me demande de finir un projet en 24h`,
					`❌ J’ai peur de regretter ma décision : il y a une émotion + un avis personnel
❌Je n’ai pas confiance en mes capacités : il y a une sensation + une formulation négative
❌Je ne supporte pas l’autorité : formulation négative + un avis personnel`,
					`Te voilà maintenant prêt·e à rendre ta situation factuelle.`,
				],
				confirmLabel: `J'écris ma situation factuelle`,
			},
		],
	},
}

export default tips

import { Box, ListItemText, useTheme } from '@mui/material'

type ListItemTextWithNumberProps = {
	index: number
	text: string
	selected: boolean
}

export default function View({
	index,
	text,
	selected,
}: ListItemTextWithNumberProps) {
	const theme = useTheme()

	/**
	 * Render
	 */

	return (
		<ListItemText
			primary={
				<Box
					sx={{
						display: 'flex',
						alignItems: 'stretch',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flex: '0 0 fit-content',
							alignItems: 'center',
							justifyContent: 'center',
							minHeight: '28px',
							minWidth: '28px',
							borderRadius: '2px',
							backgroundColor: selected
								? theme.palette.primary.main
								: '#f5f5f5',
							color: selected
								? theme.palette.primary.contrastText
								: theme.palette.text.primary,
							fontSize: '0.875rem',
						}}
					>
						{index + 1}
					</Box>
					<Box
						sx={{
							display: 'flex',
							flex: '1 0',
							paddingLeft: '16px',
							color: '#292827',
							fontSize: '0.875rem',
							alignItems: 'center',
						}}
					>
						{text}
					</Box>
				</Box>
			}
		/>
	)
}

import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
	Box,
	Grid,
	Link,
	Avatar,
	Button,
	Container,
	TextField,
	IconButton,
	Typography,
	InputLabel,
	FormControl,
	CssBaseline,
	OutlinedInput,
	InputAdornment,
	CircularProgress,
} from '@mui/material'

import { LockOutlined, Visibility, VisibilityOff } from '@mui/icons-material'

import AuthError from 'domain/Auth/interfaces/AuthError'

import useAuth from 'presentation/hooks/useAuth'

import { Copyright } from '../../components/Auth/Copyright'

export default function SignIn() {
	const location = useLocation()
	const navigate = useNavigate()

	const { authUser, login } = useAuth()

	const [message, setMessage] = useState<string>('')
	const [showPassword, setShowPassword] = useState(false)

	const from = location.state?.from?.pathname || '/'

	const handleClickShowPassword = () => setShowPassword((show) => !show)

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault()
	}

	async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault()
		setMessage('loading')

		const formData = new FormData(event.currentTarget)
		const username = formData.get('email') as string
		const password = formData.get('password') as string

		try {
			await login(username, password)
		} catch (error: any) {
			if (error === AuthError.InvalidCredentials)
				setMessage('Identifiants invalides')
			else if (error === AuthError.ConnectionFailed)
				setMessage('Connexion aux serveurs impossible')
			else {
				setMessage(
					'Une erreur est survenue. Veuillez réessayer plus tard.'
				)
			}
		}
	}

	useEffect(() => {
		if (authUser) {
			navigate(from, { replace: true })
		}
	}, [authUser, from, navigate])

	return (
		<Container component='main' maxWidth='xs'>
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
					<LockOutlined />
				</Avatar>
				<Typography component='h1' variant='h5'>
					Se connecter
				</Typography>
				<Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
					<TextField
						margin='normal'
						required
						fullWidth
						id='email'
						label='Adresse email'
						name='email'
						autoComplete='email'
						autoFocus
						sx={{
							height: '56px',
						}}
					/>
					<FormControl
						sx={{ mt: 1 }}
						variant='outlined'
						fullWidth
						required
					>
						<InputLabel htmlFor='password'>Mot de passe</InputLabel>
						<OutlinedInput
							id='password'
							autoComplete='current-password'
							type={showPassword ? 'text' : 'password'}
							label='Mot de passe'
							name='password'
							endAdornment={
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge='end'
									>
										{showPassword ? (
											<VisibilityOff />
										) : (
											<Visibility />
										)}
									</IconButton>
								</InputAdornment>
							}
							sx={{
								height: '56px',
							}}
						/>
					</FormControl>
					<Grid container color='red'>
						{message === 'loading' ? <CircularProgress /> : message}
					</Grid>

					<Button
						type='submit'
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2 }}
					>
						Se connecter
					</Button>
					<Grid container>
						<Grid item xs>
							<Link
								href='/password-reset'
								variant='body2'
								onClick={() => {
									navigate('/password-reset')
								}}
							>
								Réinitialiser le mot de passe
							</Link>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Copyright sx={{ mt: 8, mb: 4 }} />
		</Container>
	)
}

import { useCallback, useMemo } from 'react'
import { Box, Button, Chip, useTheme, useMediaQuery } from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import SupportType from 'domain/Support/interfaces/SupportType'
import ProblematicDTO from 'domain/Diagnostic/Problematic/interfaces/ProblematicDTO'

import CategoriesMock from 'domain/Psycho/core/data-access/StrategiesCategoriesMock'

import Modal from '../Modal/View'
import SwipeableDrawer from '../SwipeableDrawer/View'

const getCategoryStyles = (categoryLabel: string) => {
	switch (categoryLabel) {
		case 'Amitié': {
			return {
				backgroundColor: '#FCF3ED',
				color: '#E17F48',
			}
		}
		case 'Amour': {
			return {
				backgroundColor: '#FAEEEE',
				color: '#C54F4F',
			}
		}
		case 'Santé': {
			return {
				backgroundColor: '#EFF7F5',
				color: '#5FA69B',
			}
		}
		case 'Sport': {
			return {
				backgroundColor: '#EDF6F9',
				color: '#48A0BC',
			}
		}
		case 'Famille': {
			return {
				backgroundColor: '#FAEEF2',
				color: '#C95478',
			}
		}
		case 'Argent': {
			return {
				backgroundColor: '#EEF4FA',
				color: '#4E8BC5',
			}
		}
		case 'Travail': {
			return {
				backgroundColor: '#F2EFFC',
				color: '#785FDB',
			}
		}
		case 'Émotions': {
			return {
				backgroundColor: '#F6EFFA',
				color: '#A05DCA',
			}
		}
		default: {
			return {
				backgroundColor: '#E9E9E9',
				color: '#292827',
			}
		}
	}
}

type Props = {
	problematic: ProblematicDTO | null
	type: 'modal' | 'swipeable-drawer'
	opened: boolean
	goToCoaching: (problematic: ProblematicDTO) => void
	edit: (problematic: ProblematicDTO) => void
	cancel: () => void
	remove: (id: string) => void
}

export default function View({
	problematic,
	type,
	opened,
	goToCoaching,
	edit,
	cancel,
	remove,
}: Props) {
	/**
	 * Dependencies
	 */

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	/**
	 * Memos
	 */

	const description = useMemo(() => {
		if (problematic === null) {
			return ''
		}

		const description = problematic.description.slice()
		if (description.length > 0) {
			return description
		}

		return problematic.freeDescription.slice()
	}, [problematic])

	/**
	 * Callbacks
	 */

	const onGoToCoaching = useCallback(() => {
		if (problematic === null) return

		goToCoaching(problematic)
	}, [problematic, goToCoaching])

	const onEdit = useCallback(() => {
		if (problematic === null) return

		edit(problematic)
	}, [problematic, edit])

	const onRemove = useCallback(() => {
		if (problematic === null) return

		remove(problematic.id)
	}, [problematic, remove])

	/**
	 * Render
	 */

	const categoriesRender = useMemo(() => {
		if (problematic === null) return null

		return problematic.categories.map((categoryId) => {
			const category = CategoriesMock.find(
				(strategyCategory) => strategyCategory.id === categoryId
			)

			if (!category) return null

			return (
				<Chip
					key={category.id}
					label={category.label}
					style={{
						backgroundColor: getCategoryStyles(category.label)
							.backgroundColor,
						color: getCategoryStyles(category.label).color,
						fontSize: '0.75rem',
						height: '24px',
					}}
				/>
			)
		})
	}, [problematic])

	const coachingButtonLabelRender = useMemo(() => {
		if (problematic === null) return ``

		for (let i = 0; i < problematic.supports.length; i++) {
			const support = problematic.supports[i]
			if (support.type === SupportType.PerspectiveSolver) {
				return `Reprendre le coaching`
			}
		}

		return `Commencer le coaching`
	}, [problematic])

	const contentRender = useMemo(
		() => (
			<Box
				sx={{
					display: 'flex',
					flex: '1 0 0',
					flexDirection: 'column',
					justifyContent: 'space-between',
					width: '100%',
					gap: '24px',
				}}
			>
				{isMobile ? (
					<>
						<Box
							sx={{
								position: 'absolute',
								top: '20px',
								left: '50%',
								transform: 'translateX(-40px)',
								width: '80px',
								height: '4px',
								borderRadius: '4px',
								background: '#e8e8e8',
							}}
						></Box>
					</>
				) : (
					<></>
				)}
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						flex: '0 0 fit-content',
						width: '100%',
						gap: '8px',
						marginTop: '8px',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							gap: '4px',
						}}
					>
						{categoriesRender}
					</Box>
					<h3>{description}</h3>
				</Box>
				<Box sx={{ height: '100%', fontSize: '1rem' }}>
					<Box
						onClick={onEdit}
						sx={{
							display: 'flex',
							gap: '8px',
							padding: '12px',
							alignItems: 'center',
							color: theme.palette.primary.main,
							fill: theme.palette.primary.main,
						}}
					>
						<EditIcon /> Editer le sujet
					</Box>
					<Box
						onClick={onRemove}
						sx={{
							display: 'flex',
							gap: '8px',
							padding: '12px',
							alignItems: 'center',
							color: '#F16161',
							fill: '#F16161',
						}}
					>
						<DeleteIcon /> Supprimer le sujet
					</Box>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: isMobile ? 'column' : 'row',
						flex: '0 0 fit-content',
						width: '100%',
						gap: '8px',
					}}
				>
					{isMobile ? (
						<>
							<Button
								onClick={onGoToCoaching}
								variant='contained'
								color='primary'
							>
								{coachingButtonLabelRender}
							</Button>
							<Button
								onClick={cancel}
								variant='contained'
								color='secondary'
							>
								Annuler
							</Button>
						</>
					) : (
						<>
							<Button
								onClick={cancel}
								variant='contained'
								color='secondary'
							>
								Annuler
							</Button>
							<Button
								onClick={onGoToCoaching}
								variant='contained'
								color='primary'
							>
								{coachingButtonLabelRender}
							</Button>
						</>
					)}
				</Box>
			</Box>
		),
		[
			isMobile,
			description,
			theme,
			categoriesRender,
			coachingButtonLabelRender,
			onGoToCoaching,
			onEdit,
			onRemove,
			cancel,
		]
	)

	if (type === 'modal') {
		return (
			<Modal isOpen={opened} close={cancel} maximized>
				{contentRender}
			</Modal>
		)
	} else {
		return (
			<SwipeableDrawer
				maximized
				isOpen={opened}
				close={cancel}
				anchor={'bottom'}
			>
				{contentRender}
			</SwipeableDrawer>
		)
	}
}

import { createTheme } from '@mui/material/styles'

const primaryMainColor = '#369EA0'
const primaryLightColor = '#F1FDFD'
const primaryDarkColor = '#115355'
const primaryContrastColor = '#FFFFFF'
const secondaryLightColor = '#FCF3EF'

const lightTheme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: primaryMainColor,
			light: primaryLightColor,
			dark: primaryDarkColor,
			contrastText: primaryContrastColor,
		},
		secondary: {
			main: '#FFFFFF',
		},
		text: {
			primary: '#292827',
			secondary: '#292827',
			disabled: '#A0A0A0',
		},
		background: {
			default: '#FCF3EF',
			paper: '#FFFFFF',
		},
	},
	//shadows: ['none', '8px 8px 8px 8px rgba(0,0,0,1)', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
	typography: {
		fontFamily: ['Quattrocento Sans', 'sans-serif'].join(','),
		fontSize: 16,
	},
	components: {
		MuiAccordion: {
			styleOverrides: {
				root: {
					width: '100%',
					fontSize: '0.875rem',
					borderRadius: '4px',
					padding: '0px 12px',
					'&:before': {
						display: 'none',
					},
				},
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				content: {
					width: '100%',
					fontSize: '0.875rem',
					borderRadius: '4px',
				},
			},
		},
		MuiAccordionDetails: {
			styleOverrides: {
				root: {
					width: '100%',
					fontSize: '0.875rem',
					padding: '8px 0px 12px',
				},
			},
		},
		MuiBottomNavigation: {
			styleOverrides: {
				root: {
					background: 'none',
					padding: '8px 16px 24px',
					height: 'auto',
					[createTheme().breakpoints.up('sm')]: {
						backgroundColor: '#FFFFFF',
						borderRadius: '8px',
						overflow: 'hidden',
						display: 'flex',
						width: 'auto',
						height: 'fit-content',
						padding: 'unset',
						position: 'absolute',
						top: '32px',
					},
				},
			},
		},
		MuiBottomNavigationAction: {
			styleOverrides: {
				label: {
					color: '#292827',
					lineHeight: '100%',
					fontSize: '0.75rem',
					width: 'max-content',
					'&.Mui-selected': {
						color: primaryMainColor,
						fontSize: '0.75rem',
					},
					[createTheme().breakpoints.up('sm')]: {
						fontSize: '1rem',
						borderRadius: '8px',
						'&.Mui-selected': {
							color: primaryContrastColor,
							fontSize: '1rem',
						},
					},
				},
				root: {
					padding: '0px',
					minWidth: 'unset',
					[createTheme().breakpoints.up('sm')]: {
						padding: '12px 24px 12px 12px',
						display: 'flex',
						flexDirection: 'row',
						gap: '6px',
						'&.Mui-selected': {
							backgroundColor: primaryMainColor,
						},
					},
					width: 'auto',
					height: 'auto',
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				gutters: {
					padding: '0px',
				},
			},
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					borderRadius: '4px',
					backgroundColor: '#FFFFFF',
					border: '1px solid #f5f5f5',
					padding: '8px',
					marginBottom: '8px',
					'&:hover':{
						backgroundColor:'#FFFFFF',
						borderColor: primaryMainColor
					},
					'&.Mui-selected': {
						border: '1px solid',
						borderColor: primaryMainColor,
					},
				},
				dense: {
					padding: '12px',
				},
			},
		},
		MuiListItemText: {
			styleOverrides: {
				root: {
					fontSize: '1rem',
					margin: '0px',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					padding: '14px 24px',
					textTransform: 'unset',
					fontSize: '1rem',
					fontWeight: '400',
					borderRadius: '560px',
					backgroundColor: primaryMainColor,
					lineHeight: '100%',
					minWidth: '120px',
					width: '100%',
					transition: 'transform 0.2s ease-out',
					boxShadow: 'none',
					[createTheme().breakpoints.up('sm')]: {
						fontSize: '1rem',
						width: 'fit-content',
						minWidth: '160px',
					},
					'&:hover': {
						backgroundColor: primaryMainColor,
						boxShadow: 'none',
						transform: 'scale(1.05)',
					},
				},
				containedSecondary: {
					color: primaryMainColor,
					background: 'none',
					border: 'solid 1px',
					borderColor: primaryMainColor,
					transition: 'transform 0.2s ease-out',
					'&:hover': {
						transform: 'scale(1.05)',
						background: secondaryLightColor,
					},
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					marginTop: '8px',
					backgroundColor: '#FFFFFF',
					fontSize: '1rem',
					padding: '12px',
					alignContent: 'center',
				},
				input: {
					lineHeight: '100%',
				},
			},
		},
		MuiInputAdornment: {
			styleOverrides: {
				positionStart: {
					marginTop: '0px !important',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					marginTop: '0px',
				},
				input: {
					padding: 'unset',
				},
				notchedOutline: {
					borderColor: '#FDF8F1',
				},
			},
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					marginTop: '0px',
					borderRadius: '650px',
					backgroundColor: '#ffffff',
					[createTheme().breakpoints.up('sm')]: {
						backgroundColor: '#f5f5f5',
					},
					'&:before': {
						display: 'none',
					},
					'&::hover:before': {
						display: 'none',
					},
					'&:after': {
						display: 'none',
					},
					'&:hover': {
						backgroundColor: '#fff',
					},
					'&.Mui-focused': {
						backgroundColor: '#fff',
					},
				},
				input: {
					padding: 'unset',
					borderRadius: '0px!important',
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				select: {
					padding: 'unset',
					alignContent: 'center',
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: 'unset',
					minHeight: 'unset',
					height: 'fit-content',
					borderRadius: '56px',
					padding: '12px 24px',
					zIndex: '1',
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					minHeight: 'unset',
				},
				flexContainer: {
					justifyContent: 'center',
				},
				indicator: {
					bottom: '0',
					top: '0',
					right: '0',
					left: '0',
					height: 'auto',
					borderRadius: '56px',
					backgroundColor: primaryMainColor,
					color: primaryContrastColor,
					zIndex: '0',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					borderRadius: '8px',
					boxShadow: 'none',
				},
			},
		},
		MuiLinearProgress: {
			styleOverrides: {
				root: {
					backgroundColor: '#f5f6f6',
					[createTheme().breakpoints.up('sm')]: {
						borderRadius: '4px',
					},
				},
				bar: {
					borderRadius: '4px',
					[createTheme().breakpoints.up('sm')]: {
						borderRadius: '0px 4px 4px 0px',
					},
				},
			},
		},
	},
})

export default lightTheme

import { Box } from '@mui/material'

export default function View() {
	return (
		<Box sx={{ height: '100%', maxHeight: '100%', overflow: 'auto' }}>
			<p>Ton niveau de bien-être général est dans la moyenne.</p>
			<br />
			<p>
				Grâce à un travail sur tes pensées et tes croyances, tu peux
				grandement améliorer ton niveau de bien-être de manière
				progressive et significative.
			</p>
			<br />
			<p>
				En allant répondre aux différentes questions des catégories, tu
				peux découvrir d’éventuelles pensées ou croyances désagréables
				qui seront à utiliser dans la partie “auto-coaching”. Avec une
				session d’auto-coaching quotidienne d’environ 10 minutes, tu vas
				pouvoir transformer les pensées ou croyances qui ne sont pas
				adaptées à ton épanouissement.
			</p>
		</Box>
	)
}

import { useMemo } from 'react'
import { Button } from '@mui/material'

import Inspiration from 'domain/Support/PerspectiveSolver/core/tips/Inspiration'

import './styles.css'

type InspirationProps = {
	inspiration: Inspiration
	goBackToInstruction: () => void
}

function InstructionPageComponent({
	inspiration,
	goBackToInstruction,
}: InspirationProps) {
	/**
	 * Render
	 */

	const messagesRender = useMemo(() => {
		const messagesRender = inspiration.text.map((text, index) => (
			<li key={index}>{text}</li>
		))

		return <ul>{messagesRender}</ul>
	}, [inspiration])

	return (
		<div className='tips-inspiration'>
			<div className='tips-inspiration-content'>{messagesRender}</div>

			{/*
			<div className='tips-inspiration-non-exhaustive-mention'>
				Liste non-exhaustive
			</div>
			*/}
			<div className='tips-inspiration-quit'>
				<Button
					variant='contained'
					style={{ color: 'white' }}
					onClick={goBackToInstruction}
				>
					Revenir aux conseils
				</Button>
			</div>
		</div>
	)
}

export default InstructionPageComponent

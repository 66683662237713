import Question from '../../interfaces/data-transfer-objects/QuestionDTO'

const rawQuestions = [
	{
		id: `QY_Evm708OR_BKtkJgAHW`,
		categoryId: `7`,
		wording: `Que penses-tu de toi dans le sport ?`,
		answerPrefix: `Le sport et moi : `,
	},
	{
		id: `PkZjSWzi4ZYxW7yS2GPNt`,
		categoryId: `7`,
		wording: `Que penses-tu du sport en général ? Que représente le sport pour toi ?`,
		answerPrefix: `Le sport : `,
	},
	{
		id: `PcWhotUtEInnQEPVyPsu-`,
		categoryId: `7`,
		wording: `Que penses-tu de l’effort physique ?`,
		answerPrefix: `L'effort physique : `,
	},
	{
		id: `q9j2MGnCFo0MdknpCXusc`,
		categoryId: `7`,
		wording: `Qu’est ce que tu penses que les autres pensent de toi par rapport au sport ?`,
		answerPrefix: `Ce qu'on pense de moi au sport : `,
	},
	{
		id: `d9nv0L4cKrvj6WJfL3e-Z`,
		categoryId: `7`,
		wording: `Que penses-tu des personnes qui font du sport ?`,
		answerPrefix: `Les personnes qui font du sport : `,
	},
	{
		id: `ii_mWpIOyBgeCoCaYj-8r`,
		categoryId: `6`,
		wording: `Que penses-tu de toi dans le monde du travail ?`,
		answerPrefix: `Ce que je pense de moi dans le monde du travail : `,
	},
	{
		id: `fA1ibCxE45og8xnj74BPS`,
		categoryId: `6`,
		wording: `Qu’est ce que tu penses que les autres pensent de toi dans le monde professionnel ?`,
		answerPrefix: `Ce qu'on pense de moi dans le monde pro : `,
	},
	{
		id: `GVYhoT7hQRnyIbmGWS7Yj`,
		categoryId: `6`,
		wording: `Qu’est ce que tu penses que les autres pensent de toi dans ta carrière ?`,
		answerPrefix: `Ce qu'on pense de moi dans ma carrière : `,
	},
	{
		id: `KMXfcM5C5E_VOTeJPpxWQ`,
		categoryId: `6`,
		wording: `Que penses-tu du travail ? Que représente le travail pour toi ?`,
		answerPrefix: `Le travail : `,
	},
	{
		id: `FsWSv3F50LulnOWCC-C3e`,
		categoryId: `6`,
		wording: `Que penses-tu de ton domaine professionnel ?`,
		answerPrefix: `Mon domaine professionnel : `,
	},
	{
		id: `YR52-0u0WRS0HyPm2yybG`,
		categoryId: `6`,
		wording: `Que penses-tu de changer de métier au cours de sa vie ?`,
		answerPrefix: `Changer de métier au cours de sa vie : `,
	},
	{
		id: `peeCaPIjg01eQqgRdpQ7e`,
		categoryId: `2`,
		wording: `Que penses-tu de l'argent ? Que représente l'argent pour toi ?`,
		answerPrefix: `L'argent : `,
	},
	{
		id: `f3airspCz6--FFBmmOWmK`,
		categoryId: `2`,
		wording: `Que penses-tu de ta situation financière actuelle ?`,
		answerPrefix: `Ma situation financière actuelle : `,
	},
	{
		id: `7eiSMem5PlnQt7oHD_rkG`,
		categoryId: `2`,
		wording: `Qu’est ce que tu penses que les autres pensent de toi dans ta situation financière ?`,
		answerPrefix: `Ce qu'on pense de moi dans ma situation financière : `,
	},
	{
		id: `LewtpxFDyYF3OSnzXLu9E`,
		categoryId: `2`,
		wording: `Que penses-tu de toi avec peu ou beaucoup d'argent ?`,
		answerPrefix: `Moi avec peu ou beaucoup d'argent : `,
	},
	{
		id: `FRTb8n3jr3AoOFxl6qSAf`,
		categoryId: `2`,
		wording: `Qu’est ce que tu penses que les autres pensent de toi avec peu ou beaucoup d'argent ?`,
		answerPrefix: `Ce qu'on pense de moi quand j'ai peu ou beaucoup d'argent : `,
	},
	{
		id: `FyTt4twSZnazS1nIgxL9k`,
		categoryId: `2`,
		wording: `Que penses-tu des personnes riches, pauvres, modestes et millionnaires ?`,
		answerPrefix: `Les riches, pauvres, modestes, millionnaires : `,
	},
	{
		id: `iQg7sz4ko52B2ryQ9kybD`,
		categoryId: `2`,
		wording: `Que penses-tu de la gestion financière ?`,
		answerPrefix: `La gestion financière : `,
	},
	{
		id: `R6cOpSuXsRMWbwqlwHQTK`,
		categoryId: `8`,
		wording: `Que penses-tu de toi de manière générale ?`,
		answerPrefix: `Ce que je pense de moi : `,
	},
	{
		id: `PuuatIMHc-vCo7Olog4Bb`,
		categoryId: `8`,
		wording: `Que penses-tu de toi au sein de la société ?`,
		answerPrefix: `Moi au sein de la société : `,
	},
	{
		id: `bbHypPE76TZDks54tsMkf`,
		categoryId: `8`,
		wording: `Qu’est ce que tu imagines que les autres pensent de toi ?`,
		answerPrefix: `Ce que j'imagine qu'on pense de moi : `,
	},
	{
		id: `38xjQuv8nluYCmDGWztZd`,
		categoryId: `8`,
		wording: `Que penses-tu de tes défauts ?`,
		answerPrefix: `Ce que je pense de mes défauts : `,
	},
	{
		id: `VJ4PhxbX1Iutilzd3vleG`,
		categoryId: `8`,
		wording: `Que penses-tu de passer du temps seul-e ?`,
		answerPrefix: `Passer du temps seul-e : `,
	},
	{
		id: `SaJo1cNElzjv1lJAQXuLZ`,
		categoryId: `8`,
		wording: `Que penses-tu des émotions désagréables ?`,
		answerPrefix: `Les émotions désagréables : `,
	},
	{
		id: `a5pDQxOoamP2M8jfPUNuS`,
		categoryId: `8`,
		wording: `Que penses-tu du stress ?`,
		answerPrefix: `Le stress : `,
	},
	{
		id: `dtnSHY--lkx4lmB9Cqar6`,
		categoryId: `1`,
		wording: `Que penses-tu de ta santé ?`,
		answerPrefix: `Ma santé : `,
	},
	{
		id: `wNTvTneLC-Fu5-6ztbgeN`,
		categoryId: `1`,
		wording: `Que penses-tu des douleurs ou des inconforts ?`,
		answerPrefix: `Les douleurs ou inconforts : `,
	},
	{
		id: `UshdVy5vfRYrne_CrjTsp`,
		categoryId: `1`,
		wording: `Que penses-tu de toi par rapport à ta santé ?`,
		answerPrefix: `Ce que je pense de moi vis-à-vis de ma santé : `,
	},
	{
		id: `xJZIlHkgdr-eYYrzhF8cM`,
		categoryId: `1`,
		wording: `Que penses-tu de ta vie et ton quotidien par rapport à ta santé ?`,
		answerPrefix: `Ce que je pense de ma vie et mon quotidien : `,
	},
	{
		id: `OKBdE9tGKThAJh2Cto4UJ`,
		categoryId: `1`,
		wording: `S’il y a une maladie diagnostiquée, que penses-tu de celle-ci par rapport à ton épanouissement ?`,
		answerPrefix: `Au sujet de ma maladie : `,
	},
	{
		id: `1PvyJTXYmQ-Tz36Objhwk`,
		categoryId: `3`,
		wording: `Que penses-tu de ton couple ?`,
		tag: `couple`,
		answerPrefix: `Mon couple : `,
	},
	{
		id: `gYmqX1KnnHjuYjBZ_E2Tq`,
		categoryId: `3`,
		wording: `Que penses-tu de toi dans ta relation amoureuse ?`,
		tag: `couple`,
		answerPrefix: `Moi dans ma relation amoureuse : `,
	},
	{
		id: `n_XqMEo0zxxSiItt9HLm6`,
		categoryId: `3`,
		wording: `Que penses-tu que les autres pensent de ta vie amoureuse ?`,
		tag: `couple`,
		answerPrefix: `Ce qu'on pense de ma relation amoureuse : `,
	},
	{
		id: `-rejSCXXyjfbYcC_wt53E`,
		categoryId: `3`,
		wording: `Que penses-tu de ton ou ta partenaire ?`,
		tag: `couple`,
		answerPrefix: `Mon/Ma partenaire : `,
	},
	{
		id: `hq0Mi99X4p4Kv1JAJBJSl`,
		categoryId: `3`,
		wording: `Que penses-tu de ta situation amoureuse actuelle ?`,
		tag: `single`,
		answerPrefix: `Ma situation amoureuse actuelle`,
	},
	{
		id: `XJZdOHzzK5yIaCrHeAW_4`,
		categoryId: `3`,
		wording: `Que penses-tu des femmes ou des hommes de manière générale ?`,
		tag: `single`,
		answerPrefix: `Les femmes ou hommes de manière générale : `,
	},
	{
		id: `s_FrOh30Ezl0-VAigerZv`,
		categoryId: `3`,
		wording: `Que penses-tu de toi lors d’une nouvelle rencontre ?`,
		tag: `single`,
		answerPrefix: `Moi lors d'une nouvelle rencontre : `,
	},
	{
		id: `oC1YUZUJSjHpHWEtfR-nh`,
		categoryId: `3`,
		wording: `Que penses-tu que les personnes que tu rencontres pensent de toi ?`,
		tag: `single`,
		answerPrefix: `Ce qu'on pense de moi en me rencontrant : `,
	},
	{
		id: `ozWmxiH_tRYScl5Xn8ghV`,
		categoryId: `3`,
		wording: `Que penses-tu du couple de manière générale ?`,
		tag: `single`,
		answerPrefix: `Le couple : `,
	},
	{
		id: `DkALbCH51nUYjL-9hODjI`,
		categoryId: `4`,
		wording: `Que penses-tu de ta famille ?`,
		answerPrefix: `Ma famille : `,
	},
	{
		id: `_ab8lGs4wy8hBM2McvKlw`,
		categoryId: `4`,
		wording: `Que penses-tu que ta famille pense de toi ?`,
		answerPrefix: `Ce que la famille pense de moi : `,
	},
	{
		id: `9RdXWIk8l0IIZPkqk0V2n`,
		categoryId: `4`,
		wording: `Que penses-tu de chaque membre de ta famille avec qui tu es proche ?`,
		answerPrefix: `Les membres de ma famille proche : `,
	},
	{
		id: `C-DjtHPm3E0BRKATGfJD8`,
		categoryId: `4`,
		wording: `Que penses-tu de chaque relation que tu entretiens dans ta famille ?`,
		answerPrefix: `Mes relations avec ma famille : `,
	},
	{
		id: `g4h3Xokuq-4ZOkcq6tnVy`,
		categoryId: `4`,
		wording: `Si tu as des enfants, que penses-tu de leur éducation actuelle ?`,
		answerPrefix: `L'éducation de mes enfants : `,
	},
	{
		id: `5Z5p04Sappls-rYuZmlAU`,
		categoryId: `4`,
		wording: `Si tu as des enfants, que penses-tu de toi en tant que parent ?`,
		answerPrefix: `Moi en tant de parent : `,
	},
	{
		id: `-TdW-Tnebuzrc6sFSmKcL`,
		categoryId: `5`,
		wording: `Que penses-tu de ta vie amicale ?`,
		answerPrefix: `Ma vie amicale : `,
	},
	{
		id: `4FMt4_MXOoG9Nz1RW5wVL`,
		categoryId: `5`,
		wording: `Que penses-tu du temps passé avec tes amis ?`,
		answerPrefix: `Le temps passé avec mes amis : `,
	},
	{
		id: `SF9ghImBymyIxzWHAasCs`,
		categoryId: `5`,
		wording: `Que penses-tu des nouvelles rencontres amicales ?`,
		answerPrefix: `Les nouvelles rencontres amicales : `,
	},
	{
		id: `G8S9eFY8YsxxIGqCxrGWV`,
		categoryId: `5`,
		wording: `Que penses-tu de toi dans tes relations amicales ?`,
		answerPrefix: `Moi dans les relations amicales : `,
	},
	{
		id: `zOz9XTuNCvoFR-AKkc5uJ`,
		categoryId: `5`,
		wording: `Que penses-tu que tes amis pensent de toi ?`,
		answerPrefix: `Ce que mes amis pensent de moi : `,
	},
]

const questions: Array<Question> = []

for (let i = 0; i < rawQuestions.length; i++) {
	const rawQuestion = rawQuestions[i]

	questions.push({
		id: rawQuestion.id,
		categoryId: rawQuestion.categoryId,
		wording: rawQuestion.wording,
		answerPrefix: rawQuestion.answerPrefix,
		tag: rawQuestion.tag,
	})
}

export default questions

import { Button } from '@mui/material'

import CoachingSituation from 'domain/Support/PerspectiveSolver/core/CoachingSituation'

type ResultAdditionalSituationFormProps = {
	situation: CoachingSituation
	updateSituation: (updatedSituation: CoachingSituation) => void
	startNewSituation: () => void
	quitCoaching: () => void
}

function ResultAdditionalSituationForm({
	situation,
	updateSituation,
	startNewSituation,
	quitCoaching,
}: ResultAdditionalSituationFormProps) {
	/**
	 * Render
	 */

	return (
		<div className='coaching-situation-content'>
			<h3 style={{ flex: '0 0 fit-content' }}>
				Ma problématique a évolué pendant le processus
			</h3>
			<div style={{ flex: '1 0 0', overflowY: 'auto' }}>
				<p>L’objectif est d’identifier la nouvelle problématique.</p>
				<p>
					En effet, une première pensée désagréable peut avoir été
					traitée, mais une autre pensée peut apparaître à la suite.
					C’est un peu comme enlever des couches d’un oignon. Le
					coaching a donc besoin de continuer.
				</p>
				<p>
					<b>Suggestion</b> : tu peux prendre une feuille et écrire ce
					qui te passe par la tête pour identifier la situation et les
					nouvelles pensées.
				</p>
				<p>Est ce que tu souhaites faire une nouvelle session ?</p>
			</div>

			<div style={{ flex: '0 0 fit-content' }}>
				<Button
					onClick={startNewSituation}
					variant='contained'
					style={{ color: 'white', margin: '8px 0px' }}
					fullWidth
				>
					Oui, c’est parti !
				</Button>
				<Button
					onClick={quitCoaching}
					variant='contained'
					style={{ color: 'white', margin: '8px 0px' }}
					fullWidth
				>
					Non, quitter
				</Button>
			</div>
		</div>
	)
}

export default ResultAdditionalSituationForm

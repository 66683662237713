import CategoryWeight from '../entities/CategoryWeight'
import CategoryWeightDTO from '../../interfaces/data-transfer-objects/CategoryWeightDTO'

class CategoryWeightMapper {
	static toDTO(categoryWeight: CategoryWeight): CategoryWeightDTO {
		return {
			categoryId: categoryWeight.categoryId,
			weightCode: categoryWeight.weightCode,
		}
	}
}

export default CategoryWeightMapper

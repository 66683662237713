import SnapshotDTO from 'domain/Diagnostic/WellBeingSnapshot/Snapshot/interfaces/SnapshotDTO'
import SnapshotInteractor from 'domain/Diagnostic/WellBeingSnapshot/Snapshot/interfaces/SnapshotInteractor'

import QuestionnaireInteractor from '../../interfaces/QuestionnaireInteractor'
import QuestionnaireRepository from '../../interfaces/QuestionnaireRepository'

import QuestionnaireMapper from '../mappers/QuestionnaireMapper'

export default class QuestionnaireInteractorImpl
	implements QuestionnaireInteractor
{
	constructor(
		private questionnaireRepository: QuestionnaireRepository,
		private snapshotInteractor: SnapshotInteractor
	) {}

	getCurrent = async () => {
		const questionnaire =
			await this.questionnaireRepository.getCurrentQuestionnaire()

		if (questionnaire === null) return null

		return QuestionnaireMapper.toDTO(questionnaire)
	}

	create = async () => {
		let questionnaire =
			await this.questionnaireRepository.getCurrentQuestionnaire()

		if (questionnaire === null) {
			questionnaire =
				await this.questionnaireRepository.createQuestionnaire()
		}

		return QuestionnaireMapper.toDTO(questionnaire)
	}

	selectAnswer = async (questionIndex: number, answerIndex: number) => {
		const questionnaire =
			await this.questionnaireRepository.getCurrentQuestionnaire()

		if (questionnaire === null)
			throw Error('Tried to answer to questionnaire but none exists')

		await questionnaire.answerQuestion(questionIndex, answerIndex)
		await this.questionnaireRepository.saveQuestionnaire(questionnaire)

		return QuestionnaireMapper.toDTO(questionnaire)
	}

	confirmQuestionnaire = async () => {
		const questionnaire =
			await this.questionnaireRepository.getCurrentQuestionnaire()

		if (questionnaire === null)
			throw Error('Tried to confirm questionnaire but there is none')

		const questionnaireIsConfirmable = questionnaire.isConfirmable()
		if (questionnaireIsConfirmable === false)
			throw Error('Tried to confirm questionnaire that is not complete')

		const snapshotDTO: SnapshotDTO = await questionnaire.confirm()

		await this.snapshotInteractor.save(snapshotDTO)
		await this.questionnaireRepository.clearQuestionnaire()

		return snapshotDTO
	}
}

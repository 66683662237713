import { useMemo } from 'react'
import { Button, Grow } from '@mui/material'

import './styles.css'

type ConfirmationButtonProps = {
	isShown: boolean
	label: String
	confirm: () => void
}

function ConfirmationButton({
	isShown,
	label,
	confirm,
}: ConfirmationButtonProps) {
	/**
	 * Render
	 */

	const confirmationButtonClass = useMemo(() => {
		if (isShown === false) return 'confirmation-button'
		else return 'confirmation-button confirmation-button-shown'
	}, [isShown])

	return (
		<div className={confirmationButtonClass}>
			<Grow in={isShown} timeout={{ enter: 500 }}>
				<Button
					onClick={confirm}
					variant='contained'
					style={{ color: 'white' }}
					fullWidth
				>
					{label}
				</Button>
			</Grow>
		</div>
	)
}

export default ConfirmationButton

import { useCallback, useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, useTheme, useMediaQuery } from '@mui/material'
import { produce } from 'immer'

import SupportType from 'domain/Support/interfaces/SupportType'
import ProblematicDTO from 'domain/Diagnostic/Problematic/interfaces/ProblematicDTO'

import useInteractors from 'presentation/hooks/useInteractors'

import ManageProblematicContainer from 'presentation/views/components/ManageProblematicContainer/View'
import EditProblematicContainer from 'presentation/views/components/EditProblematicContainer/View'
import DoneProblematicContainer from 'presentation/views/components/DoneProblematicContainer/View'

import Header from './Header/View'
import Filters from './Filters/View'
import AddNewButton from './AddNewButton/View'
import ProblematicList from './ProblematicList/View'

export default function View() {
	/**
	 * Base
	 */

	const navigate = useNavigate()

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const {
		coachingInteractor,
		diagnostic: { problematicInteractor },
	} = useInteractors()

	/**
	 * Local state
	 */

	const [problematics, setProblematics] = useState<Array<ProblematicDTO>>([])
	const [search, setSearch] = useState<string>('')

	const [managedProblematic, setManagedProblematic] =
		useState<ProblematicDTO | null>(null)
	const [managingProblematic, setManagingProblematic] =
		useState<boolean>(false)

	const [editedProblematic, setEditedProblematic] =
		useState<ProblematicDTO | null>(null)
	const [editingProblematic, setEditingProblematic] = useState<boolean>(false)

	const [shownDoneProblematic, setShownDoneProblematic] =
		useState<ProblematicDTO | null>(null)
	const [showingDoneProblematic, setShowingDoneProblematic] =
		useState<boolean>(false)

	// Type of problematics to be shown (tab selection)
	// 0 shows started and waiting problematics
	// 1 shows done problematics
	const [problematicsTypeShown, selectProblematicsTypeShown] =
		useState<number>(0)

	/**
	 * Callbacks
	 */

	const loadProblematics = useCallback(async () => {
		const problematics = await problematicInteractor.getProblematics()
		setProblematics(problematics)
	}, [problematicInteractor])

	const onSearchChange = useCallback((search: string) => {
		setSearch(search)
	}, [])

	const onAddProblematic = useCallback(() => {
		setEditingProblematic(true)
	}, [])

	const onManageProblematic = useCallback((problematic: ProblematicDTO) => {
		setManagedProblematic(problematic)
		setManagingProblematic(true)
	}, [])

	const onEditProblematic = useCallback((problematic: ProblematicDTO) => {
		setEditedProblematic(problematic)
		setEditingProblematic(true)
	}, [])

	const onShowDoneProblematic = useCallback((problematic: ProblematicDTO) => {
		setShownDoneProblematic(problematic)
		setShowingDoneProblematic(true)
	}, [])

	const onConfirmNewProblematic = useCallback(
		async (
			id: string | null,
			newProblematic: string,
			categories: Array<string>
		) => {
			if (id === null) {
				const problematic = await problematicInteractor.addProblematic(
					newProblematic,
					categories
				)

				const newProblematicsState = produce(
					problematics,
					(problematics) => {
						problematics.unshift(problematic)
					}
				)

				setProblematics(newProblematicsState)
			} else {
				const problematic =
					await problematicInteractor.updateProblematic(
						id,
						newProblematic,
						categories
					)

				const newProblematicsState = produce(
					problematics,
					(problematics) => {
						for (let i = 0; i < problematics.length; i++) {
							if (problematics[i].id === problematic.id) {
								problematics[i] = problematic
								return problematics
							}
						}
					}
				)

				setProblematics(newProblematicsState)
			}

			setEditingProblematic(false)
			setEditedProblematic(null)
		},
		[problematicInteractor, problematics]
	)

	const onCancelProblematic = useCallback(() => {
		setEditingProblematic(false)
		setEditedProblematic(null)

		setShowingDoneProblematic(false)
		setShownDoneProblematic(null)

		setManagingProblematic(false)
		setManagedProblematic(null)
	}, [])

	const onRemoveProblematic = useCallback(
		async (id: string) => {
			await problematicInteractor.removeProblematic(id)

			const newProblematicsState: Array<ProblematicDTO> = produce(
				problematics,
				(problematics) => {
					return problematics.filter(
						(problematic) => problematic.id !== id
					)
				}
			)

			setEditingProblematic(false)
			setEditedProblematic(null)
			setShowingDoneProblematic(false)
			setShownDoneProblematic(null)
			setProblematics(newProblematicsState)
		},
		[problematicInteractor, problematics]
	)

	const onGoToCoaching = useCallback(
		async (problematic: ProblematicDTO) => {
			// If coaching already exist, navigate to it
			for (let i = 0; i < problematic.supports.length; i++) {
				const support = problematic.supports[i]
				if (support.type === SupportType.PerspectiveSolver) {
					navigate(`/autocoaching/${support.id}`)
					return
				}
			}

			// Create new coaching
			const coaching = await coachingInteractor.addFromProblematic(
				problematic.id
			)
			navigate(`/autocoaching/${coaching.id}`)
		},
		[coachingInteractor, navigate]
	)

	/**
	 * Memos
	 */

	const filteredProblematics = useMemo(
		() =>
			produce(problematics, (problematics) => {
				if (search.trim() === '') return problematics

				return problematics.filter((problematic) => {
					const descriptionToSearchIn = (
						problematic.description.trim() === ''
							? problematic.freeDescription
							: problematic.description
					)
						.normalize('NFD')
						.toLowerCase()

					const searchWords = search
						.split(' ')
						.map((word) => word.normalize('NFD').toLowerCase())

					for (let i = 0; i < searchWords.length; i++) {
						const searchWord = searchWords[i]
						if (descriptionToSearchIn.indexOf(searchWord) !== -1)
							return true
					}

					return false
				})
			}),
		[problematics, search]
	)

	const waitingProblematics = useMemo(
		() =>
			filteredProblematics.filter(
				(problematic) => problematic.supports.length === 0
			),
		[filteredProblematics]
	)

	const startedProblematics = useMemo(
		() =>
			filteredProblematics.filter(
				(problematic) =>
					problematic.supports.length > 0 &&
					problematic.supports[0].done === false
			),
		[filteredProblematics]
	)

	const doneProblematics = useMemo(
		() =>
			filteredProblematics.filter(
				(problematic) =>
					problematic.supports.length > 0 &&
					problematic.supports[0].done === true
			),
		[filteredProblematics]
	)

	/**
	 * Effects
	 */

	useEffect(() => {
		loadProblematics()
	}, [loadProblematics])

	useEffect(() => {
		if (managedProblematic === null) {
			return
		}

		for (let i = 0; i < problematics.length; i++) {
			if (managedProblematic.id === problematics[i].id) {
				setManagedProblematic(problematics[i])
				return
			}

			setManagingProblematic(false)
			setManagedProblematic(null)
		}
	}, [problematics, managedProblematic])

	useEffect(() => {
		if (shownDoneProblematic === null) {
			return
		}

		for (let i = 0; i < problematics.length; i++) {
			if (shownDoneProblematic.id === problematics[i].id) {
				setShownDoneProblematic(problematics[i])
				return
			}

			setShowingDoneProblematic(false)
			setShownDoneProblematic(null)
		}
	}, [problematics, shownDoneProblematic])

	/**
	 * Render
	 */

	const problematicsRender = useMemo(() => {
		if (problematicsTypeShown === 1) {
			return (
				<ProblematicList
					title={'Mes sessions archivées'}
					problematics={doneProblematics}
					onClickProblematic={onShowDoneProblematic}
				/>
			)
		}

		return (
			<>
				<ProblematicList
					title={'Mes sessions en cours'}
					problematics={startedProblematics}
					onClickProblematic={onManageProblematic}
				/>
				<ProblematicList
					title={'Mes sujets'}
					problematics={waitingProblematics}
					onClickProblematic={onManageProblematic}
				/>
			</>
		)
	}, [
		startedProblematics,
		waitingProblematics,
		doneProblematics,
		problematicsTypeShown,
		onManageProblematic,
		onShowDoneProblematic,
	])

	return (
		<>
			{isMobile ? (
				<>
					<Box
						sx={{
							position: 'relative',
							display: 'flex',
							flexDirection: 'column',
							height: '100%',
							width: '100%',
						}}
					>
						<Box
							sx={{
								flex: '0 0 auto',
								width: '100%',
							}}
						>
							<Header
								problematicsTypeShown={problematicsTypeShown}
								selectProblematicsTypeShown={
									selectProblematicsTypeShown
								}
							/>
						</Box>
						<Box
							sx={{
								flex: '0 0 auto',
								padding: '12px 16px',
								width: '100%',
							}}
						>
							<Filters
								search={search}
								onSearchChange={onSearchChange}
							/>
						</Box>
						<Box
							sx={{
								flex: '1 0 0',
								padding: {
									xs: '16px 0px 96px 16px',
									sm: '16px 24px',
								},
								overflow: 'auto',
								width: '100%',
								height: '100%',
							}}
						>
							{problematicsRender}
						</Box>
						<Box
							sx={{
								flex: '0 0 auto',
								padding: '32px 16px 16px',
								width: '100%',
								position: 'absolute',
								bottom: '0',
								background:
									'linear-gradient(180deg, rgba(252, 243, 239, 0.00) 0%, #FCF3EF 52%, #FCF3EF 100%)',
							}}
						>
							<AddNewButton onClick={onAddProblematic} />
						</Box>
					</Box>
				</>
			) : (
				<>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '32px',
							padding: {
								sm: '136px 48px 48px',
								md: '136px 32px 64px 32px',
								lg: '160px 64px 96px 64px',
							},
							height: '100%',
							width: '100%',
							maxWidth: '1248px',
							margin: '0 auto',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: {
									sm: 'column',
									md: 'row',
								},
								borderRadius: '8px',
								height: 'fit-content',
								width: '100%',
								gap: '16px',
								justifyContent: {
									sm: 'center',
									md: 'space-between',
								},
								alignItems: 'center',
								padding: '24px',
								background: theme.palette.background.paper,
							}}
						>
							<h2
								style={{
									margin: '0px',
									width: 'auto',
									whiteSpace: 'nowrap',
								}}
							>
								Mes notes
							</h2>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									height: 'fit-content',
									width: '100%',
									gap: {
										sm: '16px',
										md: '24px',
									},
									justifyContent: {
										sm: 'center',
										md: 'flex-end',
									},
									alignItems: 'center',
								}}
							>
								<Filters
									search={search}
									onSearchChange={onSearchChange}
								/>
								<AddNewButton onClick={onAddProblematic} />
							</Box>
						</Box>
						<Box>
							<Header
								problematicsTypeShown={problematicsTypeShown}
								selectProblematicsTypeShown={
									selectProblematicsTypeShown
								}
							/>
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexDirection: {
									sm: 'column',
									md: 'row',
								},
								width: '100%',
								height: 'fit-content',
								gap: '32px',
								justifyContent: 'center',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: {
										sm: 'column',
										md: 'row',
									},
									gap: '32px',
									height: 'fit-content',
									width: '100%',
								}}
							>
								{problematicsRender}
								{/*
								<ProblematicList
									title={'En cours'}
									problematics={startedProblematics}
									onClickProblematic={onEditProblematic}
								/>
								*/}
							</Box>
							{/*
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									height: 'fit-content',
									width: '100%',
								}}
							>
								<ProblematicList
									title={'En attente'}
									problematics={waitingProblematics}
									onClickProblematic={onEditProblematic}
								/>
							</Box>
							*/}
						</Box>
					</Box>
				</>
			)}
			<ManageProblematicContainer
				problematic={managedProblematic}
				type={isMobile ? 'swipeable-drawer' : 'modal'}
				opened={managingProblematic}
				goToCoaching={onGoToCoaching}
				edit={onEditProblematic}
				cancel={onCancelProblematic}
				remove={onRemoveProblematic}
			/>
			<EditProblematicContainer
				problematic={editedProblematic}
				type={isMobile ? 'swipeable-drawer' : 'modal'}
				opened={editingProblematic}
				confirm={onConfirmNewProblematic}
				cancel={onCancelProblematic}
			/>
			<DoneProblematicContainer
				problematic={shownDoneProblematic}
				type={isMobile ? 'swipeable-drawer' : 'modal'}
				opened={!!showingDoneProblematic}
				edit={onEditProblematic}
				cancel={onCancelProblematic}
				remove={onRemoveProblematic}
			/>
		</>
	)
}

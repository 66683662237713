import { ReactElement, useMemo } from 'react'
import { Box, Button, useTheme } from '@mui/material'

import SnapshotDTO from 'domain/Diagnostic/WellBeingSnapshot/Snapshot/interfaces/SnapshotDTO'
import QuestionnaireDTO from 'domain/Diagnostic/WellBeingSnapshot/Questionnaire/interfaces/data-transfer-objects/QuestionnaireDTO'

import CategoriesQuestionnaire from 'domain/Diagnostic/WellBeingSnapshot/CategoriesQuestionnaire/interfaces/data-transfer-objects/CategoriesQuestionnaireDTO'

import LastRefresh from './LastRefresh/View'
import Snapshot from './Snapshot/View'

type Props = {
	loading: boolean
	mostRecentSnapshot: SnapshotDTO | null
	snapshotQuestionnaire: QuestionnaireDTO | null
	showQuestionnaireStartInstructions: () => void
	categoriesQuestionnaire: CategoriesQuestionnaire | null
	startCategoriesQuestionnaire: () => Promise<void>
	modals: ReactElement
}

export default function View({
	loading,
	mostRecentSnapshot,
	snapshotQuestionnaire,
	showQuestionnaireStartInstructions,
	categoriesQuestionnaire,
	startCategoriesQuestionnaire,
	modals,
}: Props) {
	const theme = useTheme()

	const contentRender = useMemo(
		() =>
			loading ? (
				'Chargement ...'
			) : (
				<Snapshot snapshot={mostRecentSnapshot} />
			),
		[loading, mostRecentSnapshot]
	)

	const startCategoriesQuestionnaireButtonLabel = useMemo(() => {
		if (categoriesQuestionnaire === null) return `Identifier mes objectifs`
		else return `Identifier mes objectifs (suite)`
	}, [categoriesQuestionnaire])

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '32px',
					padding: {
						sm: '136px 48px 48px',
						md: '136px 32px 64px 32px',
						lg: '160px 64px 96px 64px',
					},
					height: '100%',
					width: '100%',
					maxWidth: '1248px',
					margin: '0 auto',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: {
							sm: 'column',
							md: 'row',
						},
						height: 'fit-content',
						width: '100%',
						gap: '16px',
						justifyContent: {
							sm: 'center',
							md: 'space-between',
						},
						alignItems: 'center',
						padding: '24px',
						background: theme.palette.background.paper,
						borderRadius: '8px',
					}}
				>
					<h2
						style={{
							margin: '0px',
							width: 'auto',
						}}
					>
						Étoile de vie
					</h2>
					<LastRefresh
						snapshot={mostRecentSnapshot}
						questionnaire={snapshotQuestionnaire}
						showQuestionnaireStartInstructions={
							showQuestionnaireStartInstructions
						}
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: {
							sm: 'column',
							md: 'row',
						},
						width: '100%',
						height: 'fit-content',
						gap: '32px',
						justifyContent: 'flex-end',
					}}
				>
					<Box
						sx={{
							width: '100%',
						}}
					>
						{contentRender}
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							backgroundColor: theme.palette.background.paper,
							padding: '48px',
							borderRadius: '8px',
							height: '100%',
							width: '100%',
							gap: '24px',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<h3
							style={{
								marginBottom: '0px',
								textAlign: 'center',
							}}
						>
							Améliore ton niveau de bien-être
						</h3>
						<Button
							color='primary'
							variant='contained'
							size='small'
							onClick={startCategoriesQuestionnaire}
						>
							{startCategoriesQuestionnaireButtonLabel}
						</Button>
					</Box>
				</Box>
			</Box>
			{modals}
		</>
	)
}
